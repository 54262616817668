export const DATA = [
  {
    headshot_url: "202307191/images/headshots/5/5ccf29ba_sabr.jpg",
    id: "abreu-001bob",
    link: "/players/a/abreubo01.shtml",
    name: "Bobby Abreu",
    name_first: "Bobby",
    name_last: "Abreu",
    search_name: ["Bobby Abreu"],
    teams: [
      { id: "NYM" },
      { id: "LAD" },
      { id: "ANA", season_b_rbi_100: "qualified", season_b_sb_30: "qualified" },
      {
        id: "NYY",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "PHI",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_hr_30_sb_30: "qualified",
        season_b_rbi_100: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "HOU" },
    ],
    years: "1996-2014",
  },
  {
    career_p_sv_300: "qualified",
    headshot_url: "202307191/images/headshots/c/c29fd34b_sabr.jpg",
    id: "aguile001ric",
    link: "/players/a/aguilri01.shtml",
    name: "Rick Aguilera",
    name_first: "Rick",
    name_last: "Aguilera",
    search_name: ["Rick Aguilera"],
    teams: [
      {
        id: "MIN",
        season_allstar: "qualified",
        season_p_sv_40: "qualified",
        ws_champ: "qualified",
      },
      { id: "NYM", ws_champ: "qualified" },
      { id: "CHC" },
      { id: "BOS" },
    ],
    years: "1985-2000",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/a/ace19da3_sabr.jpg",
    id: "allen-001eth",
    link: "/players/a/allenet01.shtml",
    name: "Ethan Allen",
    name_first: "Ethan",
    name_last: "Allen",
    search_name: ["Ethan Allen"],
    teams: [
      { id: "CHC" },
      { id: "CIN", season_b_avg_300: "qualified" },
      { id: "SFG", season_b_avg_300: "unqualified" },
      { id: "PHI", season_b_avg_300: "qualified" },
      { id: "BAL", season_b_avg_300: "unqualified" },
      { id: "STL" },
    ],
    years: "1926-1938",
  },
  {
    career_award_hof: "qualified",
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/2/24c918e7_sabr.jpg",
    id: "alomar001rob",
    link: "/players/a/alomaro01.shtml",
    name: "Roberto Alomar",
    name_first: "Roberto",
    name_last: "Alomar",
    search_name: ["Roberto Alomar"],
    teams: [
      { id: "ARI", season_b_avg_300: "unqualified" },
      { id: "NYM" },
      { id: "SDP", season_allstar: "qualified", season_b_sb_30: "qualified" },
      {
        id: "CLE",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
        season_b_sb_30: "qualified",
      },
      {
        id: "BAL",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
      },
      { id: "CHW" },
      {
        id: "TOR",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_sb_30: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "1988-2004",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/3/3d8b257b_sabr.jpg",
    id: "alou--001mat",
    link: "/players/a/alouma01.shtml",
    name: "Matty Alou",
    name_first: "Matty",
    name_last: "Alou",
    search_name: ["Matty Alou"],
    teams: [
      { id: "SDP" },
      {
        id: "PIT",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
      },
      { id: "SFG", season_b_avg_300: "unqualified" },
      { id: "NYY" },
      { id: "STL", season_b_avg_300: "qualified" },
      { id: "OAK", ws_champ: "qualified" },
    ],
    years: "1960-1974",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/3/30ebdf88_sabr.jpg",
    id: "alou--001moi",
    link: "/players/a/aloumo01.shtml",
    name: "Moises Alou",
    name_first: "Moises",
    name_last: "Alou",
    search_name: ["Moises Alou"],
    teams: [
      { id: "NYM", season_b_avg_300: "unqualified" },
      { id: "CHC", season_allstar: "qualified", season_b_rbi_100: "qualified" },
      {
        id: "WSN",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
      },
      {
        id: "SFG",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
      },
      { id: "PIT" },
      {
        id: "HOU",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "FLA",
        season_allstar: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "1990-2008",
  },
  {
    headshot_url: "202307191/images/headshots/7/722422f3_sabr.jpg",
    id: "anders004mar",
    link: "/players/a/anderma02.shtml",
    name: "Marlon Anderson",
    name_first: "Marlon",
    name_last: "Anderson",
    search_name: ["Marlon Anderson"],
    teams: [
      { id: "LAD", season_b_avg_300: "unqualified" },
      { id: "NYM", season_b_avg_300: "unqualified" },
      { id: "TBD" },
      { id: "WSN" },
      { id: "PHI", season_b_avg_300: "qualified" },
      { id: "STL" },
    ],
    years: "1998-2009",
  },
  {
    career_award_hof: "qualified",
    headshot_url: "202307191/images/headshots/8/8e1285e8_sabr.jpg",
    id: "baines001har",
    link: "/players/b/baineha01.shtml",
    name: "Harold Baines",
    name_first: "Harold",
    name_last: "Baines",
    search_name: ["Harold Baines"],
    teams: [
      { id: "TEX", season_award_silver_slugger: "qualified" },
      { id: "CLE" },
      { id: "BAL", season_allstar: "qualified", season_b_avg_300: "qualified" },
      { id: "OAK", season_allstar: "qualified" },
      {
        id: "CHW",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
    ],
    years: "1980-2001",
  },
  {
    career_award_hof: "qualified",
    headshot_url: "202307191/images/headshots/8/8822919c_sabr.jpg",
    id: "bancro001dav",
    link: "/players/b/bancrda01.shtml",
    name: "Dave Bancroft",
    name_first: "Dave",
    name_last: "Bancroft",
    search_name: ["Dave Bancroft"],
    teams: [
      { id: "LAD" },
      {
        id: "SFG",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        ws_champ: "qualified",
      },
      { id: "ATL", season_b_avg_300: "qualified" },
      { id: "PHI" },
    ],
    years: "1915-1930",
  },
  {
    headshot_url: "202307191/images/headshots/d/dbdccbfa_sabr.jpg",
    id: "baylor001don",
    link: "/players/b/baylodo01.shtml",
    name: "Don Baylor",
    name_first: "Don",
    name_last: "Baylor",
    search_name: ["Don Baylor"],
    teams: [
      { id: "MIN", ws_champ: "qualified" },
      {
        id: "ANA",
        season_allstar: "qualified",
        season_award_mvp: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "NYY",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
      },
      { id: "BAL", season_b_sb_30: "qualified" },
      { id: "BOS", season_award_silver_slugger: "qualified" },
      { id: "OAK", season_b_sb_30: "qualified" },
    ],
    years: "1970-1988",
  },
  {
    headshot_url: "202307191/images/headshots/b/b831fa63_sabr.jpg",
    id: "beck--002wal",
    link: "/players/b/beckbo01.shtml",
    name: "Boom-Boom Beck",
    name_first: "Boom-Boom",
    name_last: "Beck",
    search_name: ["Boom-Boom Beck", "BoomBoom Beck"],
    teams: [
      { id: "LAD" },
      { id: "CIN" },
      { id: "PIT" },
      { id: "PHI", season_b_avg_300: "unqualified" },
      { id: "DET", season_b_avg_300: "unqualified" },
      { id: "BAL", season_b_avg_300: "unqualified" },
    ],
    years: "1924-1945",
  },
  {
    career_award_hof: "qualified",
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/b/b2aa2e3e_sabr.jpg",
    id: "beckle001jac",
    link: "/players/b/becklja01.shtml",
    name: "Jake Beckley",
    name_first: "Jake",
    name_last: "Beckley",
    search_name: ["Jake Beckley"],
    teams: [
      { id: "CIN", season_b_avg_300: "qualified" },
      { id: "SFG", season_b_avg_300: "unqualified" },
      {
        id: "PIT",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "STL", season_b_avg_300: "qualified" },
    ],
    years: "1888-1907",
  },
  {
    headshot_url: "202307191/images/headshots/f/fa0f9b5c_sabr.jpg",
    id: "beltra001car",
    link: "/players/b/beltrca01.shtml",
    name: "Carlos Beltr\u00e1n",
    name_first: "Carlos",
    name_last: "Beltr\u00e1n",
    search_name: ["Carlos Beltran"],
    teams: [
      {
        id: "KCR",
        season_award_roty: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "TEX" },
      {
        id: "NYM",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "SFG", season_b_avg_300: "unqualified" },
      { id: "NYY", season_allstar: "qualified", season_b_avg_300: "qualified" },
      { id: "STL", season_allstar: "qualified" },
      { id: "HOU", season_allstar: "qualified", ws_champ: "qualified" },
    ],
    years: "1998-2017",
  },
  {
    career_b_h_3000: "qualified",
    headshot_url: "202307191/images/headshots/1/1423362b_sabr_sea.jpg",
    id: "beltre001adr",
    link: "/players/b/beltrad01.shtml",
    name: "Adri\u00e1n Beltr\u00e9",
    name_first: "Adri\u00e1n",
    name_last: "Beltr\u00e9",
    search_name: ["Adrian Beltre"],
    teams: [
      {
        id: "TEX",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "LAD",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "SEA", season_award_gold_glove: "qualified" },
      {
        id: "BOS",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
    ],
    years: "1998-2018",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/8/80aaace3_sabr.jpg",
    id: "berger001wal",
    link: "/players/b/bergewa01.shtml",
    name: "Wally Berger",
    name_first: "Wally",
    name_last: "Berger",
    search_name: ["Wally Berger"],
    teams: [
      { id: "CIN", season_b_avg_300: "unqualified" },
      { id: "SFG" },
      { id: "PHI", season_b_avg_300: "unqualified" },
      {
        id: "ATL",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
    ],
    years: "1930-1940",
  },
  {
    career_award_hof: "qualified",
    career_p_so_3000: "qualified",
    headshot_url: "202307191/images/headshots/8/86826f24_sabr.jpg",
    id: "blylev001rik",
    link: "/players/b/blylebe01.shtml",
    name: "Bert Blyleven",
    name_first: "Bert",
    name_last: "Blyleven",
    search_name: ["Bert Blyleven"],
    teams: [
      {
        id: "MIN",
        season_allstar: "qualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
        ws_champ: "qualified",
      },
      { id: "TEX" },
      { id: "ANA" },
      { id: "CLE", season_allstar: "qualified" },
      { id: "PIT", ws_champ: "qualified" },
    ],
    years: "1970-1992",
  },
  {
    headshot_url: "202307191/images/headshots/5/5af0e0b0_sabr.jpg",
    id: "bonds-001bob",
    link: "/players/b/bondsbo01.shtml",
    name: "Bobby Bonds",
    name_first: "Bobby",
    name_last: "Bonds",
    search_name: ["Bobby Bonds"],
    teams: [
      {
        id: "SFG",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_hr_30_sb_30: "qualified",
        season_b_rbi_100: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "STL" },
      { id: "TEX", season_b_sb_30: "qualified" },
      {
        id: "ANA",
        season_b_hr_30_sb_30: "qualified",
        season_b_rbi_100: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "CLE", season_b_sb_30: "qualified" },
      { id: "CHC" },
      {
        id: "NYY",
        season_allstar: "qualified",
        season_b_hr_30_sb_30: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "CHW" },
    ],
    years: "1968-1981",
  },
  {
    headshot_url: "202307191/images/headshots/0/065291f6_sabr.jpg",
    id: "bonill001rob",
    link: "/players/b/bonilbo01.shtml",
    name: "Bobby Bonilla",
    name_first: "Bobby",
    name_last: "Bonilla",
    search_name: ["Bobby Bonilla"],
    teams: [
      { id: "NYM", season_allstar: "qualified", season_b_avg_300: "qualified" },
      {
        id: "PIT",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "ATL" },
      { id: "STL" },
      { id: "LAD" },
      {
        id: "BAL",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "FLA", ws_champ: "qualified" },
      { id: "CHW" },
    ],
    years: "1986-2001",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/9/972fe435_sabr.jpg",
    id: "bonura001hen",
    link: "/players/b/bonurze01.shtml",
    name: "Zeke Bonura",
    name_first: "Zeke",
    name_last: "Bonura",
    search_name: ["Zeke Bonura"],
    teams: [
      { id: "MIN", season_b_rbi_100: "qualified" },
      { id: "CHC" },
      { id: "SFG", season_b_avg_300: "qualified" },
      {
        id: "CHW",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
    ],
    years: "1934-1940",
  },
  {
    career_b_avg_300: "unqualified",
    headshot_url: "202307191/images/headshots/b/bfbe1e87_sabr.jpg",
    id: "boone-001isa",
    link: "/players/b/booneik01.shtml",
    name: "Ike Boone",
    name_first: "Ike",
    name_last: "Boone",
    search_name: ["Ike Boone"],
    teams: [
      { id: "LAD" },
      { id: "SFG", season_b_avg_300: "unqualified" },
      { id: "CHW" },
      { id: "BOS", season_b_avg_300: "qualified" },
    ],
    years: "1922-1932",
  },
  {
    headshot_url: "202307191/images/headshots/0/073084c3_sabr.jpg",
    id: "border001pat",
    link: "/players/b/bordepa01.shtml",
    name: "Pat Borders",
    name_first: "Pat",
    name_last: "Borders",
    search_name: ["Pat Borders"],
    teams: [
      { id: "MIN" },
      { id: "KCR" },
      { id: "STL", season_b_avg_300: "unqualified" },
      { id: "ANA" },
      { id: "CLE", season_b_avg_300: "unqualified" },
      { id: "SEA", season_b_avg_300: "unqualified" },
      { id: "HOU" },
      { id: "TOR", ws_champ: "qualified" },
      { id: "CHW" },
    ],
    years: "1988-2005",
  },
  {
    headshot_url: "202307191/images/headshots/1/151e6406_sabr.jpg",
    id: "botten001ken",
    link: "/players/b/botteke01.shtml",
    name: "Kent Bottenfield",
    name_first: "Kent",
    name_last: "Bottenfield",
    search_name: ["Kent Bottenfield"],
    teams: [
      { id: "COL" },
      { id: "SFG" },
      { id: "PHI" },
      { id: "STL", season_allstar: "qualified" },
      { id: "WSN", season_b_avg_300: "unqualified" },
      { id: "CHC", season_b_avg_300: "unqualified" },
      { id: "ANA", season_b_avg_300: "unqualified" },
      { id: "HOU" },
    ],
    years: "1992-2001",
  },
  {
    headshot_url: "202307191/images/headshots/6/65286652_sabr.jpg",
    id: "bradle001mil",
    link: "/players/b/bradlmi01.shtml",
    name: "Milton Bradley",
    name_first: "Milton",
    name_last: "Bradley",
    search_name: ["Milton Bradley"],
    teams: [
      { id: "OAK" },
      { id: "LAD" },
      { id: "TEX", season_allstar: "qualified", season_b_avg_300: "qualified" },
      { id: "SDP", season_b_avg_300: "unqualified" },
      { id: "WSN" },
      { id: "CLE", season_b_avg_300: "unqualified" },
      { id: "CHC" },
      { id: "SEA" },
    ],
    years: "2000-2011",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/5/57364bc9_sabr.jpg",
    id: "bressl001ray",
    link: "/players/b/bressru01.shtml",
    name: "Rube Bressler",
    name_first: "Rube",
    name_last: "Bressler",
    search_name: ["Rube Bressler"],
    teams: [
      { id: "LAD", season_b_avg_300: "qualified" },
      { id: "CIN", season_b_avg_300: "qualified", ws_champ: "qualified" },
      { id: "PHI" },
      { id: "STL" },
      { id: "OAK" },
    ],
    years: "1914-1932",
  },
  {
    career_award_hof: "qualified",
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/c/c08044f6_sabr.jpg",
    id: "brouth001den",
    link: "/players/b/broutda01.shtml",
    name: "Dan Brouthers",
    name_first: "Dan",
    name_last: "Brouthers",
    search_name: ["Dan Brouthers"],
    teams: [
      { id: "SFG" },
      { id: "PHI", season_b_avg_300: "unqualified" },
      {
        id: "ATL",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "LAD",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
        season_b_sb_30: "qualified",
      },
    ],
    years: "1879-1904",
  },
  {
    headshot_url: "202307191/images/headshots/1/14fff13c_sabr.jpg",
    id: "brown-008jam",
    link: "/players/b/brownke01.shtml",
    name: "Kevin Brown",
    name_first: "Kevin",
    name_last: "Brown",
    search_name: ["Kevin Brown"],
    teams: [
      { id: "LAD", season_allstar: "qualified", season_p_so_200: "qualified" },
      { id: "TEX", season_allstar: "qualified", season_p_w_20: "qualified" },
      { id: "SDP", season_allstar: "qualified", season_p_so_200: "qualified" },
      { id: "NYY", season_b_avg_300: "unqualified" },
      {
        id: "FLA",
        season_allstar: "qualified",
        season_p_so_200: "qualified",
        ws_champ: "qualified",
      },
      { id: "BAL" },
    ],
    years: "1986-2005",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/b/b4fdac3f_sabr.jpg",
    id: "browni001pet",
    link: "/players/b/brownpe01.shtml",
    name: "Pete Browning",
    name_first: "Pete",
    name_last: "Browning",
    search_name: ["Pete Browning"],
    teams: [
      { id: "LAD", season_b_avg_300: "unqualified" },
      { id: "CIN", season_b_avg_300: "unqualified" },
      { id: "PIT" },
      { id: "STL" },
    ],
    years: "1882-1894",
  },
  {
    headshot_url: "202307191/images/headshots/4/444a4659_sabr.jpg",
    id: "buckne002wil",
    link: "/players/b/bucknbi01.shtml",
    name: "Bill Buckner",
    name_first: "Bill",
    name_last: "Buckner",
    search_name: ["Bill Buckner"],
    teams: [
      { id: "KCR" },
      { id: "LAD", season_b_avg_300: "qualified", season_b_sb_30: "qualified" },
      { id: "ANA", season_b_avg_300: "unqualified" },
      {
        id: "CHC",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "BOS", season_b_h_200: "qualified", season_b_rbi_100: "qualified" },
    ],
    years: "1969-1990",
  },
  {
    career_award_hof: "qualified",
    headshot_url: "202307191/images/headshots/b/bcacaa59_sabr_phi.jpg",
    id: "bunnin001jam",
    link: "/players/b/bunniji01.shtml",
    name: "Jim Bunning",
    name_first: "Jim",
    name_last: "Bunning",
    search_name: ["Jim Bunning"],
    teams: [
      { id: "LAD" },
      { id: "PIT" },
      { id: "PHI", season_allstar: "qualified", season_p_so_200: "qualified" },
      {
        id: "DET",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
      },
    ],
    years: "1955-1971",
  },
  {
    headshot_url: "202307191/images/headshots/2/24804821_sabr.jpg",
    id: "burges001for",
    link: "/players/b/burgesm01.shtml",
    name: "Smoky Burgess",
    name_first: "Smoky",
    name_last: "Burgess",
    search_name: ["Smoky Burgess"],
    teams: [
      {
        id: "CIN",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
      },
      { id: "CHC" },
      {
        id: "PIT",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
        ws_champ: "qualified",
      },
      {
        id: "PHI",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
      },
      { id: "CHW", season_b_avg_300: "unqualified" },
    ],
    years: "1949-1967",
  },
  {
    career_award_hof: "qualified",
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/5/53d6808e_sabr.jpg",
    id: "burket001jes",
    link: "/players/b/burkeje01.shtml",
    name: "Jesse Burkett",
    name_first: "Jesse",
    name_last: "Burkett",
    search_name: ["Jesse Burkett"],
    teams: [
      { id: "SFG", season_b_avg_300: "unqualified" },
      { id: "BAL", season_b_avg_300: "qualified" },
      {
        id: "STL",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "BOS" },
    ],
    years: "1890-1905",
  },
  {
    headshot_url: "202307191/images/headshots/d/d8bf583a_sabr_col.jpg",
    id: "burks-001ell",
    link: "/players/b/burksel01.shtml",
    name: "Ellis Burks",
    name_first: "Ellis",
    name_last: "Burks",
    search_name: ["Ellis Burks"],
    teams: [
      { id: "CLE", season_b_avg_300: "qualified" },
      {
        id: "COL",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_hr_30_sb_30: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "SFG", season_b_avg_300: "unqualified" },
      { id: "CHW" },
      {
        id: "BOS",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
      },
    ],
    years: "1987-2004",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/8/88e2067e_sabr.jpg",
    id: "burns-001geo",
    link: "/players/b/burnsge02.shtml",
    name: "George Burns",
    name_first: "George",
    name_last: "Burns",
    search_name: ["George Burns"],
    teams: [
      {
        id: "CLE",
        season_award_mvp: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
      { id: "NYY", season_b_avg_300: "unqualified" },
      { id: "DET" },
      { id: "BOS", season_b_avg_300: "qualified" },
      { id: "OAK", season_b_avg_300: "qualified", ws_champ: "qualified" },
    ],
    years: "1914-1929",
  },
  {
    headshot_url: "202307191/images/headshots/3/30a2a3bd_sabr.jpg",
    id: "bush--001les",
    link: "/players/b/bushjo01.shtml",
    name: "Bullet Joe Bush",
    name_first: "Bullet Joe",
    name_last: "Bush",
    search_name: ["Bullet Joe Bush"],
    teams: [
      { id: "MIN" },
      { id: "SFG", season_b_avg_300: "unqualified" },
      { id: "PIT", season_b_avg_300: "unqualified" },
      {
        id: "NYY",
        season_b_avg_300: "unqualified",
        season_p_w_20: "qualified",
        ws_champ: "qualified",
      },
      { id: "BAL" },
      { id: "BOS", season_b_avg_300: "unqualified", ws_champ: "qualified" },
      { id: "OAK", season_b_avg_300: "unqualified", ws_champ: "qualified" },
    ],
    years: "1912-1928",
  },
  {
    headshot_url: "202307191/images/headshots/4/41366870_sabr.jpg",
    id: "butler001bre",
    link: "/players/b/butlebr01.shtml",
    name: "Brett Butler",
    name_first: "Brett",
    name_last: "Butler",
    search_name: ["Brett Butler"],
    teams: [
      { id: "NYM", season_b_avg_300: "unqualified" },
      {
        id: "LAD",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "CLE", season_b_avg_300: "qualified", season_b_sb_30: "qualified" },
      { id: "SFG", season_b_avg_300: "qualified", season_b_sb_30: "qualified" },
      { id: "ATL", season_b_sb_30: "qualified" },
    ],
    years: "1981-1997",
  },
  {
    headshot_url: "202307191/images/headshots/a/a26113fd_br.jpg",
    id: "byrd--001mar",
    link: "/players/b/byrdma01.shtml",
    name: "Marlon Byrd",
    name_first: "Marlon",
    name_last: "Byrd",
    search_name: ["Marlon Byrd"],
    teams: [
      { id: "NYM" },
      { id: "SFG" },
      { id: "PIT", season_b_avg_300: "unqualified" },
      { id: "PHI", season_b_avg_300: "qualified" },
      { id: "BOS" },
      { id: "TEX", season_b_avg_300: "qualified" },
      { id: "CLE" },
      { id: "WSN" },
      { id: "CHC", season_allstar: "qualified" },
      { id: "CIN" },
    ],
    years: "2002-2016",
  },
  {
    headshot_url: "202307191/images/headshots/6/6fca0aa0_br.jpg",
    id: "cabrer001mel",
    link: "/players/c/cabreme01.shtml",
    name: "Melky Cabrera",
    name_first: "Melky",
    name_last: "Cabrera",
    search_name: ["Melky Cabrera"],
    teams: [
      { id: "KCR", season_b_avg_300: "qualified", season_b_h_200: "qualified" },
      { id: "PIT" },
      { id: "SFG", season_allstar: "qualified", season_b_avg_300: "qualified" },
      { id: "ATL" },
      { id: "CLE" },
      { id: "NYY", ws_champ: "qualified" },
      { id: "TOR", season_b_avg_300: "qualified" },
      { id: "CHW" },
    ],
    years: "2005-2019",
  },
  {
    headshot_url: "202307191/images/headshots/6/63ea8a28_mlbam.jpg",
    id: "cahill001tre",
    link: "/players/c/cahiltr01.shtml",
    name: "Trevor Cahill",
    name_first: "Trevor",
    name_last: "Cahill",
    search_name: ["Trevor Cahill"],
    teams: [
      { id: "KCR" },
      { id: "ARI" },
      { id: "PIT" },
      { id: "SFG" },
      { id: "ATL" },
      {
        id: "OAK",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
      },
      { id: "SDP", season_b_avg_300: "unqualified" },
      { id: "CHC" },
      { id: "ANA", season_b_avg_300: "unqualified" },
    ],
    years: "2009-2021",
  },
  {
    career_b_avg_300: "unqualified",
    headshot_url: "202307191/images/headshots/1/1a45c296_sabr.jpg",
    id: "campbe007mic",
    link: "/players/c/campbmi01.shtml",
    name: "Mike Campbell",
    name_first: "Mike",
    name_last: "Campbell",
    search_name: ["Mike Campbell"],
    teams: [
      { id: "TEX" },
      { id: "SDP", season_b_avg_300: "unqualified" },
      { id: "CHC", season_b_avg_300: "unqualified" },
      { id: "SEA" },
    ],
    years: "1987-1996",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/a/ae57de14_sabr_nyy.jpg",
    id: "cano--001rob",
    link: "/players/c/canoro01.shtml",
    name: "Robinson Can\u00f3",
    name_first: "Robinson",
    name_last: "Can\u00f3",
    search_name: ["Robinson Cano"],
    teams: [
      { id: "NYM", season_b_avg_300: "unqualified" },
      { id: "SDP" },
      { id: "ATL" },
      {
        id: "SEA",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "NYY",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "2005-2022",
  },
  {
    headshot_url: "202307191/images/headshots/8/8a7502e4_sabr.jpg",
    id: "carden001jos",
    link: "/players/c/cardejo02.shtml",
    name: "Jos\u00e9 Cardenal",
    name_first: "Jos\u00e9",
    name_last: "Cardenal",
    search_name: ["Jose Cardenal"],
    teams: [
      { id: "KCR", season_b_avg_300: "unqualified" },
      { id: "NYM" },
      { id: "SFG" },
      { id: "PHI" },
      { id: "STL" },
      { id: "ANA", season_b_sb_30: "qualified" },
      { id: "CLE", season_b_sb_30: "qualified" },
      { id: "CHC", season_b_avg_300: "qualified", season_b_sb_30: "qualified" },
      { id: "MIL" },
    ],
    years: "1963-1980",
  },
  {
    career_award_hof: "qualified",
    career_p_so_3000: "qualified",
    career_p_w_300: "qualified",
    headshot_url: "202307191/images/headshots/e/e438064d_sabr.jpg",
    id: "carlto001ste",
    link: "/players/c/carltst01.shtml",
    name: "Steve Carlton",
    name_first: "Steve",
    name_last: "Carlton",
    search_name: ["Steve Carlton"],
    teams: [
      { id: "MIN" },
      { id: "CLE" },
      { id: "SFG" },
      {
        id: "PHI",
        season_allstar: "qualified",
        season_award_cy_young: "qualified",
        season_award_gold_glove: "qualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
        ws_champ: "qualified",
      },
      {
        id: "STL",
        season_allstar: "qualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
        ws_champ: "qualified",
      },
      { id: "CHW" },
    ],
    years: "1965-1988",
  },
  {
    career_award_hof: "qualified",
    headshot_url: "202307191/images/headshots/1/1a995e9e_sabr.jpg",
    id: "carter002gar",
    link: "/players/c/cartega01.shtml",
    name: "Gary Carter",
    name_first: "Gary",
    name_last: "Carter",
    search_name: ["Gary Carter"],
    teams: [
      { id: "LAD" },
      {
        id: "NYM",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
      {
        id: "WSN",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "SFG" },
    ],
    years: "1974-1992",
  },
  {
    headshot_url: "202307191/images/headshots/d/d6d37272_sabr.jpg",
    id: "carter001jos",
    link: "/players/c/cartejo01.shtml",
    name: "Joe Carter",
    name_first: "Joe",
    name_last: "Carter",
    search_name: ["Joe Carter"],
    teams: [
      { id: "SDP", season_b_rbi_100: "qualified" },
      {
        id: "CLE",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_hr_30_sb_30: "qualified",
        season_b_rbi_100: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "CHC" },
      { id: "SFG" },
      { id: "BAL" },
      {
        id: "TOR",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "1983-1998",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/6/608e8f66_sabr.jpg",
    id: "casey-001sea",
    link: "/players/c/caseyse01.shtml",
    name: "Sean Casey",
    name_first: "Sean",
    name_last: "Casey",
    search_name: ["Sean Casey"],
    teams: [
      { id: "CIN", season_allstar: "qualified", season_b_avg_300: "qualified" },
      { id: "CLE" },
      { id: "PIT" },
      { id: "DET" },
      { id: "BOS", season_b_avg_300: "unqualified" },
    ],
    years: "1997-2008",
  },
  {
    career_award_hof: "qualified",
    headshot_url: "202307191/images/headshots/b/bd6a83d8_sabr.jpg",
    id: "stenge001cha",
    link: "/players/s/stengca01.shtml",
    name: "Casey Stengel",
    name_first: "Casey",
    name_last: "Stengel",
    search_name: ["Casey Stengel"],
    teams: [
      { id: "LAD", season_b_avg_300: "qualified" },
      { id: "SFG", season_b_avg_300: "unqualified", ws_champ: "qualified" },
      { id: "PIT" },
      { id: "ATL" },
      { id: "PHI", season_b_avg_300: "qualified" },
    ],
    years: "1912-1925",
  },
  {
    career_award_hof: "qualified",
    headshot_url: "202307191/images/headshots/0/017440d1_sabr.jpg",
    id: "cepeda001orl",
    link: "/players/c/cepedor01.shtml",
    name: "Orlando Cepeda",
    name_first: "Orlando",
    name_last: "Cepeda",
    search_name: ["Orlando Cepeda"],
    teams: [
      { id: "KCR" },
      {
        id: "SFG",
        season_allstar: "qualified",
        season_award_roty: "qualified",
        season_b_avg_300: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "ATL",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "STL",
        season_allstar: "qualified",
        season_award_mvp: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
      { id: "BOS" },
      { id: "OAK" },
    ],
    years: "1958-1974",
  },
  {
    career_p_sv_300: "qualified",
    headshot_url: "202307191/images/headshots/e/e7d0cac7_mlbam.jpg",
    id: "chapma001aro",
    link: "/players/c/chapmar01.shtml",
    name: "Aroldis Chapman",
    name_first: "Aroldis",
    name_last: "Chapman",
    search_name: ["Aroldis Chapman"],
    teams: [
      { id: "KCR" },
      { id: "TEX" },
      { id: "CHC", ws_champ: "qualified" },
      { id: "CIN", season_allstar: "qualified" },
      { id: "NYY", season_allstar: "qualified" },
    ],
    years: "2010-2023",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/0/0fe7f158_sabr.jpg",
    id: "chapma002wil",
    link: "/players/c/chapmbe01.shtml",
    name: "Ben Chapman",
    name_first: "Ben",
    name_last: "Chapman",
    search_name: ["Ben Chapman"],
    teams: [
      {
        id: "MIN",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
      },
      { id: "LAD", season_b_avg_300: "unqualified" },
      { id: "CLE" },
      { id: "PHI", season_b_avg_300: "unqualified" },
      {
        id: "NYY",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
        season_b_sb_30: "qualified",
        ws_champ: "qualified",
      },
      { id: "CHW" },
      { id: "BOS", season_b_avg_300: "qualified" },
    ],
    years: "1930-1946",
  },
  {
    headshot_url: "202307191/images/headshots/a/a23aa6b3_br.jpg",
    id: "chen--001bru",
    link: "/players/c/chenbr01.shtml",
    name: "Bruce Chen",
    name_first: "Bruce",
    name_last: "Chen",
    search_name: ["Bruce Chen"],
    teams: [
      { id: "KCR", season_b_avg_300: "unqualified" },
      { id: "NYM" },
      { id: "PHI" },
      { id: "ATL" },
      { id: "BOS" },
      { id: "TEX" },
      { id: "CLE" },
      { id: "CIN" },
      { id: "WSN", season_b_avg_300: "unqualified" },
      { id: "HOU" },
      { id: "BAL", season_b_avg_300: "unqualified" },
    ],
    years: "1998-2015",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/3/3bcff907_sabr.jpg",
    id: "clark-007wil",
    link: "/players/c/clarkwi02.shtml",
    name: "Will Clark",
    name_first: "Will",
    name_last: "Clark",
    search_name: ["Will Clark"],
    teams: [
      {
        id: "TEX",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "SFG",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "STL", season_b_avg_300: "unqualified" },
      { id: "BAL", season_b_avg_300: "unqualified" },
    ],
    years: "1986-2000",
  },
  {
    career_b_avg_300: "unqualified",
    headshot_url: "202307191/images/headshots/3/3b565bc5_sabr.jpg",
    id: "clark-001ter",
    link: "/players/c/clarkte01.shtml",
    name: "Terry Clark",
    name_first: "Terry",
    name_last: "Clark",
    search_name: ["Terry Clark"],
    teams: [
      { id: "KCR" },
      { id: "TEX", season_b_avg_300: "unqualified" },
      { id: "CLE" },
      { id: "ANA" },
      { id: "ATL" },
      { id: "BAL" },
      { id: "HOU", season_b_avg_300: "unqualified" },
    ],
    years: "1988-1997",
  },
  {
    career_p_so_3000: "qualified",
    career_p_w_300: "qualified",
    headshot_url: "202307191/images/headshots/b/b5a2be2f_br.jpg",
    id: "clemen001wil",
    link: "/players/c/clemero02.shtml",
    name: "Roger Clemens",
    name_first: "Roger",
    name_last: "Clemens",
    search_name: ["Roger Clemens"],
    teams: [
      {
        id: "NYY",
        season_allstar: "qualified",
        season_award_cy_young: "qualified",
        season_b_avg_300: "unqualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
        ws_champ: "qualified",
      },
      {
        id: "HOU",
        season_allstar: "qualified",
        season_award_cy_young: "qualified",
        season_p_so_200: "qualified",
      },
      {
        id: "TOR",
        season_allstar: "qualified",
        season_award_cy_young: "qualified",
        season_b_avg_300: "unqualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
      },
      {
        id: "BOS",
        season_allstar: "qualified",
        season_award_cy_young: "qualified",
        season_award_mvp: "qualified",
        season_b_avg_300: "unqualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
      },
    ],
    years: "1984-2007",
  },
  {
    headshot_url: "202307191/images/headshots/6/640e1825_sabr.jpg",
    id: "cline-001tyr",
    link: "/players/c/clinety01.shtml",
    name: "Ty Cline",
    name_first: "Ty",
    name_last: "Cline",
    search_name: ["Ty Cline"],
    teams: [
      { id: "CIN" },
      { id: "WSN", season_b_avg_300: "unqualified" },
      { id: "CHC", season_b_avg_300: "unqualified" },
      { id: "CLE", season_b_avg_300: "unqualified" },
      { id: "SFG" },
      { id: "ATL", season_b_avg_300: "unqualified" },
    ],
    years: "1960-1971",
  },
  {
    headshot_url: "202307191/images/headshots/8/8899e413_sabr.jpg",
    id: "colavi001roc",
    link: "/players/c/colavro01.shtml",
    name: "Rocky Colavito",
    name_first: "Rocky",
    name_last: "Colavito",
    search_name: ["Rocky Colavito"],
    teams: [
      { id: "LAD" },
      {
        id: "CLE",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "NYY" },
      {
        id: "DET",
        season_allstar: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "CHW" },
      { id: "OAK", season_allstar: "qualified", season_b_rbi_100: "qualified" },
    ],
    years: "1955-1968",
  },
  {
    headshot_url: "202307191/images/headshots/a/a16637da_sabr.jpg",
    id: "colbru001gre",
    link: "/players/c/colbrgr01.shtml",
    name: "Greg Colbrunn",
    name_first: "Greg",
    name_last: "Colbrunn",
    search_name: ["Greg Colbrunn"],
    teams: [
      { id: "ARI", season_b_avg_300: "unqualified", ws_champ: "qualified" },
      { id: "MIN" },
      { id: "COL", season_b_avg_300: "unqualified" },
      { id: "WSN" },
      { id: "SEA" },
      { id: "ATL" },
      { id: "FLA", season_b_avg_300: "qualified" },
    ],
    years: "1992-2004",
  },
  {
    headshot_url: "202307191/images/headshots/7/78c1d3e9_sabr.jpg",
    id: "colema002vin",
    link: "/players/c/colemvi01.shtml",
    name: "Vince Coleman",
    name_first: "Vince",
    name_last: "Coleman",
    search_name: ["Vince Coleman"],
    teams: [
      { id: "KCR", season_b_sb_30: "qualified" },
      { id: "NYM", season_b_sb_30: "qualified" },
      { id: "CIN" },
      { id: "SEA" },
      { id: "DET" },
      {
        id: "STL",
        season_allstar: "qualified",
        season_award_roty: "qualified",
        season_b_sb_30: "qualified",
      },
    ],
    years: "1985-1997",
  },
  {
    headshot_url: "202307191/images/headshots/a/ab0c8e4e_sabr.jpg",
    id: "collin001dav",
    link: "/players/c/collida02.shtml",
    name: "Dave Collins",
    name_first: "Dave",
    name_last: "Collins",
    search_name: ["Dave Collins"],
    teams: [
      { id: "STL" },
      { id: "OAK" },
      { id: "ANA", season_b_sb_30: "qualified" },
      { id: "CIN", season_b_avg_300: "qualified", season_b_sb_30: "qualified" },
      { id: "SEA" },
      { id: "NYY" },
      { id: "DET" },
      {
        id: "TOR",
        season_b_avg_300: "unqualified",
        season_b_sb_30: "qualified",
      },
    ],
    years: "1975-1990",
  },
  {
    headshot_url: "202307191/images/headshots/1/1625da35_br.jpg",
    id: "colon-001bar",
    link: "/players/c/colonba01.shtml",
    name: "Bartolo Colon",
    name_first: "Bartolo",
    name_last: "Colon",
    search_name: ["Bartolo Colon"],
    teams: [
      { id: "MIN" },
      { id: "NYM", season_allstar: "qualified" },
      { id: "ATL" },
      { id: "OAK", season_allstar: "qualified" },
      { id: "BOS" },
      { id: "TEX" },
      {
        id: "CLE",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
        season_p_so_200: "qualified",
      },
      { id: "WSN" },
      {
        id: "ANA",
        season_allstar: "qualified",
        season_award_cy_young: "qualified",
        season_b_avg_300: "unqualified",
        season_p_w_20: "qualified",
      },
      { id: "NYY" },
      { id: "CHW" },
    ],
    years: "1997-2018",
  },
  {
    headshot_url: "202307191/images/headshots/1/191828e7_sabr.jpg",
    id: "cone--001dav",
    link: "/players/c/coneda01.shtml",
    name: "David Cone",
    name_first: "David",
    name_last: "Cone",
    search_name: ["David Cone"],
    teams: [
      {
        id: "KCR",
        season_allstar: "qualified",
        season_award_cy_young: "qualified",
      },
      {
        id: "NYM",
        season_allstar: "qualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
      },
      {
        id: "NYY",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
        ws_champ: "qualified",
      },
      { id: "BOS" },
      { id: "TOR", ws_champ: "qualified" },
    ],
    years: "1986-2003",
  },
  {
    headshot_url: "202307191/images/headshots/d/dceb1250_sabr.jpg",
    id: "cooper008wil",
    link: "/players/c/coopewa01.shtml",
    name: "Walker Cooper",
    name_first: "Walker",
    name_last: "Cooper",
    search_name: ["Walker Cooper"],
    teams: [
      { id: "CHC", season_b_avg_300: "unqualified" },
      { id: "CIN", season_allstar: "qualified" },
      { id: "PIT" },
      {
        id: "SFG",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "ATL",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
      },
      {
        id: "STL",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
        ws_champ: "qualified",
      },
    ],
    years: "1940-1957",
  },
  {
    career_p_sv_300: "qualified",
    headshot_url: "202307191/images/headshots/1/160932d7_sabr.jpg",
    id: "corder001fra",
    link: "/players/c/cordefr01.shtml",
    name: "Francisco Cordero",
    name_first: "Francisco",
    name_last: "Cordero",
    search_name: ["Francisco Cordero"],
    teams: [
      { id: "TEX", season_allstar: "qualified", season_p_sv_40: "qualified" },
      { id: "CIN", season_allstar: "qualified", season_p_sv_40: "qualified" },
      { id: "HOU" },
      { id: "DET" },
      { id: "TOR" },
      { id: "MIL", season_allstar: "qualified", season_p_sv_40: "qualified" },
    ],
    years: "1999-2012",
  },
  {
    career_award_hof: "qualified",
    headshot_url: "202307191/images/headshots/7/7b589446_davis.jpg",
    id: "covele001sta",
    link: "/players/c/covelst01.shtml",
    name: "Stan Coveleski",
    name_first: "Stan",
    name_last: "Coveleski",
    search_name: ["Stan Coveleski"],
    teams: [
      {
        id: "MIN",
        season_b_avg_300: "unqualified",
        season_p_w_20: "qualified",
      },
      { id: "CLE", season_p_w_20: "qualified", ws_champ: "qualified" },
      { id: "NYY" },
      { id: "OAK" },
    ],
    years: "1912-1928",
  },
  {
    headshot_url: "202307191/images/headshots/f/fea2f131_mlbam.jpg",
    id: "cruz--002nel",
    link: "/players/c/cruzne02.shtml",
    name: "Nelson Cruz",
    name_first: "Nelson",
    name_last: "Cruz",
    search_name: ["Nelson Cruz"],
    teams: [
      {
        id: "MIN",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "TBD" },
      { id: "TEX", season_allstar: "qualified", season_b_avg_300: "qualified" },
      { id: "SDP" },
      { id: "WSN" },
      {
        id: "SEA",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "BAL",
        season_allstar: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "MIL" },
    ],
    years: "2005-2023",
  },
  {
    headshot_url: "202307191/images/headshots/b/be5d770b_sabr.jpg",
    id: "cuccin001ant",
    link: "/players/c/cuccito01.shtml",
    name: "Tony Cuccinello",
    name_first: "Tony",
    name_last: "Cuccinello",
    search_name: ["Tony Cuccinello"],
    teams: [
      { id: "LAD", season_allstar: "qualified" },
      { id: "CIN", season_b_avg_300: "qualified" },
      { id: "SFG" },
      { id: "ATL", season_allstar: "qualified", season_b_avg_300: "qualified" },
      {
        id: "CHW",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
      },
    ],
    years: "1930-1945",
  },
  {
    headshot_url: "202307191/images/headshots/6/625c5c68_sabr.jpg",
    id: "cullen001roy",
    link: "/players/c/cullero01.shtml",
    name: "Roy Cullenbine",
    name_first: "Roy",
    name_last: "Cullenbine",
    search_name: ["Roy Cullenbine"],
    teams: [
      { id: "MIN" },
      { id: "LAD" },
      { id: "CLE", season_allstar: "qualified" },
      { id: "NYY", season_b_avg_300: "unqualified" },
      { id: "BAL", season_allstar: "qualified", season_b_avg_300: "qualified" },
      { id: "DET", season_b_avg_300: "qualified", ws_champ: "qualified" },
    ],
    years: "1938-1947",
  },
  {
    career_award_hof: "qualified",
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/7/7107706b_sabr.jpg",
    id: "cuyler001haz",
    link: "/players/c/cuyleki01.shtml",
    name: "Kiki Cuyler",
    name_first: "Kiki",
    name_last: "Cuyler",
    search_name: ["Kiki Cuyler"],
    teams: [
      { id: "LAD" },
      { id: "CIN", season_b_avg_300: "qualified" },
      {
        id: "CHC",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
        season_b_sb_30: "qualified",
      },
      {
        id: "PIT",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
        season_b_sb_30: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "1921-1938",
  },
  {
    headshot_url: "202307191/images/headshots/7/7a7bca43_sabr.jpg",
    id: "damon-002joh",
    link: "/players/d/damonjo01.shtml",
    name: "Johnny Damon",
    name_first: "Johnny",
    name_last: "Damon",
    search_name: ["Johnny Damon"],
    teams: [
      {
        id: "KCR",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "TBD" },
      { id: "CLE" },
      { id: "NYY", season_b_avg_300: "qualified", ws_champ: "qualified" },
      { id: "DET" },
      {
        id: "BOS",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_sb_30: "qualified",
        ws_champ: "qualified",
      },
      { id: "OAK" },
    ],
    years: "1995-2012",
  },
  {
    headshot_url: "202307191/images/headshots/9/92dda5ac_sabr.jpg",
    id: "davali001vic",
    link: "/players/d/davalvi01.shtml",
    name: "Vic Davalillo",
    name_first: "Vic",
    name_last: "Davalillo",
    search_name: ["Vic Davalillo"],
    teams: [
      { id: "LAD", season_b_avg_300: "unqualified" },
      { id: "ANA" },
      {
        id: "CLE",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_b_avg_300: "qualified",
      },
      { id: "PIT", season_b_avg_300: "unqualified", ws_champ: "qualified" },
      { id: "STL", season_b_avg_300: "unqualified" },
      { id: "OAK", ws_champ: "qualified" },
    ],
    years: "1963-1980",
  },
  {
    headshot_url: "202307191/images/headshots/f/f842dfbd_sabr.jpg",
    id: "davis-013cha",
    link: "/players/d/davisch01.shtml",
    name: "Chili Davis",
    name_first: "Chili",
    name_last: "Davis",
    search_name: ["Chili Davis"],
    teams: [
      { id: "KCR" },
      { id: "MIN", ws_champ: "qualified" },
      {
        id: "ANA",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "SFG", season_allstar: "qualified", season_b_avg_300: "qualified" },
      { id: "NYY", ws_champ: "qualified" },
    ],
    years: "1981-1999",
  },
  {
    headshot_url: "202307191/images/headshots/9/98ac284f_sabr.jpg",
    id: "davis-002eri",
    link: "/players/d/daviser01.shtml",
    name: "Eric Davis",
    name_first: "Eric",
    name_last: "Davis",
    search_name: ["Eric Davis"],
    teams: [
      { id: "LAD", season_b_sb_30: "qualified" },
      {
        id: "CIN",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_hr_30_sb_30: "qualified",
        season_b_rbi_100: "qualified",
        season_b_sb_30: "qualified",
        ws_champ: "qualified",
      },
      { id: "SFG" },
      { id: "STL", season_b_avg_300: "unqualified" },
      { id: "BAL", season_b_avg_300: "qualified" },
      { id: "DET" },
    ],
    years: "1984-2001",
  },
  {
    headshot_url: "202307191/images/headshots/8/8d0f172a_br.jpg",
    id: "davis-001raj",
    link: "/players/d/davisra01.shtml",
    name: "Rajai Davis",
    name_first: "Rajai",
    name_last: "Davis",
    search_name: ["Rajai Davis"],
    teams: [
      { id: "NYM" },
      { id: "SFG" },
      { id: "PIT" },
      { id: "BOS" },
      { id: "OAK", season_b_avg_300: "qualified", season_b_sb_30: "qualified" },
      { id: "CLE", season_b_sb_30: "qualified" },
      { id: "DET", season_b_sb_30: "qualified" },
      { id: "TOR", season_b_sb_30: "qualified" },
    ],
    years: "2006-2019",
  },
  {
    headshot_url: "202307191/images/headshots/6/664f669f_sabr.jpg",
    id: "davis-003her",
    link: "/players/d/davisto02.shtml",
    name: "Tommy Davis",
    name_first: "Tommy",
    name_last: "Davis",
    search_name: ["Tommy Davis"],
    teams: [
      { id: "KCR" },
      { id: "NYM", season_b_avg_300: "qualified" },
      { id: "OAK", season_b_avg_300: "unqualified" },
      {
        id: "LAD",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
      { id: "ANA" },
      { id: "CHC" },
      { id: "HOU" },
      { id: "BAL", season_b_avg_300: "qualified" },
      { id: "CHW" },
      { id: "MIL" },
    ],
    years: "1959-1976",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/c/cd894376_sabr.jpg",
    id: "davis-001vir",
    link: "/players/d/davissp01.shtml",
    name: "Virgil Davis",
    name_first: "Virgil",
    name_last: "Davis",
    search_name: ["Virgil Davis"],
    teams: [
      { id: "CIN" },
      { id: "PIT", season_b_avg_300: "unqualified" },
      { id: "PHI", season_b_avg_300: "qualified" },
      { id: "STL", season_b_avg_300: "unqualified", ws_champ: "qualified" },
    ],
    years: "1928-1945",
  },
  {
    career_award_hof: "qualified",
    headshot_url: "202307191/images/headshots/8/8ce7c5bf_sabr.jpg",
    id: "dawson001and",
    link: "/players/d/dawsoan01.shtml",
    name: "Andre Dawson",
    name_first: "Andre",
    name_last: "Dawson",
    search_name: ["Andre Dawson"],
    teams: [
      {
        id: "CHC",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_mvp: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "WSN",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_roty: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "FLA" },
      { id: "BOS" },
    ],
    years: "1976-1996",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/6/633b5823_sabr.jpg",
    id: "demont001eug",
    link: "/players/d/demonge01.shtml",
    name: "Gene DeMontreville",
    name_first: "Gene",
    name_last: "DeMontreville",
    search_name: ["Gene DeMontreville"],
    teams: [
      { id: "MIN" },
      { id: "PIT" },
      { id: "ATL", season_b_avg_300: "qualified" },
      { id: "LAD" },
      { id: "CHC" },
      { id: "BAL" },
    ],
    years: "1894-1904",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/e/ebbb2eaf_sabr.jpg",
    id: "dillin001rob",
    link: "/players/d/dillibo01.shtml",
    name: "Bob Dillinger",
    name_first: "Bob",
    name_last: "Dillinger",
    search_name: ["Bob Dillinger"],
    teams: [
      { id: "PIT" },
      {
        id: "BAL",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "CHW", season_b_avg_300: "unqualified" },
      { id: "OAK", season_b_avg_300: "unqualified" },
    ],
    years: "1946-1951",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/3/3b51e847_sabr.jpg",
    id: "donlin001mic",
    link: "/players/d/donlimi01.shtml",
    name: "Mike Donlin",
    name_first: "Mike",
    name_last: "Donlin",
    search_name: ["Mike Donlin"],
    teams: [
      { id: "CIN", season_b_avg_300: "qualified" },
      { id: "PIT", season_b_avg_300: "unqualified" },
      {
        id: "SFG",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
        season_b_sb_30: "qualified",
        ws_champ: "qualified",
      },
      { id: "ATL", season_b_avg_300: "unqualified" },
      { id: "STL", season_b_avg_300: "unqualified" },
    ],
    years: "1899-1914",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/7/753652af_sabr.jpg",
    id: "donova001pat",
    link: "/players/d/donovpa01.shtml",
    name: "Patsy Donovan",
    name_first: "Patsy",
    name_last: "Donovan",
    search_name: ["Patsy Donovan"],
    teams: [
      { id: "MIN" },
      { id: "LAD" },
      { id: "PIT", season_b_avg_300: "qualified", season_b_sb_30: "qualified" },
      { id: "ATL" },
      { id: "STL", season_b_avg_300: "qualified", season_b_sb_30: "qualified" },
    ],
    years: "1890-1907",
  },
  {
    career_b_avg_300: "unqualified",
    headshot_url: "202307191/images/headshots/3/37358e2a_mlbam.jpg",
    id: "drake-001oli",
    link: "/players/d/drakeol01.shtml",
    name: "Oliver Drake",
    name_first: "Oliver",
    name_last: "Drake",
    search_name: ["Oliver Drake"],
    teams: [
      { id: "MIN" },
      { id: "TBD", season_b_avg_300: "unqualified" },
      { id: "CLE" },
      { id: "ANA" },
      { id: "BAL" },
      { id: "TOR" },
      { id: "MIL" },
    ],
    years: "2015-2020",
  },
  {
    career_award_hof: "qualified",
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/d/d208fb41_sabr.jpg",
    id: "duffy-001hug",
    link: "/players/d/duffyhu01.shtml",
    name: "Hugh Duffy",
    name_first: "Hugh",
    name_last: "Duffy",
    search_name: ["Hugh Duffy"],
    teams: [
      { id: "CHC", season_b_avg_300: "qualified", season_b_sb_30: "qualified" },
      { id: "PHI", season_b_avg_300: "unqualified" },
      {
        id: "ATL",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "BAL", season_b_avg_300: "unqualified" },
    ],
    years: "1888-1906",
  },
  {
    headshot_url: "202307191/images/headshots/7/78e5c7bc_br.jpg",
    id: "duke--001zac",
    link: "/players/d/dukeza01.shtml",
    name: "Zach Duke",
    name_first: "Zach",
    name_last: "Duke",
    search_name: ["Zach Duke"],
    teams: [
      { id: "MIN" },
      { id: "ARI", season_b_avg_300: "unqualified" },
      { id: "PIT", season_allstar: "qualified" },
      { id: "STL" },
      { id: "WSN", season_b_avg_300: "unqualified" },
      { id: "CIN", season_b_avg_300: "unqualified" },
      { id: "SEA" },
      { id: "MIL" },
      { id: "CHW" },
    ],
    years: "2005-2019",
  },
  {
    career_award_hof: "qualified",
    headshot_url: "202307191/images/headshots/3/35d925c7_sabr.jpg",
    id: "duroch001leo",
    link: "/players/d/durocle01.shtml",
    name: "Leo Durocher",
    name_first: "Leo",
    name_last: "Durocher",
    search_name: ["Leo Durocher"],
    teams: [
      { id: "LAD", season_allstar: "qualified" },
      { id: "CIN" },
      { id: "NYY", ws_champ: "qualified" },
      { id: "STL", season_allstar: "qualified", ws_champ: "qualified" },
    ],
    years: "1925-1945",
  },
  {
    headshot_url: "202307191/images/headshots/3/3eb7d47d_sabr.jpg",
    id: "dwyer-002jam",
    link: "/players/d/dwyerji01.shtml",
    name: "Jim Dwyer",
    name_first: "Jim",
    name_last: "Dwyer",
    search_name: ["Jim Dwyer"],
    teams: [
      { id: "MIN", season_b_avg_300: "unqualified" },
      { id: "NYM" },
      { id: "WSN", season_b_avg_300: "unqualified" },
      { id: "SFG" },
      { id: "BAL", season_b_avg_300: "unqualified", ws_champ: "qualified" },
      { id: "STL" },
      { id: "BOS" },
    ],
    years: "1973-1990",
  },
  {
    headshot_url: "202307191/images/headshots/e/e3276c46_sabr.jpg",
    id: "easler001mic",
    link: "/players/e/easlemi01.shtml",
    name: "Mike Easler",
    name_first: "Mike",
    name_last: "Easler",
    search_name: ["Mike Easler"],
    teams: [
      { id: "ANA" },
      {
        id: "PIT",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        ws_champ: "qualified",
      },
      { id: "PHI" },
      { id: "NYY", season_b_avg_300: "qualified" },
      { id: "HOU" },
      { id: "BOS", season_b_avg_300: "qualified" },
    ],
    years: "1973-1987",
  },
  {
    career_award_hof: "qualified",
    career_p_sv_300: "qualified",
    headshot_url: "202307191/images/headshots/9/98aaf620_sabr.jpg",
    id: "eckers001den",
    link: "/players/e/eckerde01.shtml",
    name: "Dennis Eckersley",
    name_first: "Dennis",
    name_last: "Eckersley",
    search_name: ["Dennis Eckersley"],
    teams: [
      { id: "CHC" },
      { id: "CLE", season_allstar: "qualified", season_p_so_200: "qualified" },
      { id: "STL" },
      {
        id: "OAK",
        season_allstar: "qualified",
        season_award_cy_young: "qualified",
        season_award_mvp: "qualified",
        season_p_sv_40: "qualified",
        ws_champ: "qualified",
      },
      {
        id: "BOS",
        season_allstar: "qualified",
        season_p_w_20: "qualified",
      },
    ],
    years: "1975-1998",
  },
  {
    headshot_url: "202307191/images/headshots/c/cad0118c_davis.jpg",
    id: "edward001hen",
    link: "/players/e/edwarha01.shtml",
    name: "Hank Edwards",
    name_first: "Hank",
    name_last: "Edwards",
    search_name: ["Hank Edwards"],
    teams: [
      { id: "LAD" },
      { id: "CIN", season_b_avg_300: "unqualified" },
      { id: "CHC", season_b_avg_300: "unqualified" },
      { id: "CLE", season_b_avg_300: "qualified" },
      { id: "BAL" },
      { id: "CHW", season_b_avg_300: "unqualified" },
    ],
    years: "1941-1953",
  },
  {
    headshot_url: "202307191/images/headshots/7/7e48602e_sabr.jpg",
    id: "everet001car",
    link: "/players/e/evereca01.shtml",
    name: "Carl Everett",
    name_first: "Carl",
    name_last: "Everett",
    search_name: ["Carl Everett"],
    teams: [
      { id: "NYM" },
      {
        id: "BOS",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "TEX" },
      { id: "WSN" },
      { id: "SEA" },
      { id: "FLA" },
      {
        id: "HOU",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "CHW",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "1993-2006",
  },
  {
    career_award_hof: "qualified",
    headshot_url: "202307191/images/headshots/e/efe76f7c_sabr.jpg",
    id: "evers-002joh",
    link: "/players/e/eversjo01.shtml",
    name: "Johnny Evers",
    name_first: "Johnny",
    name_last: "Evers",
    search_name: ["Johnny Evers"],
    teams: [
      {
        id: "CHC",
        season_b_avg_300: "qualified",
        season_b_sb_30: "qualified",
        ws_champ: "qualified",
      },
      { id: "PHI" },
      { id: "ATL", season_award_mvp: "qualified", ws_champ: "qualified" },
      { id: "CHW" },
    ],
    years: "1902-1929",
  },
  {
    headshot_url: "202307191/images/headshots/b/be8590ec_sabr_wsn.jpg",
    id: "fairly001ron",
    link: "/players/f/fairlro01.shtml",
    name: "Ron Fairly",
    name_first: "Ron",
    name_last: "Fairly",
    search_name: ["Ron Fairly"],
    teams: [
      { id: "LAD", season_b_avg_300: "qualified", ws_champ: "qualified" },
      { id: "ANA" },
      { id: "WSN", season_allstar: "qualified" },
      { id: "STL", season_b_avg_300: "unqualified" },
      { id: "TOR", season_allstar: "qualified" },
      { id: "OAK" },
    ],
    years: "1958-1978",
  },
  {
    headshot_url: "202307191/images/headshots/8/81a7570e_sabr.jpg",
    id: "ferrel001wes",
    link: "/players/f/ferrewe01.shtml",
    name: "Wes Ferrell",
    name_first: "Wes",
    name_last: "Ferrell",
    search_name: ["Wes Ferrell"],
    teams: [
      { id: "MIN", season_allstar: "qualified" },
      { id: "LAD" },
      {
        id: "CLE",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
        season_p_w_20: "qualified",
      },
      { id: "ATL", season_b_avg_300: "unqualified" },
      { id: "NYY", ws_champ: "qualified" },
      {
        id: "BOS",
        season_b_avg_300: "unqualified",
        season_p_w_20: "qualified",
      },
    ],
    years: "1927-1941",
  },
  {
    headshot_url: "202307191/images/headshots/b/b60ed164_sabr.jpg",
    id: "finley001ste",
    link: "/players/f/finlest01.shtml",
    name: "Steve Finley",
    name_first: "Steve",
    name_last: "Finley",
    search_name: ["Steve Finley"],
    teams: [
      {
        id: "ARI",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
      { id: "COL" },
      { id: "SFG" },
      { id: "LAD", season_award_gold_glove: "qualified" },
      {
        id: "SDP",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "ANA" },
      { id: "BAL" },
      { id: "HOU", season_b_sb_30: "qualified" },
    ],
    years: "1989-2007",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/e/ef9f81a8_sabr.jpg",
    id: "fonsec001lew",
    link: "/players/f/fonsele01.shtml",
    name: "Lew Fonseca",
    name_first: "Lew",
    name_last: "Fonseca",
    search_name: ["Lew Fonseca"],
    teams: [
      {
        id: "CLE",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "CIN", season_b_avg_300: "unqualified" },
      { id: "PHI", season_b_avg_300: "qualified" },
      { id: "CHW" },
    ],
    years: "1921-1933",
  },
  {
    career_b_avg_300: "unqualified",
    headshot_url: "202307191/images/headshots/8/84a26bd2_mlbam.jpg",
    id: "font--001wil",
    link: "/players/f/fontwi01.shtml",
    name: "Wilmer Font",
    name_first: "Wilmer",
    name_last: "Font",
    search_name: ["Wilmer Font"],
    teams: [
      { id: "NYM", season_b_avg_300: "unqualified" },
      { id: "TBD" },
      { id: "LAD" },
      { id: "TEX" },
      { id: "TOR" },
      { id: "OAK" },
    ],
    years: "2012-2020",
  },
  {
    career_b_avg_300: "unqualified",
    headshot_url: "202307191/images/headshots/5/53d0bb2e_sabr.jpg",
    id: "forste001ter",
    link: "/players/f/forstte01.shtml",
    name: "Terry Forster",
    name_first: "Terry",
    name_last: "Forster",
    search_name: ["Terry Forster"],
    teams: [
      { id: "LAD", season_b_avg_300: "unqualified", ws_champ: "qualified" },
      { id: "ANA" },
      { id: "PIT", season_b_avg_300: "unqualified" },
      { id: "ATL", season_b_avg_300: "unqualified" },
      { id: "CHW", season_b_avg_300: "unqualified" },
    ],
    years: "1971-1986",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/8/81af331c_sabr.jpg",
    id: "fourni001joh",
    link: "/players/f/fournja01.shtml",
    name: "Jack Fournier",
    name_first: "Jack",
    name_last: "Fournier",
    search_name: ["Jack Fournier"],
    teams: [
      {
        id: "LAD",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "ATL" },
      { id: "NYY", season_b_avg_300: "unqualified" },
      { id: "STL", season_b_avg_300: "qualified" },
      { id: "CHW", season_b_avg_300: "qualified" },
    ],
    years: "1912-1927",
  },
  {
    career_award_hof: "qualified",
    career_b_avg_300: "qualified",
    career_b_hr_500: "qualified",
    headshot_url: "202307191/images/headshots/e/e34a045d_sabr.jpg",
    id: "foxx--001jam",
    link: "/players/f/foxxji01.shtml",
    name: "Jimmie Foxx",
    name_first: "Jimmie",
    name_last: "Foxx",
    search_name: ["Jimmie Foxx"],
    teams: [
      { id: "CHC" },
      { id: "PHI" },
      {
        id: "BOS",
        season_allstar: "qualified",
        season_award_mvp: "qualified",
        season_b_avg_300: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "OAK",
        season_allstar: "qualified",
        season_award_mvp: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "1925-1945",
  },
  {
    headshot_url: "202307191/images/headshots/a/ade5464a_sabr.jpg",
    id: "franco001jul",
    link: "/players/f/francju01.shtml",
    name: "Julio Franco",
    name_first: "Julio",
    name_last: "Franco",
    search_name: ["Julio Franco"],
    teams: [
      { id: "NYM" },
      { id: "TBD" },
      { id: "ATL", season_b_avg_300: "unqualified" },
      { id: "PHI" },
      {
        id: "TEX",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_sb_30: "qualified",
      },
      {
        id: "CLE",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_sb_30: "qualified",
      },
      {
        id: "CHW",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
      },
      { id: "MIL" },
    ],
    years: "1982-2007",
  },
  {
    headshot_url: "202307191/images/headshots/8/8f25f690_br.jpg",
    id: "franco001jef",
    link: "/players/f/francje02.shtml",
    name: "Jeff Francoeur",
    name_first: "Jeff",
    name_last: "Francoeur",
    search_name: ["Jeff Francoeur"],
    teams: [
      { id: "KCR" },
      { id: "NYM", season_b_avg_300: "unqualified" },
      { id: "SFG" },
      { id: "PHI" },
      {
        id: "ATL",
        season_award_gold_glove: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "TEX", season_b_avg_300: "unqualified" },
      { id: "SDP" },
      { id: "FLA" },
    ],
    years: "2005-2016",
  },
  {
    headshot_url: "202307191/images/headshots/1/10f4ef3f_sabr.jpg",
    id: "galan-001aug",
    link: "/players/g/galanau01.shtml",
    name: "Augie Galan",
    name_first: "Augie",
    name_last: "Galan",
    search_name: ["Augie Galan"],
    teams: [
      { id: "LAD", season_allstar: "qualified", season_b_avg_300: "qualified" },
      { id: "CIN", season_b_avg_300: "qualified" },
      {
        id: "CHC",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
      },
      { id: "SFG" },
      { id: "OAK", season_b_avg_300: "unqualified" },
    ],
    years: "1934-1949",
  },
  {
    headshot_url: "202307191/images/headshots/4/4fa68f08_sabr.jpg",
    id: "galarr001and",
    link: "/players/g/galaran01.shtml",
    name: "Andr\u00e9s Galarraga",
    name_first: "Andr\u00e9s",
    name_last: "Galarraga",
    search_name: ["Andres Galarraga"],
    teams: [
      { id: "TEX" },
      { id: "ANA", season_b_avg_300: "unqualified" },
      {
        id: "COL",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "WSN",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
      },
      { id: "SFG", season_b_avg_300: "unqualified" },
      {
        id: "ATL",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "STL" },
    ],
    years: "1985-2004",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/f/fb90e442_sabr.jpg",
    id: "garcia006ant",
    link: "/players/g/garcino01.shtml",
    name: "Nomar Garciaparra",
    name_first: "Nomar",
    name_last: "Garciaparra",
    search_name: ["Nomar Garciaparra"],
    teams: [
      { id: "LAD", season_allstar: "qualified", season_b_avg_300: "qualified" },
      { id: "CHC" },
      { id: "OAK" },
      {
        id: "BOS",
        season_allstar: "qualified",
        season_award_roty: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
      },
    ],
    years: "1996-2009",
  },
  {
    career_b_avg_300: "unqualified",
    headshot_url: "202307191/images/headshots/e/e2965af1_br_sfg.jpg",
    id: "gearri001cor",
    link: "/players/g/gearrco01.shtml",
    name: "Cory Gearrin",
    name_first: "Cory",
    name_last: "Gearrin",
    search_name: ["Cory Gearrin"],
    teams: [
      { id: "MIN" },
      { id: "TEX" },
      { id: "SFG", season_b_avg_300: "unqualified" },
      { id: "SEA" },
      { id: "NYY" },
      { id: "ATL" },
      { id: "OAK" },
    ],
    years: "2011-2020",
  },
  {
    headshot_url: "202307191/images/headshots/6/632ed912_sabr.jpg",
    id: "gleaso002wil",
    link: "/players/g/gleaski01.shtml",
    name: "Kid Gleason",
    name_first: "Kid",
    name_last: "Gleason",
    search_name: ["Kid Gleason"],
    teams: [
      {
        id: "SFG",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "PHI", season_p_so_200: "qualified", season_p_w_20: "qualified" },
      { id: "DET", season_b_sb_30: "qualified" },
      { id: "STL", season_p_w_20: "qualified" },
      { id: "CHW", season_b_avg_300: "unqualified" },
    ],
    years: "1888-1912",
  },
  {
    headshot_url: "202307191/images/headshots/9/9f2148d9_sabr.jpg",
    id: "gonzal001ren",
    link: "/players/g/gonzare01.shtml",
    name: "Rene Gonzales",
    name_first: "Rene",
    name_last: "Gonzales",
    search_name: ["Rene Gonzales"],
    teams: [
      { id: "TEX" },
      { id: "COL", season_b_avg_300: "unqualified" },
      { id: "CLE", season_b_avg_300: "unqualified" },
      { id: "ANA", season_b_avg_300: "unqualified" },
      { id: "WSN" },
      { id: "BAL" },
      { id: "TOR" },
    ],
    years: "1984-1997",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/0/05dce458_sabr.jpg",
    id: "goodma003wil",
    link: "/players/g/goodmbi01.shtml",
    name: "Billy Goodman",
    name_first: "Billy",
    name_last: "Goodman",
    search_name: ["Billy Goodman"],
    teams: [
      { id: "HOU" },
      { id: "BAL", season_b_avg_300: "unqualified" },
      { id: "CHW" },
      { id: "BOS", season_allstar: "qualified", season_b_avg_300: "qualified" },
    ],
    years: "1947-1962",
  },
  {
    headshot_url: "202307191/images/headshots/e/ec2327e7_sabr.jpg",
    id: "goodwi001tho",
    link: "/players/g/goodwto01.shtml",
    name: "Tom Goodwin",
    name_first: "Tom",
    name_last: "Goodwin",
    search_name: ["Tom Goodwin"],
    teams: [
      { id: "KCR", season_b_sb_30: "qualified" },
      { id: "TEX", season_b_sb_30: "qualified" },
      { id: "LAD" },
      { id: "CHC" },
      { id: "COL", season_b_sb_30: "qualified" },
      { id: "SFG" },
    ],
    years: "1991-2004",
  },
  {
    headshot_url: "202307191/images/headshots/7/7ca4573b_sabr.jpg",
    id: "gordon001tho",
    link: "/players/g/gordoto01.shtml",
    name: "Tom Gordon",
    name_first: "Tom",
    name_last: "Gordon",
    search_name: ["Tom Gordon"],
    teams: [
      { id: "ARI" },
      { id: "KCR" },
      { id: "PHI", season_allstar: "qualified" },
      { id: "BOS", season_allstar: "qualified", season_p_sv_40: "qualified" },
      { id: "CHC" },
      { id: "NYY", season_allstar: "qualified" },
      { id: "HOU" },
      { id: "CHW" },
    ],
    years: "1988-2009",
  },
  {
    career_award_hof: "qualified",
    career_p_sv_300: "qualified",
    headshot_url: "202307191/images/headshots/0/0871f3e2_sabr.jpg",
    id: "gossag001ric",
    link: "/players/g/gossari01.shtml",
    name: "Rich Gossage",
    name_first: "Rich",
    name_last: "Gossage",
    search_name: ["Rich Gossage"],
    teams: [
      { id: "SFG" },
      { id: "PIT", season_allstar: "qualified" },
      { id: "OAK" },
      { id: "TEX" },
      { id: "SDP", season_allstar: "qualified" },
      { id: "CHC" },
      { id: "SEA" },
      { id: "NYY", season_allstar: "qualified", ws_champ: "qualified" },
      { id: "CHW", season_allstar: "qualified" },
    ],
    years: "1972-1994",
  },
  {
    headshot_url: "202307191/images/headshots/1/1ce1bcf4_sabr.jpg",
    id: "graffa001ant",
    link: "/players/g/graffto01.shtml",
    name: "Tony Graffanino",
    name_first: "Tony",
    name_last: "Graffanino",
    search_name: ["Tony Graffanino"],
    teams: [
      { id: "KCR" },
      { id: "TBD", season_b_avg_300: "unqualified" },
      { id: "CLE" },
      { id: "ATL" },
      { id: "MIL" },
      { id: "BOS", season_b_avg_300: "unqualified" },
      { id: "CHW", season_b_avg_300: "unqualified" },
    ],
    years: "1996-2009",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/c/c1c905a3_sabr.jpg",
    id: "granth001geo",
    link: "/players/g/grantge01.shtml",
    name: "George Grantham",
    name_first: "George",
    name_last: "Grantham",
    search_name: ["George Grantham"],
    teams: [
      { id: "CIN" },
      { id: "CHC", season_b_avg_300: "qualified", season_b_sb_30: "qualified" },
      { id: "SFG" },
      { id: "PIT", season_b_avg_300: "qualified", ws_champ: "qualified" },
    ],
    years: "1922-1934",
  },
  {
    headshot_url: "202307191/images/headshots/e/e83bfe13_mlbam.jpg",
    id: "greink001don",
    link: "/players/g/greinza01.shtml",
    name: "Zack Greinke",
    name_first: "Zack",
    name_last: "Greinke",
    search_name: ["Zack Greinke"],
    teams: [
      {
        id: "ARI",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_silver_slugger: "qualified",
        season_p_so_200: "qualified",
      },
      {
        id: "KCR",
        season_allstar: "qualified",
        season_award_cy_young: "qualified",
        season_b_avg_300: "unqualified",
        season_p_so_200: "qualified",
      },
      {
        id: "LAD",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "unqualified",
        season_p_so_200: "qualified",
      },
      { id: "ANA" },
      {
        id: "HOU",
        season_award_gold_glove: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "unqualified",
      },
      { id: "MIL", season_p_so_200: "qualified" },
    ],
    years: "2004-2023",
  },
  {
    career_award_hof: "qualified",
    headshot_url: "202307191/images/headshots/9/96624988_sabr.jpg",
    id: "griffi003cla",
    link: "/players/g/griffcl01.shtml",
    name: "Clark Griffith",
    name_first: "Clark",
    name_last: "Griffith",
    search_name: ["Clark Griffith"],
    teams: [
      { id: "MIN", season_b_avg_300: "unqualified" },
      { id: "CIN" },
      {
        id: "CHC",
        season_b_avg_300: "unqualified",
        season_p_w_20: "qualified",
      },
      { id: "NYY" },
      { id: "STL" },
      {
        id: "CHW",
        season_b_avg_300: "unqualified",
        season_p_w_20: "qualified",
      },
    ],
    years: "1891-1914",
  },
  {
    career_award_hof: "qualified",
    headshot_url: "202307191/images/headshots/0/0957655a_davis.jpg",
    id: "grimes001bur",
    link: "/players/g/grimebu01.shtml",
    name: "Burleigh Grimes",
    name_first: "Burleigh",
    name_last: "Grimes",
    search_name: ["Burleigh Grimes"],
    teams: [
      {
        id: "LAD",
        season_b_avg_300: "unqualified",
        season_p_w_20: "qualified",
      },
      { id: "CHC" },
      { id: "SFG" },
      {
        id: "PIT",
        season_b_avg_300: "unqualified",
        season_p_w_20: "qualified",
      },
      { id: "NYY" },
      { id: "ATL" },
      { id: "STL", ws_champ: "qualified" },
    ],
    years: "1916-1934",
  },
  {
    headshot_url: "202307191/images/headshots/9/9c358e4d_br.jpg",
    id: "grudzi001mar",
    link: "/players/g/grudzma01.shtml",
    name: "Mark Grudzielanek",
    name_first: "Mark",
    name_last: "Grudzielanek",
    search_name: ["Mark Grudzielanek"],
    teams: [
      {
        id: "KCR",
        season_award_gold_glove: "qualified",
        season_b_avg_300: "qualified",
      },
      { id: "LAD", season_b_avg_300: "qualified" },
      { id: "CLE" },
      { id: "CHC", season_b_avg_300: "qualified" },
      {
        id: "WSN",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "STL" },
    ],
    years: "1995-2010",
  },
  {
    career_award_hof: "qualified",
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/d/dfacd030_br.jpg",
    id: "guerre001vla",
    link: "/players/g/guerrvl01.shtml",
    name: "Vladimir Guerrero",
    name_first: "Vladimir",
    name_last: "Guerrero",
    search_name: ["Vladimir Guerrero"],
    teams: [
      {
        id: "TEX",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "ANA",
        season_allstar: "qualified",
        season_award_mvp: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "WSN",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_hr_30_sb_30: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "BAL" },
    ],
    years: "1996-2011",
  },
  {
    headshot_url: "202307191/images/headshots/d/d53f2aaa_sabr.jpg",
    id: "hammon002jef",
    link: "/players/h/hammoje01.shtml",
    name: "Jeffrey Hammonds",
    name_first: "Jeffrey",
    name_last: "Hammonds",
    search_name: ["Jeffrey Hammonds"],
    teams: [
      { id: "WSN" },
      {
        id: "COL",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "CIN", season_b_avg_300: "unqualified" },
      { id: "SFG" },
      { id: "BAL", season_b_avg_300: "unqualified" },
      { id: "MIL" },
    ],
    years: "1993-2005",
  },
  {
    headshot_url: "202307191/images/headshots/9/99a3c09e_sabr.jpg",
    id: "hampto002mic",
    link: "/players/h/hamptmi01.shtml",
    name: "Mike Hampton",
    name_first: "Mike",
    name_last: "Hampton",
    search_name: ["Mike Hampton"],
    teams: [
      { id: "ARI" },
      { id: "NYM", season_award_silver_slugger: "qualified" },
      {
        id: "COL",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "unqualified",
      },
      {
        id: "ATL",
        season_award_gold_glove: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "unqualified",
      },
      { id: "SEA" },
      {
        id: "HOU",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "unqualified",
        season_p_w_20: "qualified",
      },
    ],
    years: "1993-2010",
  },
  {
    headshot_url: "202307191/images/headshots/e/ec879ce5_br.jpg",
    id: "haren-001dan",
    link: "/players/h/harenda01.shtml",
    name: "Dan Haren",
    name_first: "Dan",
    name_last: "Haren",
    search_name: ["Dan Haren"],
    teams: [
      {
        id: "ARI",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
        season_p_so_200: "qualified",
      },
      { id: "STL" },
      {
        id: "OAK",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
      },
      { id: "LAD" },
      { id: "ANA", season_b_avg_300: "unqualified" },
      { id: "WSN" },
      { id: "CHC" },
      { id: "FLA" },
    ],
    years: "2003-2015",
  },
  {
    headshot_url: "202307191/images/headshots/6/6771b773_sabr.jpg",
    id: "harper001tom",
    link: "/players/h/harpeto01.shtml",
    name: "Tommy Harper",
    name_first: "Tommy",
    name_last: "Harper",
    search_name: ["Tommy Harper"],
    teams: [
      { id: "ANA" },
      { id: "CLE" },
      { id: "CIN", season_b_sb_30: "qualified" },
      { id: "BAL" },
      { id: "OAK", season_b_avg_300: "unqualified" },
      { id: "BOS", season_b_sb_30: "qualified" },
      {
        id: "MIL",
        season_allstar: "qualified",
        season_b_hr_30_sb_30: "qualified",
        season_b_sb_30: "qualified",
      },
    ],
    years: "1962-1976",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/a/aa4987d5_sabr.jpg",
    id: "harper004geo",
    link: "/players/h/harpege02.shtml",
    name: "George Harper",
    name_first: "George",
    name_last: "Harper",
    search_name: ["George Harper"],
    teams: [
      { id: "CIN", season_b_avg_300: "qualified" },
      { id: "SFG", season_b_avg_300: "qualified" },
      { id: "ATL" },
      { id: "PHI", season_b_avg_300: "qualified" },
      { id: "STL", season_b_avg_300: "unqualified" },
      { id: "DET" },
    ],
    years: "1916-1929",
  },
  {
    headshot_url: "202307191/images/headshots/0/0a8d4b33_sabr.jpg",
    id: "harris001gre",
    link: "/players/h/harrigr01.shtml",
    name: "Greg Harris",
    name_first: "Greg",
    name_last: "Harris",
    search_name: ["Greg Harris"],
    teams: [
      { id: "NYM" },
      { id: "PHI", season_b_avg_300: "unqualified" },
      { id: "BOS" },
      { id: "TEX" },
      { id: "SDP", season_b_avg_300: "unqualified" },
      { id: "CIN" },
      { id: "WSN", season_b_avg_300: "unqualified" },
      { id: "NYY" },
    ],
    years: "1981-1995",
  },
  {
    headshot_url: "202307191/images/headshots/2/268a76f0_sabr.jpg",
    id: "harris003leo",
    link: "/players/h/harrile01.shtml",
    name: "Lenny Harris",
    name_first: "Lenny",
    name_last: "Harris",
    search_name: ["Lenny Harris"],
    teams: [
      { id: "ARI", season_b_avg_300: "unqualified" },
      { id: "NYM", season_b_avg_300: "unqualified" },
      { id: "COL" },
      { id: "LAD", season_b_avg_300: "unqualified" },
      { id: "CIN", season_b_avg_300: "unqualified" },
      { id: "CHC" },
      { id: "FLA", season_b_avg_300: "unqualified", ws_champ: "qualified" },
      { id: "MIL", season_b_avg_300: "unqualified" },
    ],
    years: "1988-2005",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/4/458c7544_sabr.jpg",
    id: "harris001jos",
    link: "/players/h/harrijo03.shtml",
    name: "Joe Harris",
    name_first: "Joe",
    name_last: "Harris",
    search_name: ["Joe Harris"],
    teams: [
      { id: "MIN", season_b_avg_300: "unqualified" },
      { id: "LAD" },
      { id: "CLE", season_b_avg_300: "unqualified" },
      { id: "PIT", season_b_avg_300: "qualified" },
      { id: "NYY" },
      { id: "BOS", season_b_avg_300: "qualified" },
    ],
    years: "1914-1928",
  },
  {
    headshot_url: "202307191/images/headshots/a/a35b6622_sabr.jpg",
    id: "hassey001ron",
    link: "/players/h/hassero01.shtml",
    name: "Ron Hassey",
    name_first: "Ron",
    name_last: "Hassey",
    search_name: ["Ron Hassey"],
    teams: [
      { id: "WSN" },
      { id: "CHC", season_b_avg_300: "unqualified" },
      { id: "CLE", season_b_avg_300: "unqualified" },
      { id: "NYY" },
      { id: "OAK", ws_champ: "qualified" },
      { id: "CHW", season_b_avg_300: "unqualified" },
    ],
    years: "1978-1991",
  },
  {
    headshot_url: "202307191/images/headshots/5/5487a391_sabr.jpg",
    id: "heep--001dan",
    link: "/players/h/heepda01.shtml",
    name: "Danny Heep",
    name_first: "Danny",
    name_last: "Heep",
    search_name: ["Danny Heep"],
    teams: [
      { id: "LAD", ws_champ: "qualified" },
      { id: "NYM", ws_champ: "qualified" },
      { id: "ATL", season_b_avg_300: "unqualified" },
      { id: "HOU" },
      { id: "BOS", season_b_avg_300: "unqualified" },
    ],
    years: "1979-1991",
  },
  {
    headshot_url: "202307191/images/headshots/a/a9ce3370_sabr.jpg",
    id: "hemsle001ral",
    link: "/players/h/hemslro01.shtml",
    name: "Rollie Hemsley",
    name_first: "Rollie",
    name_last: "Hemsley",
    search_name: ["Rollie Hemsley"],
    teams: [
      { id: "CLE", season_allstar: "qualified" },
      { id: "CIN" },
      { id: "CHC", season_b_avg_300: "unqualified" },
      { id: "PIT" },
      { id: "PHI", season_b_avg_300: "unqualified" },
      { id: "NYY", season_allstar: "qualified", ws_champ: "qualified" },
      { id: "BAL", season_allstar: "qualified", season_b_avg_300: "qualified" },
    ],
    years: "1928-1947",
  },
  {
    career_award_hof: "qualified",
    career_b_h_3000: "qualified",
    headshot_url: "202307191/images/headshots/9/957d4da0_sabr.jpg",
    id: "hender003ric",
    link: "/players/h/henderi01.shtml",
    name: "Rickey Henderson",
    name_first: "Rickey",
    name_last: "Henderson",
    search_name: ["Rickey Henderson"],
    teams: [
      { id: "NYM", season_b_avg_300: "qualified", season_b_sb_30: "qualified" },
      { id: "BOS" },
      {
        id: "OAK",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_mvp: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_sb_30: "qualified",
        ws_champ: "qualified",
      },
      { id: "LAD" },
      { id: "SDP", season_b_sb_30: "qualified" },
      { id: "ANA" },
      {
        id: "NYY",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "SEA", season_b_sb_30: "qualified" },
      { id: "TOR", ws_champ: "qualified" },
    ],
    years: "1979-2003",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/4/41cc43d5_sabr.jpg",
    id: "hendri001har",
    link: "/players/h/hendrha01.shtml",
    name: "Harvey Hendrick",
    name_first: "Harvey",
    name_last: "Hendrick",
    search_name: ["Harvey Hendrick"],
    teams: [
      { id: "LAD", season_b_avg_300: "qualified" },
      { id: "CHC" },
      { id: "CIN", season_b_avg_300: "qualified" },
      { id: "CLE" },
      { id: "PHI" },
      { id: "NYY", ws_champ: "qualified" },
      { id: "STL" },
    ],
    years: "1923-1934",
  },
  {
    headshot_url: "202307191/images/headshots/9/957a995d_sabr.jpg",
    id: "heredi002fel",
    link: "/players/h/heredfe01.shtml",
    name: "F\u00e9lix Heredia",
    name_first: "F\u00e9lix",
    name_last: "Heredia",
    search_name: ["Felix Heredia"],
    teams: [
      { id: "NYM" },
      { id: "CIN", season_b_avg_300: "unqualified" },
      { id: "CHC", season_b_avg_300: "unqualified" },
      { id: "NYY" },
      { id: "FLA", season_b_avg_300: "unqualified", ws_champ: "qualified" },
      { id: "TOR" },
    ],
    years: "1996-2005",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/4/48d34e71_sabr.jpg",
    id: "herman001flo",
    link: "/players/h/hermaba01.shtml",
    name: "Babe Herman",
    name_first: "Babe",
    name_last: "Herman",
    search_name: ["Babe Herman"],
    teams: [
      {
        id: "LAD",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "CHC", season_b_avg_300: "qualified" },
      { id: "CIN", season_b_avg_300: "qualified" },
      { id: "PIT" },
      { id: "DET", season_b_avg_300: "unqualified" },
    ],
    years: "1926-1945",
  },
  {
    career_award_hof: "qualified",
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/d/d6297ffd_sabr.jpg",
    id: "herman002wil",
    link: "/players/h/hermabi01.shtml",
    name: "Billy Herman",
    name_first: "Billy",
    name_last: "Herman",
    search_name: ["Billy Herman"],
    teams: [
      {
        id: "LAD",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "CHC",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
      },
      { id: "PIT" },
      { id: "ATL", season_b_avg_300: "unqualified" },
    ],
    years: "1931-1947",
  },
  {
    career_b_avg_300: "unqualified",
    career_p_sv_300: "qualified",
    headshot_url: "202307191/images/headshots/2/2f097db1_sabr.jpg",
    id: "hernan007rob",
    link: "/players/h/hernaro01.shtml",
    name: "Roberto Hern\u00e1ndez",
    name_first: "Roberto",
    name_last: "Hern\u00e1ndez",
    search_name: ["Roberto Hernandez"],
    teams: [
      { id: "KCR" },
      { id: "NYM" },
      { id: "TBD", season_allstar: "qualified", season_p_sv_40: "qualified" },
      { id: "PIT" },
      { id: "SFG", season_b_avg_300: "unqualified" },
      { id: "PHI" },
      { id: "ATL" },
      { id: "LAD" },
      { id: "CLE" },
      { id: "CHW", season_allstar: "qualified" },
    ],
    years: "1991-2007",
  },
  {
    headshot_url: "202307191/images/headshots/3/3d537ba3_sabr.jpg",
    id: "hickma004cha",
    link: "/players/h/hickmch01.shtml",
    name: "Charlie Hickman",
    name_first: "Charlie",
    name_last: "Hickman",
    search_name: ["Charlie Hickman"],
    teams: [
      { id: "MIN", season_b_avg_300: "unqualified" },
      { id: "CLE", season_b_avg_300: "qualified" },
      { id: "SFG", season_b_avg_300: "qualified" },
      { id: "ATL", season_b_avg_300: "unqualified" },
      { id: "DET" },
      { id: "CHW" },
      { id: "BOS" },
    ],
    years: "1897-1908",
  },
  {
    headshot_url: "202307191/images/headshots/6/6fc4d2bd_sabr.jpg",
    id: "hillen001she",
    link: "/players/h/hillesh02.shtml",
    name: "Shea Hillenbrand",
    name_first: "Shea",
    name_last: "Hillenbrand",
    search_name: ["Shea Hillenbrand"],
    teams: [
      { id: "ARI", season_b_avg_300: "qualified" },
      { id: "LAD" },
      { id: "ANA" },
      { id: "SFG" },
      { id: "TOR", season_allstar: "qualified", season_b_avg_300: "qualified" },
      { id: "BOS", season_allstar: "qualified", season_b_avg_300: "qualified" },
    ],
    years: "2001-2007",
  },
  {
    headshot_url: "202307191/images/headshots/4/47a98881_sabr.jpg",
    id: "hopp--001joh",
    link: "/players/h/hoppjo01.shtml",
    name: "Johnny Hopp",
    name_first: "Johnny",
    name_last: "Hopp",
    search_name: ["Johnny Hopp"],
    teams: [
      { id: "LAD" },
      { id: "PIT", season_b_avg_300: "unqualified" },
      { id: "NYY", season_b_avg_300: "unqualified", ws_champ: "qualified" },
      { id: "ATL", season_allstar: "qualified", season_b_avg_300: "qualified" },
      { id: "DET" },
      { id: "STL", season_b_avg_300: "qualified", ws_champ: "qualified" },
    ],
    years: "1939-1952",
  },
  {
    career_award_hof: "qualified",
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/b/b5854fe4_sabr.jpg",
    id: "hornsb001rog",
    link: "/players/h/hornsro01.shtml",
    name: "Rogers Hornsby",
    name_first: "Rogers",
    name_last: "Hornsby",
    search_name: ["Rogers Hornsby"],
    teams: [
      {
        id: "CHC",
        season_award_mvp: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "SFG",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "ATL", season_b_avg_300: "qualified" },
      { id: "BAL", season_b_avg_300: "unqualified" },
      {
        id: "STL",
        season_award_mvp: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "1915-1937",
  },
  {
    career_award_hof: "qualified",
    headshot_url: "202307191/images/headshots/5/5fca5ae6_sabr.jpg",
    id: "hoyt--001wai",
    link: "/players/h/hoytwa01.shtml",
    name: "Waite Hoyt",
    name_first: "Waite",
    name_last: "Hoyt",
    search_name: ["Waite Hoyt"],
    teams: [
      { id: "LAD" },
      { id: "PIT" },
      { id: "SFG" },
      {
        id: "NYY",
        season_b_avg_300: "unqualified",
        season_p_w_20: "qualified",
        ws_champ: "qualified",
      },
      { id: "DET" },
      { id: "OAK", season_b_avg_300: "unqualified" },
      { id: "BOS" },
    ],
    years: "1918-1938",
  },
  {
    headshot_url: "202307191/images/headshots/d/df0ac6be_br.jpg",
    id: "hudson001orl",
    link: "/players/h/hudsoor01.shtml",
    name: "Orlando Hudson",
    name_first: "Orlando",
    name_last: "Hudson",
    search_name: ["Orlando Hudson"],
    teams: [
      { id: "MIN" },
      {
        id: "ARI",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_b_avg_300: "qualified",
      },
      {
        id: "LAD",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
      },
      { id: "SDP" },
      { id: "CHW" },
      { id: "TOR", season_award_gold_glove: "qualified" },
    ],
    years: "2002-2012",
  },
  {
    career_b_avg_300: "unqualified",
    headshot_url: "202307191/images/headshots/e/e5a952ff_sabr.jpg",
    id: "hutton001mar",
    link: "/players/h/huttoma01.shtml",
    name: "Mark Hutton",
    name_first: "Mark",
    name_last: "Hutton",
    search_name: ["Mark Hutton"],
    teams: [
      { id: "CIN", season_b_avg_300: "unqualified" },
      { id: "COL" },
      { id: "NYY" },
      { id: "FLA", season_b_avg_300: "unqualified" },
    ],
    years: "1993-1998",
  },
  {
    headshot_url: "202307191/images/headshots/8/89048053_mlbam.jpg",
    id: "iglesi001jos",
    link: "/players/i/iglesjo01.shtml",
    name: "Jos\u00e9 Iglesias",
    name_first: "Jos\u00e9",
    name_last: "Iglesias",
    search_name: ["Jose Iglesias"],
    teams: [
      { id: "COL" },
      { id: "ANA" },
      { id: "CIN" },
      { id: "BAL", season_b_avg_300: "unqualified" },
      { id: "DET", season_allstar: "qualified", season_b_avg_300: "qualified" },
      { id: "BOS", season_b_avg_300: "unqualified" },
    ],
    years: "2011-2022",
  },
  {
    career_p_sv_300: "qualified",
    headshot_url: "202307191/images/headshots/4/424ee9c7_sabr.jpg",
    id: "isring001jas",
    link: "/players/i/isrinja01.shtml",
    name: "Jason Isringhausen",
    name_first: "Jason",
    name_last: "Isringhausen",
    search_name: ["Jason Isringhausen"],
    teams: [
      { id: "TBD" },
      { id: "NYM" },
      { id: "ANA" },
      {
        id: "STL",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
        season_p_sv_40: "qualified",
      },
      { id: "OAK", season_allstar: "qualified" },
    ],
    years: "1995-2012",
  },
  {
    career_award_hof: "qualified",
    career_b_hr_500: "qualified",
    headshot_url: "202307191/images/headshots/3/365acf13_sabr-oak.jpg",
    id: "jackso002reg",
    link: "/players/j/jacksre01.shtml",
    name: "Reggie Jackson",
    name_first: "Reggie",
    name_last: "Jackson",
    search_name: ["Reggie Jackson"],
    teams: [
      {
        id: "ANA",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "NYY",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
      { id: "BAL" },
      {
        id: "OAK",
        season_allstar: "qualified",
        season_award_mvp: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "1967-1987",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/a/a2668210_sabr.jpg",
    id: "jacobs007wil",
    link: "/players/j/jacobba01.shtml",
    name: "Baby Doll Jacobson",
    name_first: "Baby Doll",
    name_last: "Jacobson",
    search_name: ["Baby Doll Jacobson"],
    teams: [
      { id: "CLE" },
      { id: "DET" },
      {
        id: "BAL",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "OAK" },
      { id: "BOS", season_b_avg_300: "unqualified" },
    ],
    years: "1915-1927",
  },
  {
    headshot_url: "202307191/images/headshots/1/1b956418_br_stl.jpg",
    id: "jay---001jon",
    link: "/players/j/jayjo02.shtml",
    name: "Jon Jay",
    name_first: "Jon",
    name_last: "Jay",
    search_name: ["Jon Jay"],
    teams: [
      { id: "KCR", season_b_avg_300: "unqualified" },
      { id: "ARI" },
      { id: "SDP" },
      { id: "ANA", season_b_avg_300: "unqualified" },
      { id: "CHC" },
      { id: "STL", season_b_avg_300: "qualified", ws_champ: "qualified" },
      { id: "CHW" },
    ],
    years: "2010-2021",
  },
  {
    headshot_url: "202307191/images/headshots/a/a038d799_sabr.jpg",
    id: "jeffer001gre",
    link: "/players/j/jeffegr01.shtml",
    name: "Gregg Jefferies",
    name_first: "Gregg",
    name_last: "Jefferies",
    search_name: ["Gregg Jefferies"],
    teams: [
      { id: "KCR" },
      { id: "NYM", season_b_avg_300: "qualified" },
      { id: "ANA", season_b_avg_300: "unqualified" },
      { id: "PHI", season_b_avg_300: "qualified" },
      { id: "DET" },
      {
        id: "STL",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_sb_30: "qualified",
      },
    ],
    years: "1987-2000",
  },
  {
    career_b_avg_300: "unqualified",
    headshot_url: "202307191/images/headshots/a/a94e4183_sabr.jpg",
    id: "jeffer001reg",
    link: "/players/j/jeffere01.shtml",
    name: "Reggie Jefferson",
    name_first: "Reggie",
    name_last: "Jefferson",
    search_name: ["Reggie Jefferson"],
    teams: [
      { id: "CLE", season_b_avg_300: "unqualified" },
      { id: "CIN" },
      { id: "SEA", season_b_avg_300: "unqualified" },
      { id: "BOS", season_b_avg_300: "qualified" },
    ],
    years: "1991-1999",
  },
  {
    career_award_hof: "qualified",
    career_p_so_3000: "qualified",
    headshot_url: "202307191/images/headshots/7/7b2f6e52_sabr.jpg",
    id: "jenkin001fer",
    link: "/players/j/jenkife01.shtml",
    name: "Fergie Jenkins",
    name_first: "Fergie",
    name_last: "Jenkins",
    search_name: ["Fergie Jenkins"],
    teams: [
      {
        id: "TEX",
        season_b_avg_300: "unqualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
      },
      {
        id: "CHC",
        season_allstar: "qualified",
        season_award_cy_young: "qualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
      },
      { id: "PHI" },
      { id: "BOS" },
    ],
    years: "1965-1983",
  },
  {
    headshot_url: "202307191/images/headshots/c/cb280268_sabr.jpg",
    id: "john--001tho",
    link: "/players/j/johnto01.shtml",
    name: "Tommy John",
    name_first: "Tommy",
    name_last: "John",
    search_name: ["Tommy John"],
    teams: [
      { id: "LAD", season_allstar: "qualified", season_p_w_20: "qualified" },
      { id: "ANA" },
      { id: "CLE" },
      { id: "NYY", season_allstar: "qualified", season_p_w_20: "qualified" },
      { id: "OAK" },
      { id: "CHW", season_allstar: "qualified" },
    ],
    years: "1963-1989",
  },
  {
    career_award_hof: "qualified",
    career_p_so_3000: "qualified",
    career_p_w_300: "qualified",
    headshot_url: "202307191/images/headshots/e/e905e1ef_br.jpg",
    id: "johnso002ran",
    link: "/players/j/johnsra05.shtml",
    name: "Randy Johnson",
    name_first: "Randy",
    name_last: "Johnson",
    search_name: ["Randy Johnson"],
    teams: [
      {
        id: "ARI",
        season_allstar: "qualified",
        season_award_cy_young: "qualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
        ws_champ: "qualified",
      },
      { id: "WSN" },
      { id: "SFG" },
      { id: "NYY", season_p_so_200: "qualified" },
      {
        id: "SEA",
        season_allstar: "qualified",
        season_award_cy_young: "qualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
      },
      { id: "HOU" },
    ],
    years: "1988-2009",
  },
  {
    headshot_url: "202307191/images/headshots/6/61ae0ee4_sabr.jpg",
    id: "johnso006ken",
    link: "/players/j/johnsla03.shtml",
    name: "Lance Johnson",
    name_first: "Lance",
    name_last: "Johnson",
    search_name: ["Lance Johnson"],
    teams: [
      {
        id: "NYM",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "CHC", season_b_avg_300: "unqualified" },
      { id: "NYY", season_b_avg_300: "unqualified" },
      { id: "STL" },
      { id: "CHW", season_b_avg_300: "qualified", season_b_sb_30: "qualified" },
    ],
    years: "1987-2000",
  },
  {
    headshot_url: "202307191/images/headshots/1/18ed0c6b_sabr.jpg",
    id: "johnso001dav",
    link: "/players/j/johnsda02.shtml",
    name: "Davey Johnson",
    name_first: "Davey",
    name_last: "Johnson",
    search_name: ["Davey Johnson"],
    teams: [
      { id: "CHC", season_b_avg_300: "unqualified" },
      { id: "PHI", season_b_avg_300: "unqualified" },
      {
        id: "ATL",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_hr_40: "qualified",
      },
      {
        id: "BAL",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "1965-1978",
  },
  {
    headshot_url: "202307191/images/headshots/2/2ad87d7d_sabr.jpg",
    id: "johnso002ale",
    link: "/players/j/johnsal01.shtml",
    name: "Alex Johnson",
    name_first: "Alex",
    name_last: "Johnson",
    search_name: ["Alex Johnson"],
    teams: [
      { id: "PHI", season_b_avg_300: "unqualified" },
      { id: "STL", ws_champ: "qualified" },
      { id: "TEX" },
      { id: "CIN", season_b_avg_300: "qualified" },
      {
        id: "ANA",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
      },
      { id: "CLE" },
      { id: "NYY" },
      { id: "DET" },
    ],
    years: "1964-1976",
  },
  {
    headshot_url: "202307191/images/headshots/5/5b8d84d0_sabr.jpg",
    id: "johnso025rob",
    link: "/players/j/johnsbo02.shtml",
    name: "Bob Johnson",
    name_first: "Bob",
    name_last: "Johnson",
    search_name: ["Bob Johnson"],
    teams: [
      { id: "NYM", season_b_avg_300: "unqualified" },
      { id: "TEX" },
      { id: "CIN" },
      { id: "ATL" },
      { id: "STL" },
      { id: "BAL", season_b_avg_300: "unqualified", ws_champ: "qualified" },
      { id: "OAK", season_b_avg_300: "unqualified" },
    ],
    years: "1960-1970",
  },
  {
    career_p_sv_300: "qualified",
    headshot_url: "202307191/images/headshots/0/094f0c23_davis.jpg",
    id: "jones-002dou",
    link: "/players/j/jonesdo01.shtml",
    name: "Doug Jones",
    name_first: "Doug",
    name_last: "Jones",
    search_name: ["Doug Jones"],
    teams: [
      { id: "CHC" },
      { id: "CLE", season_allstar: "qualified", season_p_sv_40: "qualified" },
      {
        id: "PHI",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
      },
      { id: "BAL" },
      { id: "HOU", season_allstar: "qualified" },
      { id: "OAK" },
      { id: "MIL" },
    ],
    years: "1982-2000",
  },
  {
    career_p_sv_300: "qualified",
    headshot_url: "202307191/images/headshots/5/5ec10c72_sabr.jpg",
    id: "jones-001tod",
    link: "/players/j/jonesto02.shtml",
    name: "Todd Jones",
    name_first: "Todd",
    name_last: "Jones",
    search_name: ["Todd Jones"],
    teams: [
      { id: "MIN" },
      { id: "COL" },
      { id: "PHI" },
      { id: "BOS" },
      { id: "CIN" },
      { id: "HOU", season_b_avg_300: "unqualified" },
      { id: "DET", season_allstar: "qualified", season_p_sv_40: "qualified" },
      {
        id: "FLA",
        season_b_avg_300: "unqualified",
        season_p_sv_40: "qualified",
      },
    ],
    years: "1993-2008",
  },
  {
    headshot_url: "202307191/images/headshots/a/aa010a66_sabr.jpg",
    id: "jones-006sam",
    link: "/players/j/jonessa01.shtml",
    name: "Sad Sam Jones",
    name_first: "Sad Sam",
    name_last: "Jones",
    search_name: ["Sad Sam Jones"],
    teams: [
      { id: "MIN", season_b_avg_300: "unqualified" },
      { id: "CLE", season_b_avg_300: "unqualified" },
      { id: "NYY", season_p_w_20: "qualified", ws_champ: "qualified" },
      { id: "BAL" },
      { id: "CHW" },
      {
        id: "BOS",
        season_b_avg_300: "unqualified",
        season_p_w_20: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "1914-1935",
  },
  {
    career_award_hof: "qualified",
    headshot_url: "202307191/images/headshots/d/db7b7601_sabr.jpg",
    id: "kaat--001jam",
    link: "/players/k/kaatji01.shtml",
    name: "Jim Kaat",
    name_first: "Jim",
    name_last: "Kaat",
    search_name: ["Jim Kaat"],
    teams: [
      {
        id: "MIN",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
      },
      { id: "NYY" },
      { id: "PHI", season_award_gold_glove: "qualified" },
      { id: "STL", season_b_avg_300: "unqualified", ws_champ: "qualified" },
      {
        id: "CHW",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_p_w_20: "qualified",
      },
    ],
    years: "1959-1983",
  },
  {
    headshot_url: "202307191/images/headshots/4/425948f6_sabr.jpg",
    id: "kapler001gab",
    link: "/players/k/kaplega01.shtml",
    name: "Gabe Kapler",
    name_first: "Gabe",
    name_last: "Kapler",
    search_name: ["Gabe Kapler"],
    teams: [
      { id: "TBD" },
      { id: "TEX", season_b_avg_300: "qualified" },
      { id: "COL", season_b_avg_300: "unqualified" },
      { id: "DET" },
      { id: "MIL", season_b_avg_300: "unqualified" },
      { id: "BOS", ws_champ: "qualified" },
    ],
    years: "1998-2010",
  },
  {
    career_b_avg_300: "unqualified",
    headshot_url: "202307191/images/headshots/9/9f31dbcc_sabr.jpg",
    id: "keiste001wil",
    link: "/players/k/keistbi01.shtml",
    name: "Bill Keister",
    name_first: "Bill",
    name_last: "Keister",
    search_name: ["Bill Keister"],
    teams: [
      { id: "MIN", season_b_avg_300: "qualified" },
      { id: "PHI", season_b_avg_300: "unqualified" },
      { id: "ATL" },
      { id: "STL", season_b_avg_300: "qualified", season_b_sb_30: "qualified" },
    ],
    years: "1896-1903",
  },
  {
    career_award_hof: "qualified",
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/a/acecef17_sabr.jpg",
    id: "kell--001geo",
    link: "/players/k/kellge01.shtml",
    name: "George Kell",
    name_first: "George",
    name_last: "Kell",
    search_name: ["George Kell"],
    teams: [
      { id: "BAL", season_allstar: "qualified" },
      {
        id: "DET",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "CHW", season_allstar: "qualified", season_b_avg_300: "qualified" },
      { id: "BOS", season_allstar: "qualified", season_b_avg_300: "qualified" },
      { id: "OAK" },
    ],
    years: "1943-1957",
  },
  {
    career_award_hof: "qualified",
    headshot_url: "202307191/images/headshots/3/3e4a0c89_sabr.jpg",
    id: "kelly-003geo",
    link: "/players/k/kellyge01.shtml",
    name: "George Kelly",
    name_first: "George",
    name_last: "Kelly",
    search_name: ["George Kelly"],
    teams: [
      { id: "LAD" },
      { id: "CHC", season_b_avg_300: "unqualified" },
      { id: "CIN", season_b_rbi_100: "qualified" },
      { id: "PIT" },
      {
        id: "SFG",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "1915-1932",
  },
  {
    career_award_hof: "qualified",
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/1/17b00755_sabr.jpg",
    id: "kelley002jos",
    link: "/players/k/kellejo01.shtml",
    name: "Joe Kelley",
    name_first: "Joe",
    name_last: "Kelley",
    search_name: ["Joe Kelley"],
    teams: [
      { id: "LAD", season_b_avg_300: "qualified", season_b_sb_30: "qualified" },
      { id: "CIN", season_b_avg_300: "qualified" },
      { id: "PIT" },
      { id: "ATL" },
    ],
    years: "1891-1908",
  },
  {
    headshot_url: "202307191/images/headshots/2/25493b33_sabr.jpg",
    id: "kelly-009rob",
    link: "/players/k/kellyro01.shtml",
    name: "Roberto Kelly",
    name_first: "Roberto",
    name_last: "Kelly",
    search_name: ["Roberto Kelly"],
    teams: [
      { id: "MIN", season_b_avg_300: "unqualified" },
      { id: "ATL" },
      { id: "LAD" },
      { id: "TEX", season_b_avg_300: "unqualified" },
      {
        id: "CIN",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
      },
      { id: "WSN" },
      {
        id: "NYY",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "SEA" },
    ],
    years: "1987-2000",
  },
  {
    headshot_url: "202307191/images/headshots/5/5c319114_sabr.jpg",
    id: "kent--001jef",
    link: "/players/k/kentje01.shtml",
    name: "Jeff Kent",
    name_first: "Jeff",
    name_last: "Kent",
    search_name: ["Jeff Kent"],
    teams: [
      {
        id: "LAD",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "NYM" },
      { id: "CLE" },
      {
        id: "SFG",
        season_allstar: "qualified",
        season_award_mvp: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "HOU", season_allstar: "qualified", season_b_rbi_100: "qualified" },
      { id: "TOR" },
    ],
    years: "1992-2008",
  },
  {
    career_b_avg_300: "unqualified",
    headshot_url: "202307191/images/headshots/b/ba07cc73_sabr.jpg",
    id: "keough001mat",
    link: "/players/k/keougma02.shtml",
    name: "Matt Keough",
    name_first: "Matt",
    name_last: "Keough",
    search_name: ["Matt Keough"],
    teams: [
      { id: "CHC", season_b_avg_300: "unqualified" },
      { id: "NYY" },
      { id: "HOU", season_b_avg_300: "unqualified" },
      { id: "STL" },
      { id: "OAK", season_allstar: "qualified" },
    ],
    years: "1977-1986",
  },
  {
    headshot_url: "202307191/images/headshots/5/5cb32ffa_sabr.jpg",
    id: "keppin001jef",
    link: "/players/k/keppije01.shtml",
    name: "Jeff Keppinger",
    name_first: "Jeff",
    name_last: "Keppinger",
    search_name: ["Jeff Keppinger"],
    teams: [
      { id: "KCR" },
      { id: "TBD", season_b_avg_300: "unqualified" },
      { id: "NYM" },
      { id: "CIN", season_b_avg_300: "unqualified" },
      { id: "SFG" },
      { id: "HOU", season_b_avg_300: "qualified" },
      { id: "CHW" },
    ],
    years: "2004-2013",
  },
  {
    career_p_sv_300: "qualified",
    headshot_url: "202307191/images/headshots/8/869305cd_mlbam.jpg",
    id: "kimbre001cra",
    link: "/players/k/kimbrcr01.shtml",
    name: "Craig Kimbrel",
    name_first: "Craig",
    name_last: "Kimbrel",
    search_name: ["Craig Kimbrel"],
    teams: [
      { id: "LAD" },
      { id: "SDP" },
      { id: "CHC", season_allstar: "qualified" },
      { id: "PHI", season_allstar: "qualified" },
      {
        id: "ATL",
        season_allstar: "qualified",
        season_award_roty: "qualified",
        season_p_sv_40: "qualified",
      },
      { id: "CHW" },
      {
        id: "BOS",
        season_allstar: "qualified",
        season_p_sv_40: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "2010-2023",
  },
  {
    headshot_url: "202307191/images/headshots/4/4037c26a_br.jpg",
    id: "kinsle001ian",
    link: "/players/k/kinslia01.shtml",
    name: "Ian Kinsler",
    name_first: "Ian",
    name_last: "Kinsler",
    search_name: ["Ian Kinsler"],
    teams: [
      {
        id: "TEX",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_hr_30_sb_30: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "SDP" },
      { id: "ANA", season_award_gold_glove: "qualified" },
      {
        id: "DET",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
      },
      {
        id: "BOS",
        season_award_gold_glove: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "2006-2019",
  },
  {
    career_b_avg_300: "unqualified",
    headshot_url: "202307191/images/headshots/7/72ecb5a6_davis.jpg",
    id: "kirke-002jud",
    link: "/players/k/kirkeja01.shtml",
    name: "Jay Kirke",
    name_first: "Jay",
    name_last: "Kirke",
    search_name: ["Jay Kirke"],
    teams: [
      { id: "CLE", season_b_avg_300: "unqualified" },
      { id: "SFG" },
      { id: "ATL", season_b_avg_300: "unqualified" },
      { id: "DET" },
    ],
    years: "1910-1918",
  },
  {
    headshot_url: "202307191/images/headshots/7/718c5590_sabr.jpg",
    id: "kluttz001cly",
    link: "/players/k/kluttcl01.shtml",
    name: "Clyde Kluttz",
    name_first: "Clyde",
    name_last: "Kluttz",
    search_name: ["Clyde Kluttz"],
    teams: [
      { id: "MIN", season_b_avg_300: "unqualified" },
      { id: "PIT", season_b_avg_300: "unqualified" },
      { id: "SFG", season_b_avg_300: "unqualified" },
      { id: "ATL" },
      { id: "BAL", season_b_avg_300: "unqualified" },
      { id: "STL", ws_champ: "qualified" },
    ],
    years: "1942-1952",
  },
  {
    career_b_avg_300: "unqualified",
    headshot_url: "202307191/images/headshots/a/a4bc46d9_sabr.jpg",
    id: "kruege001wil",
    link: "/players/k/kruegbi01.shtml",
    name: "Bill Krueger",
    name_first: "Bill",
    name_last: "Krueger",
    search_name: ["Bill Krueger"],
    teams: [
      { id: "MIN" },
      { id: "OAK" },
      { id: "LAD" },
      { id: "SDP", season_b_avg_300: "unqualified" },
      { id: "WSN" },
      { id: "SEA" },
      { id: "DET" },
      { id: "MIL" },
    ],
    years: "1983-1995",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/a/a79cd3a2_sabr.jpg",
    id: "kuenn-001har",
    link: "/players/k/kuennha01.shtml",
    name: "Harvey Kuenn",
    name_first: "Harvey",
    name_last: "Kuenn",
    search_name: ["Harvey Kuenn"],
    teams: [
      { id: "CHC", season_b_avg_300: "unqualified" },
      { id: "CLE", season_allstar: "qualified", season_b_avg_300: "qualified" },
      { id: "SFG", season_b_avg_300: "qualified" },
      { id: "PHI" },
      {
        id: "DET",
        season_allstar: "qualified",
        season_award_roty: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
      },
    ],
    years: "1952-1966",
  },
  {
    career_b_avg_300: "unqualified",
    headshot_url: "202307191/images/headshots/1/17effd3f_sabr.jpg",
    id: "lamar-001wil",
    link: "/players/l/lamarbi01.shtml",
    name: "Bill Lamar",
    name_first: "Bill",
    name_last: "Lamar",
    search_name: ["Bill Lamar"],
    teams: [
      { id: "LAD", season_b_avg_300: "unqualified" },
      { id: "NYY" },
      { id: "OAK", season_b_avg_300: "qualified", season_b_h_200: "qualified" },
      { id: "BOS" },
    ],
    years: "1917-1927",
  },
  {
    headshot_url: "202307191/images/headshots/2/2b1a1fee_sabr.jpg",
    id: "larsen001don",
    link: "/players/l/larsedo01.shtml",
    name: "Don Larsen",
    name_first: "Don",
    name_last: "Larsen",
    search_name: ["Don Larsen"],
    teams: [
      { id: "CHC" },
      { id: "SFG" },
      { id: "NYY", season_b_avg_300: "unqualified", ws_champ: "qualified" },
      { id: "HOU" },
      { id: "BAL" },
      { id: "CHW", season_b_avg_300: "unqualified" },
      { id: "OAK", season_b_avg_300: "unqualified" },
    ],
    years: "1953-1967",
  },
  {
    headshot_url: "202307191/images/headshots/2/2c6e52b2_sabr.jpg",
    id: "lee---004car",
    link: "/players/l/leeca01.shtml",
    name: "Carlos Lee",
    name_first: "Carlos",
    name_last: "Lee",
    search_name: ["Carlos Lee"],
    teams: [
      { id: "TEX", season_b_avg_300: "unqualified" },
      { id: "FLA" },
      {
        id: "HOU",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "MIL",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "CHW",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
    ],
    years: "1999-2012",
  },
  {
    career_award_hof: "qualified",
    career_p_sv_300: "qualified",
    headshot_url: "202307191/images/headshots/4/4862dce7_sabr.jpg",
    id: "smith-001lee",
    link: "/players/s/smithle02.shtml",
    name: "Lee Smith",
    name_first: "Lee",
    name_last: "Smith",
    search_name: ["Lee Smith"],
    teams: [
      { id: "STL", season_allstar: "qualified", season_p_sv_40: "qualified" },
      { id: "BOS" },
      { id: "CHC", season_allstar: "qualified" },
      { id: "ANA", season_allstar: "qualified" },
      { id: "CIN" },
      { id: "WSN" },
      { id: "NYY" },
      { id: "BAL", season_allstar: "qualified" },
    ],
    years: "1980-1997",
  },
  {
    headshot_url: "202307191/images/headshots/1/1e424faf_sabr.jpg",
    id: "may---002lee",
    link: "/players/m/mayle01.shtml",
    name: "Lee May",
    name_first: "Lee",
    name_last: "May",
    search_name: ["Lee May"],
    teams: [
      { id: "KCR", season_b_avg_300: "unqualified" },
      {
        id: "CIN",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "BAL", season_b_rbi_100: "qualified" },
      { id: "HOU", season_allstar: "qualified", season_b_rbi_100: "qualified" },
    ],
    years: "1965-1982",
  },
  {
    career_b_avg_300: "unqualified",
    headshot_url: "202307191/images/headshots/6/648fac91_sabr.jpg",
    id: "lee---003cli",
    link: "/players/l/leecl01.shtml",
    name: "Cliff Lee",
    name_first: "Cliff",
    name_last: "Lee",
    search_name: ["Cliff Lee"],
    teams: [
      { id: "CLE", season_b_avg_300: "unqualified" },
      { id: "CIN", season_b_avg_300: "unqualified" },
      { id: "PIT" },
      { id: "PHI", season_b_avg_300: "unqualified" },
    ],
    years: "1919-1926",
  },
  {
    headshot_url: "202307191/images/headshots/e/e8c668bf_sabr.jpg",
    id: "leonar001jef",
    link: "/players/l/leonaje01.shtml",
    name: "Jeffrey Leonard",
    name_first: "Jeffrey",
    name_last: "Leonard",
    search_name: ["Jeffrey Leonard"],
    teams: [
      { id: "LAD", season_b_avg_300: "unqualified" },
      { id: "SFG", season_allstar: "qualified", season_b_avg_300: "qualified" },
      { id: "SEA", season_allstar: "qualified" },
      { id: "HOU", season_b_avg_300: "unqualified" },
      { id: "MIL" },
    ],
    years: "1977-1990",
  },
  {
    career_award_hof: "qualified",
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/b/b4f653b8_sabr.jpg",
    id: "lindst001fre",
    link: "/players/l/lindsfr01.shtml",
    name: "Freddie Lindstrom",
    name_first: "Freddie",
    name_last: "Lindstrom",
    search_name: ["Freddie Lindstrom"],
    teams: [
      { id: "LAD" },
      { id: "CHC" },
      { id: "PIT", season_b_avg_300: "qualified" },
      {
        id: "SFG",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
      },
    ],
    years: "1924-1936",
  },
  {
    headshot_url: "202307191/images/headshots/0/0dddd15b_sabr.jpg",
    id: "lofton001ken",
    link: "/players/l/loftoke01.shtml",
    name: "Kenny Lofton",
    name_first: "Kenny",
    name_last: "Lofton",
    search_name: ["Kenny Lofton"],
    teams: [
      { id: "PIT" },
      { id: "SFG" },
      { id: "PHI", season_b_avg_300: "unqualified" },
      { id: "ATL", season_allstar: "qualified", season_b_avg_300: "qualified" },
      { id: "TEX", season_b_avg_300: "unqualified" },
      { id: "LAD", season_b_avg_300: "qualified", season_b_sb_30: "qualified" },
      {
        id: "CLE",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "CHC", season_b_avg_300: "unqualified" },
      { id: "NYY" },
      { id: "HOU" },
      { id: "CHW" },
    ],
    years: "1991-2007",
  },
  {
    career_award_hof: "qualified",
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/2/23f3d8e3_sabr.jpg",
    id: "lombar001ern",
    link: "/players/l/lombaer01.shtml",
    name: "Ernie Lombardi",
    name_first: "Ernie",
    name_last: "Lombardi",
    search_name: ["Ernie Lombardi"],
    teams: [
      { id: "LAD" },
      {
        id: "CIN",
        season_allstar: "qualified",
        season_award_mvp: "qualified",
        season_b_avg_300: "qualified",
        ws_champ: "qualified",
      },
      {
        id: "SFG",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
      },
      {
        id: "ATL",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
      },
    ],
    years: "1931-1947",
  },
  {
    career_award_hof: "qualified",
    headshot_url: "202307191/images/headshots/0/03cbf1cc_sabr.jpg",
    id: "lopez-002alf",
    link: "/players/l/lopezal01.shtml",
    name: "Al L\u00f3pez",
    name_first: "Al",
    name_last: "Lopez",
    search_name: ["Al Lopez"],
    teams: [
      { id: "LAD", season_allstar: "qualified", season_b_avg_300: "qualified" },
      { id: "CLE" },
      {
        id: "PIT",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
      },
      { id: "ATL" },
    ],
    years: "1928-1947",
  },
  {
    headshot_url: "202307191/images/headshots/e/ea62843e_sabr.jpg",
    id: "lopez-001fel",
    link: "/players/l/lopezfe01.shtml",
    name: "Felipe L\u00f3pez",
    name_first: "Felipe",
    name_last: "L\u00f3pez",
    search_name: ["Felipe Lopez"],
    teams: [
      { id: "ARI", season_b_avg_300: "unqualified" },
      { id: "TBD" },
      { id: "STL", season_b_avg_300: "unqualified" },
      { id: "BOS" },
      {
        id: "CIN",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
      },
      { id: "WSN" },
      { id: "TOR" },
      { id: "MIL", season_b_avg_300: "unqualified" },
    ],
    years: "2001-2011",
  },
  {
    headshot_url: "202307191/images/headshots/e/e0f6294f_sabr.jpg",
    id: "lorett001mar",
    link: "/players/l/loretma01.shtml",
    name: "Mark Loretta",
    name_first: "Mark",
    name_last: "Loretta",
    search_name: ["Mark Loretta"],
    teams: [
      { id: "LAD" },
      {
        id: "SDP",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
      },
      { id: "HOU", season_b_avg_300: "qualified" },
      { id: "BOS", season_allstar: "qualified" },
      { id: "MIL", season_b_avg_300: "qualified" },
    ],
    years: "1995-2009",
  },
  {
    headshot_url: "202307191/images/headshots/0/089b7000_sabr_mil.jpg",
    id: "lucroy001jon",
    link: "/players/l/lucrojo01.shtml",
    name: "Jonathan Lucroy",
    name_first: "Jonathan",
    name_last: "Lucroy",
    search_name: ["Jonathan Lucroy"],
    teams: [
      { id: "COL", season_b_avg_300: "unqualified" },
      { id: "ATL" },
      { id: "BOS" },
      { id: "OAK" },
      { id: "TEX" },
      { id: "WSN", season_b_avg_300: "unqualified" },
      { id: "ANA" },
      { id: "CHC" },
      { id: "MIL", season_allstar: "qualified", season_b_avg_300: "qualified" },
    ],
    years: "2010-2021",
  },
  {
    career_b_avg_300: "unqualified",
    headshot_url: "202307191/images/headshots/c/c5efcc91_sabr.jpg",
    id: "lyon--001bra",
    link: "/players/l/lyonbr01.shtml",
    name: "Brandon Lyon",
    name_first: "Brandon",
    name_last: "Lyon",
    search_name: ["Brandon Lyon"],
    teams: [
      { id: "ARI" },
      { id: "NYM" },
      { id: "HOU", season_b_avg_300: "unqualified" },
      { id: "DET" },
      { id: "BOS" },
      { id: "TOR" },
    ],
    years: "2001-2013",
  },
  {
    career_award_hof: "qualified",
    career_p_so_3000: "qualified",
    career_p_w_300: "qualified",
    headshot_url: "202307191/images/headshots/d/d13d4022_sabr.jpg",
    id: "maddux001gre",
    link: "/players/m/maddugr01.shtml",
    name: "Greg Maddux",
    name_first: "Greg",
    name_last: "Maddux",
    search_name: ["Greg Maddux"],
    teams: [
      { id: "LAD", season_award_gold_glove: "qualified" },
      { id: "SDP", season_award_gold_glove: "qualified" },
      {
        id: "CHC",
        season_allstar: "qualified",
        season_award_cy_young: "qualified",
        season_award_gold_glove: "qualified",
        season_b_avg_300: "unqualified",
        season_p_w_20: "qualified",
      },
      {
        id: "ATL",
        season_allstar: "qualified",
        season_award_cy_young: "qualified",
        season_award_gold_glove: "qualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "1986-2008",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/4/435d8ed1_sabr.jpg",
    id: "madloc001bil",
    link: "/players/m/madlobi01.shtml",
    name: "Bill Madlock",
    name_first: "Bill",
    name_last: "Madlock",
    search_name: ["Bill Madlock"],
    teams: [
      { id: "LAD", season_b_avg_300: "unqualified" },
      { id: "TEX", season_b_avg_300: "unqualified" },
      { id: "CHC", season_allstar: "qualified", season_b_avg_300: "qualified" },
      {
        id: "PIT",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        ws_champ: "qualified",
      },
      { id: "SFG", season_b_avg_300: "qualified" },
      { id: "DET" },
    ],
    years: "1973-1987",
  },
  {
    headshot_url: "202307191/images/headshots/1/14a1c919_sabr.jpg",
    id: "magada001dav",
    link: "/players/m/magadda01.shtml",
    name: "Dave Magadan",
    name_first: "Dave",
    name_last: "Magadan",
    search_name: ["Dave Magadan"],
    teams: [
      { id: "NYM", season_b_avg_300: "qualified" },
      { id: "SDP" },
      { id: "CHC" },
      { id: "SEA" },
      { id: "HOU", season_b_avg_300: "unqualified" },
      { id: "FLA" },
      { id: "OAK", season_b_avg_300: "unqualified" },
    ],
    years: "1986-2001",
  },
  {
    headshot_url: "202307191/images/headshots/4/44d518ff_sabr.jpg",
    id: "majesk001hen",
    link: "/players/m/majesha01.shtml",
    name: "Hank Majeski",
    name_first: "Hank",
    name_last: "Majeski",
    search_name: ["Hank Majeski"],
    teams: [
      { id: "CLE", season_b_avg_300: "unqualified" },
      { id: "NYY" },
      { id: "ATL" },
      { id: "BAL" },
      { id: "CHW", season_b_avg_300: "unqualified" },
      {
        id: "OAK",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
    ],
    years: "1939-1955",
  },
  {
    career_award_hof: "qualified",
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/1/17088fe1_sabr.jpg",
    id: "manush001hen",
    link: "/players/m/manushe01.shtml",
    name: "Heinie Manush",
    name_first: "Heinie",
    name_last: "Manush",
    search_name: ["Heinie Manush"],
    teams: [
      {
        id: "MIN",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "LAD", season_b_avg_300: "qualified" },
      { id: "PIT", season_b_avg_300: "unqualified" },
      {
        id: "BAL",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "DET", season_b_avg_300: "qualified" },
      { id: "BOS" },
    ],
    years: "1923-1939",
  },
  {
    headshot_url: "202307191/images/headshots/1/13ab5ed2_mlbam.jpg",
    id: "marte-001sta",
    link: "/players/m/martest01.shtml",
    name: "Starling Marte",
    name_first: "Starling",
    name_last: "Marte",
    search_name: ["Starling Marte"],
    teams: [
      { id: "ARI", season_b_avg_300: "unqualified" },
      { id: "NYM", season_allstar: "qualified" },
      {
        id: "PIT",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_b_avg_300: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "FLA", season_b_avg_300: "unqualified" },
      { id: "OAK", season_b_avg_300: "unqualified" },
    ],
    years: "2012-2023",
  },
  {
    career_award_hof: "qualified",
    career_p_so_3000: "qualified",
    headshot_url: "202307191/images/headshots/a/a9ba2c91_br.jpg",
    id: "martin004ped",
    link: "/players/m/martipe02.shtml",
    name: "Pedro Mart\u00ednez",
    name_first: "Pedro",
    name_last: "Mart\u00ednez",
    search_name: ["Pedro Martinez"],
    teams: [
      { id: "NYM", season_allstar: "qualified", season_p_so_200: "qualified" },
      { id: "LAD" },
      {
        id: "WSN",
        season_allstar: "qualified",
        season_award_cy_young: "qualified",
        season_p_so_200: "qualified",
      },
      { id: "PHI" },
      {
        id: "BOS",
        season_allstar: "qualified",
        season_award_cy_young: "qualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "1992-2009",
  },
  {
    headshot_url: "202307191/images/headshots/9/94144588_mlbam.jpg",
    id: "martin005jul",
    link: "/players/m/martijd02.shtml",
    name: "J.D. Martinez",
    name_first: "J.D.",
    name_last: "Martinez",
    search_name: ["J.D. Martinez", "JD Martinez"],
    teams: [
      { id: "ARI", season_b_avg_300: "unqualified" },
      { id: "LAD", season_allstar: "qualified" },
      {
        id: "DET",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "HOU" },
      {
        id: "BOS",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "2011-2023",
  },
  {
    headshot_url: "202307191/images/headshots/9/99ca7c89_davis.jpg",
    id: "mays--001car",
    link: "/players/m/maysca01.shtml",
    name: "Carl Mays",
    name_first: "Carl",
    name_last: "Mays",
    search_name: ["Carl Mays"],
    teams: [
      {
        id: "CIN",
        season_b_avg_300: "unqualified",
        season_p_w_20: "qualified",
      },
      { id: "SFG", season_b_avg_300: "unqualified" },
      {
        id: "NYY",
        season_b_avg_300: "unqualified",
        season_p_w_20: "qualified",
        ws_champ: "qualified",
      },
      { id: "BOS", season_p_w_20: "qualified", ws_champ: "qualified" },
    ],
    years: "1915-1929",
  },
  {
    career_award_hof: "qualified",
    headshot_url: "202307191/images/headshots/2/2187c402_sabr.jpg",
    id: "mccart001tom",
    link: "/players/m/mccarto01.shtml",
    name: "Tommy McCarthy",
    name_first: "Tommy",
    name_last: "McCarthy",
    search_name: ["Tommy McCarthy"],
    teams: [
      { id: "LAD" },
      { id: "PHI" },
      {
        id: "ATL",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
        season_b_sb_30: "qualified",
      },
      {
        id: "STL",
        season_b_avg_300: "qualified",
        season_b_sb_30: "qualified",
      },
    ],
    years: "1884-1896",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/2/2dfa35e9_sabr.jpg",
    id: "mccosk001wil",
    link: "/players/m/mccosba01.shtml",
    name: "Barney McCosky",
    name_first: "Barney",
    name_last: "McCosky",
    search_name: ["Barney McCosky"],
    teams: [
      { id: "CLE" },
      { id: "CIN", season_b_avg_300: "unqualified" },
      { id: "DET", season_b_avg_300: "qualified", season_b_h_200: "qualified" },
      { id: "OAK", season_b_avg_300: "qualified" },
    ],
    years: "1939-1953",
  },
  {
    career_award_hof: "qualified",
    headshot_url: "202307191/images/headshots/6/62733b6a_sabr.jpg",
    id: "mcgrif001fre",
    link: "/players/m/mcgrifr01.shtml",
    name: "Fred McGriff",
    name_first: "Fred",
    name_last: "McGriff",
    search_name: ["Fred McGriff"],
    teams: [
      { id: "LAD" },
      {
        id: "TBD",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "SDP",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "CHC", season_b_rbi_100: "qualified" },
      {
        id: "ATL",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
      {
        id: "TOR",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
      },
    ],
    years: "1986-2004",
  },
  {
    headshot_url: "202307191/images/headshots/6/62d7cf30_sabr.jpg",
    id: "mcguir006jam",
    link: "/players/m/mcguide01.shtml",
    name: "Deacon McGuire",
    name_first: "Deacon",
    name_last: "McGuire",
    search_name: ["Deacon McGuire"],
    teams: [
      { id: "PHI", season_b_avg_300: "unqualified" },
      { id: "BOS", season_b_avg_300: "unqualified" },
      { id: "LAD", season_b_avg_300: "unqualified" },
      { id: "CLE", season_b_avg_300: "unqualified" },
      { id: "NYY" },
      { id: "DET", season_b_avg_300: "unqualified" },
    ],
    years: "1884-1912",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/0/0bad180f_davis.jpg",
    id: "mcinni002joh",
    link: "/players/m/mcinnst01.shtml",
    name: "Stuffy McInnis",
    name_first: "Stuffy",
    name_last: "McInnis",
    search_name: ["Stuffy McInnis"],
    teams: [
      { id: "CLE", season_b_avg_300: "qualified" },
      { id: "PIT", season_b_avg_300: "unqualified", ws_champ: "qualified" },
      { id: "PHI" },
      { id: "ATL", season_b_avg_300: "qualified" },
      { id: "BOS", season_b_avg_300: "qualified", ws_champ: "qualified" },
      {
        id: "OAK",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "1909-1927",
  },
  {
    headshot_url: "202307191/images/headshots/c/c7883e0c_sabr.jpg",
    id: "mcmaho001don",
    link: "/players/m/mcmahdo02.shtml",
    name: "Don McMahon",
    name_first: "Don",
    name_last: "McMahon",
    search_name: ["Don McMahon"],
    teams: [
      { id: "CLE" },
      { id: "SFG", season_b_avg_300: "unqualified" },
      { id: "ATL", season_allstar: "qualified", ws_champ: "qualified" },
      { id: "DET", ws_champ: "qualified" },
      { id: "HOU" },
      { id: "CHW", season_b_avg_300: "unqualified" },
      { id: "BOS" },
    ],
    years: "1957-1974",
  },
  {
    career_award_hof: "qualified",
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/8/8fed3607_sabr.jpg",
    id: "medwic001jos",
    link: "/players/m/medwijo01.shtml",
    name: "Joe Medwick",
    name_first: "Joe",
    name_last: "Medwick",
    search_name: ["Joe Medwick"],
    teams: [
      { id: "LAD", season_allstar: "qualified", season_b_avg_300: "qualified" },
      { id: "SFG", season_allstar: "qualified", season_b_avg_300: "qualified" },
      { id: "ATL" },
      {
        id: "STL",
        season_allstar: "qualified",
        season_award_mvp: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "1932-1948",
  },
  {
    career_p_sv_300: "qualified",
    headshot_url: "202307191/images/headshots/8/8223c156_davis.jpg",
    id: "mesa--001jos",
    link: "/players/m/mesajo01.shtml",
    name: "Jos\u00e9 Mesa",
    name_first: "Jos\u00e9",
    name_last: "Mesa",
    search_name: ["Jose Mesa"],
    teams: [
      { id: "COL" },
      { id: "PIT", season_p_sv_40: "qualified" },
      { id: "SFG" },
      { id: "PHI", season_p_sv_40: "qualified" },
      { id: "CLE", season_allstar: "qualified", season_p_sv_40: "qualified" },
      { id: "SEA" },
      { id: "BAL" },
      { id: "DET" },
    ],
    years: "1987-2007",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/a/a9ef4dc6_sabr.jpg",
    id: "meusel001emi",
    link: "/players/m/meuseir01.shtml",
    name: "Irish Meusel",
    name_first: "Irish",
    name_last: "Meusel",
    search_name: ["Irish Meusel"],
    teams: [
      { id: "MIN" },
      { id: "LAD" },
      {
        id: "SFG",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
      { id: "PHI", season_b_avg_300: "qualified" },
    ],
    years: "1914-1927",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/e/e6ec9e64_sabr.jpg",
    id: "miller001edm",
    link: "/players/m/millebi02.shtml",
    name: "Bing Miller",
    name_first: "Bing",
    name_last: "Miller",
    search_name: ["Bing Miller"],
    teams: [
      { id: "MIN" },
      { id: "BAL", season_b_avg_300: "qualified" },
      { id: "BOS", season_b_avg_300: "unqualified" },
      {
        id: "OAK",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "1921-1936",
  },
  {
    headshot_url: "202307191/images/headshots/9/923ff3e8_sabr.jpg",
    id: "mitche002cla",
    link: "/players/m/mitchcl01.shtml",
    name: "Clarence Mitchell",
    name_first: "Clarence",
    name_last: "Mitchell",
    search_name: ["Clarence Mitchell"],
    teams: [
      { id: "LAD", season_b_avg_300: "unqualified" },
      { id: "CIN" },
      { id: "SFG" },
      { id: "PHI" },
      { id: "STL", season_b_avg_300: "unqualified" },
      { id: "DET", season_b_avg_300: "unqualified" },
    ],
    years: "1911-1932",
  },
  {
    headshot_url: "202307191/images/headshots/f/f0433c59_sabr.jpg",
    id: "mitche002kev",
    link: "/players/m/mitchke01.shtml",
    name: "Kevin Mitchell",
    name_first: "Kevin",
    name_last: "Mitchell",
    search_name: ["Kevin Mitchell"],
    teams: [
      { id: "NYM", ws_champ: "qualified" },
      {
        id: "SFG",
        season_allstar: "qualified",
        season_award_mvp: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "OAK" },
      { id: "BOS", season_b_avg_300: "unqualified" },
      { id: "SDP" },
      { id: "CIN", season_b_avg_300: "qualified" },
      { id: "CLE" },
      { id: "SEA" },
    ],
    years: "1984-1998",
  },
  {
    headshot_url: "202307191/images/headshots/3/34031aef_sabr.jpg",
    id: "montan001gui",
    link: "/players/m/montawi01.shtml",
    name: "Willie Monta\u00f1ez",
    name_first: "Willie",
    name_last: "Monta\u00f1ez",
    search_name: ["Willie Montanez"],
    teams: [
      { id: "NYM" },
      { id: "PIT" },
      { id: "SFG", season_b_avg_300: "qualified" },
      { id: "ATL", season_allstar: "qualified", season_b_avg_300: "qualified" },
      { id: "PHI", season_b_avg_300: "qualified" },
      { id: "TEX", season_b_avg_300: "unqualified" },
      { id: "SDP" },
      { id: "ANA" },
      { id: "WSN" },
    ],
    years: "1966-1982",
  },
  {
    career_b_avg_300: "unqualified",
    headshot_url: "202307191/images/headshots/c/cbdaa715_sabr.jpg",
    id: "moore-001tom",
    link: "/players/m/mooreto01.shtml",
    name: "Tommy Moore",
    name_first: "Tommy",
    name_last: "Moore",
    search_name: ["Tommy Moore"],
    teams: [
      { id: "TEX" },
      { id: "NYM", season_b_avg_300: "unqualified" },
      { id: "SEA" },
      { id: "STL", season_b_avg_300: "unqualified" },
    ],
    years: "1972-1977",
  },
  {
    career_award_hof: "qualified",
    headshot_url: "202307191/images/headshots/b/bf4f7a6e_sabr.jpg",
    id: "morgan001joe",
    link: "/players/m/morgajo02.shtml",
    name: "Joe Morgan",
    name_first: "Joe",
    name_last: "Morgan",
    search_name: ["Joe Morgan"],
    teams: [
      {
        id: "CIN",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_mvp: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
        season_b_sb_30: "qualified",
        ws_champ: "qualified",
      },
      { id: "SFG", season_award_silver_slugger: "qualified" },
      { id: "PHI" },
      { id: "HOU", season_allstar: "qualified", season_b_sb_30: "qualified" },
      { id: "OAK" },
    ],
    years: "1963-1984",
  },
  {
    headshot_url: "202307191/images/headshots/a/a7727193_sabr.jpg",
    id: "morgan003mic",
    link: "/players/m/morgami01.shtml",
    name: "Mike Morgan",
    name_first: "Mike",
    name_last: "Morgan",
    search_name: ["Mike Morgan"],
    teams: [
      { id: "ARI", season_b_avg_300: "unqualified", ws_champ: "qualified" },
      { id: "MIN", season_b_avg_300: "unqualified" },
      { id: "STL" },
      { id: "OAK" },
      { id: "TEX" },
      { id: "LAD", season_allstar: "qualified" },
      { id: "CIN" },
      { id: "CHC", season_b_avg_300: "unqualified" },
      { id: "NYY" },
      { id: "SEA" },
      { id: "BAL" },
      { id: "TOR" },
    ],
    years: "1978-2002",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/e/ecf3e33a_sabr.jpg",
    id: "morris003wil",
    link: "/players/m/morriha02.shtml",
    name: "Hal Morris",
    name_first: "Hal",
    name_last: "Morris",
    search_name: ["Hal Morris"],
    teams: [
      { id: "KCR", season_b_avg_300: "qualified" },
      { id: "CIN", season_b_avg_300: "qualified", ws_champ: "qualified" },
      { id: "NYY" },
      { id: "DET", season_b_avg_300: "unqualified" },
    ],
    years: "1988-2000",
  },
  {
    career_award_hof: "qualified",
    headshot_url: "202307191/images/headshots/7/7585bcdf_sabr.jpg",
    id: "morris007joh",
    link: "/players/m/morrija02.shtml",
    name: "Jack Morris",
    name_first: "Jack",
    name_last: "Morris",
    search_name: ["Jack Morris"],
    teams: [
      { id: "MIN", season_allstar: "qualified", ws_champ: "qualified" },
      { id: "CLE" },
      {
        id: "DET",
        season_allstar: "qualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
        ws_champ: "qualified",
      },
      { id: "TOR", season_p_w_20: "qualified", ws_champ: "qualified" },
    ],
    years: "1977-1994",
  },
  {
    headshot_url: "202307191/images/headshots/f/f4fdaa34_sabr.jpg",
    id: "mota--001gui",
    link: "/players/m/motagu01.shtml",
    name: "Guillermo Mota",
    name_first: "Guillermo",
    name_last: "Mota",
    search_name: ["Guillermo Mota"],
    teams: [
      { id: "NYM" },
      { id: "LAD", season_b_avg_300: "unqualified" },
      { id: "CLE" },
      { id: "WSN", season_b_avg_300: "unqualified" },
      { id: "SFG", ws_champ: "qualified" },
      { id: "FLA", season_b_avg_300: "unqualified" },
      { id: "MIL" },
    ],
    years: "1999-2012",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/0/0cd53a93_sabr.jpg",
    id: "mota--001man",
    link: "/players/m/motama01.shtml",
    name: "Manny Mota",
    name_first: "Manny",
    name_last: "Mota",
    search_name: ["Manny Mota"],
    teams: [
      {
        id: "LAD",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
      },
      { id: "WSN", season_b_avg_300: "unqualified" },
      { id: "PIT", season_b_avg_300: "unqualified" },
      { id: "SFG" },
    ],
    years: "1962-1982",
  },
  {
    career_award_hof: "qualified",
    career_b_h_3000: "qualified",
    career_b_hr_500: "qualified",
    headshot_url: "202307191/images/headshots/6/6c632af8_sabr.jpg",
    id: "murray001edd",
    link: "/players/m/murraed02.shtml",
    name: "Eddie Murray",
    name_first: "Eddie",
    name_last: "Murray",
    search_name: ["Eddie Murray"],
    teams: [
      { id: "NYM", season_b_rbi_100: "qualified" },
      {
        id: "LAD",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
      },
      { id: "ANA" },
      { id: "CLE", season_b_avg_300: "qualified" },
      {
        id: "BAL",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_roty: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "1977-1997",
  },
  {
    career_p_sv_300: "qualified",
    headshot_url: "202307191/images/headshots/8/888a7c21_sabr.jpg",
    id: "myers-001ran",
    link: "/players/m/myersra01.shtml",
    name: "Randy Myers",
    name_first: "Randy",
    name_last: "Myers",
    search_name: ["Randy Myers"],
    teams: [
      { id: "NYM" },
      { id: "SDP" },
      {
        id: "CHC",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
        season_p_sv_40: "qualified",
      },
      { id: "CIN", season_allstar: "qualified", ws_champ: "qualified" },
      { id: "BAL", season_allstar: "qualified", season_p_sv_40: "qualified" },
      { id: "TOR" },
    ],
    years: "1985-1998",
  },
  {
    career_p_sv_300: "qualified",
    headshot_url: "202307191/images/headshots/7/7b76be79_br.jpg",
    id: "nathan001jos",
    link: "/players/n/nathajo01.shtml",
    name: "Joe Nathan",
    name_first: "Joe",
    name_last: "Nathan",
    search_name: ["Joe Nathan"],
    teams: [
      { id: "MIN", season_allstar: "qualified", season_p_sv_40: "qualified" },
      { id: "TEX", season_allstar: "qualified", season_p_sv_40: "qualified" },
      { id: "CHC" },
      { id: "SFG" },
      { id: "DET" },
    ],
    years: "1999-2016",
  },
  {
    headshot_url: "202307191/images/headshots/b/b3eeb6d1_sabr.jpg",
    id: "newsom001lou",
    link: "/players/n/newsobo01.shtml",
    name: "Bobo Newsom",
    name_first: "Bobo",
    name_last: "Newsom",
    search_name: ["Bobo Newsom"],
    teams: [
      { id: "MIN", season_b_avg_300: "unqualified" },
      { id: "SFG", season_b_avg_300: "unqualified" },
      { id: "OAK", season_allstar: "qualified" },
      { id: "BOS" },
      { id: "LAD" },
      { id: "CHC" },
      { id: "NYY", ws_champ: "qualified" },
      {
        id: "BAL",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
      },
      { id: "DET", season_allstar: "qualified", season_p_w_20: "qualified" },
    ],
    years: "1929-1953",
  },
  {
    career_award_hof: "qualified",
    career_p_so_3000: "qualified",
    career_p_w_300: "qualified",
    headshot_url: "202307191/images/headshots/7/708121b0_sabr.jpg",
    id: "niekro001phi",
    link: "/players/n/niekrph01.shtml",
    name: "Phil Niekro",
    name_first: "Phil",
    name_last: "Niekro",
    search_name: ["Phil Niekro"],
    teams: [
      { id: "CLE" },
      { id: "NYY", season_allstar: "qualified" },
      {
        id: "ATL",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
      },
      { id: "TOR" },
    ],
    years: "1964-1987",
  },
  {
    headshot_url: "202307191/images/headshots/e/eef8f03b_sabr.jpg",
    id: "nieman001rob",
    link: "/players/n/niemabo01.shtml",
    name: "Bob Nieman",
    name_first: "Bob",
    name_last: "Nieman",
    search_name: ["Bob Nieman"],
    teams: [
      { id: "CLE", season_b_avg_300: "unqualified" },
      { id: "SFG", season_b_avg_300: "unqualified" },
      { id: "STL", season_b_avg_300: "unqualified" },
      { id: "DET" },
      { id: "BAL", season_b_avg_300: "qualified" },
      { id: "CHW", season_b_avg_300: "unqualified" },
    ],
    years: "1951-1962",
  },
  {
    headshot_url: "202307191/images/headshots/2/2bf962ee_sabr.jpg",
    id: "nixon-001oti",
    link: "/players/n/nixonot01.shtml",
    name: "Otis Nixon",
    name_first: "Otis",
    name_last: "Nixon",
    search_name: ["Otis Nixon"],
    teams: [
      { id: "MIN", season_b_sb_30: "qualified" },
      { id: "ATL", season_b_sb_30: "qualified" },
      { id: "BOS", season_b_sb_30: "qualified" },
      { id: "TEX", season_b_sb_30: "qualified" },
      { id: "LAD" },
      { id: "CLE" },
      { id: "WSN", season_b_sb_30: "qualified" },
      { id: "NYY" },
      { id: "TOR", season_b_sb_30: "qualified" },
    ],
    years: "1983-1999",
  },
  {
    career_award_hof: "qualified",
    career_p_so_3000: "qualified",
    career_p_w_300: "qualified",
    headshot_url: "202307191/images/headshots/4/4af413ee_sabr.jpg",
    id: "ryan--001lyn",
    link: "/players/r/ryanno01.shtml",
    name: "Nolan Ryan",
    name_first: "Nolan",
    name_last: "Ryan",
    search_name: ["Nolan Ryan"],
    teams: [
      {
        id: "TEX",
        season_allstar: "qualified",
        season_p_so_200: "unqualified",
      },
      { id: "NYM", ws_champ: "qualified" },
      {
        id: "ANA",
        season_allstar: "qualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
      },
      { id: "HOU", season_allstar: "qualified", season_p_so_200: "qualified" },
    ],
    years: "1966-1993",
  },
  {
    headshot_url: "202307191/images/headshots/6/6d0657d1_sabr.jpg",
    id: "noren-001irv",
    link: "/players/n/norenir01.shtml",
    name: "Irv Noren",
    name_first: "Irv",
    name_last: "Noren",
    search_name: ["Irv Noren"],
    teams: [
      { id: "MIN" },
      { id: "LAD" },
      { id: "CHC", season_b_avg_300: "unqualified" },
      {
        id: "NYY",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        ws_champ: "qualified",
      },
      { id: "STL", season_b_avg_300: "unqualified" },
      { id: "OAK" },
    ],
    years: "1950-1960",
  },
  {
    career_b_avg_300: "unqualified",
    headshot_url: "202307191/images/headshots/7/772756d9_sabr.jpg",
    id: "ohman-001wil",
    link: "/players/o/ohmanwi01.shtml",
    name: "Will Ohman",
    name_first: "Will",
    name_last: "Ohman",
    search_name: ["Will Ohman"],
    teams: [
      { id: "LAD", season_b_avg_300: "unqualified" },
      { id: "CHC", season_b_avg_300: "unqualified" },
      { id: "ATL" },
      { id: "FLA" },
      { id: "BAL" },
      { id: "CHW" },
    ],
    years: "2000-2012",
  },
  {
    headshot_url: "202307191/images/headshots/2/21e66774_sabr.jpg",
    id: "olivar001oma",
    link: "/players/o/olivaom01.shtml",
    name: "Omar Olivares",
    name_first: "Omar",
    name_last: "Olivares",
    search_name: ["Omar Olivares"],
    teams: [
      { id: "COL" },
      { id: "PIT" },
      { id: "PHI", season_b_avg_300: "unqualified" },
      { id: "STL" },
      { id: "OAK", season_b_avg_300: "unqualified" },
      { id: "ANA", season_b_avg_300: "unqualified" },
      { id: "SEA", season_b_avg_300: "unqualified" },
      { id: "DET", season_b_avg_300: "unqualified" },
    ],
    years: "1990-2001",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/6/61be7b74_sabr.jpg",
    id: "oliver001alb",
    link: "/players/o/oliveal01.shtml",
    name: "Al Oliver",
    name_first: "Al",
    name_last: "Oliver",
    search_name: ["Al Oliver"],
    teams: [
      { id: "LAD" },
      {
        id: "TEX",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "WSN",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "SFG" },
      {
        id: "PIT",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        ws_champ: "qualified",
      },
      { id: "PHI", season_b_avg_300: "unqualified" },
      { id: "TOR" },
    ],
    years: "1968-1985",
  },
  {
    headshot_url: "202307191/images/headshots/9/938bb13a_br.jpg",
    id: "overba001lyl",
    link: "/players/o/overbly01.shtml",
    name: "Lyle Overbay",
    name_first: "Lyle",
    name_last: "Overbay",
    search_name: ["Lyle Overbay"],
    teams: [
      { id: "ARI", season_b_avg_300: "unqualified" },
      { id: "PIT" },
      { id: "NYY" },
      { id: "ATL" },
      { id: "TOR", season_b_avg_300: "qualified" },
      { id: "MIL", season_b_avg_300: "qualified" },
    ],
    years: "2001-2014",
  },
  {
    headshot_url: "202307191/images/headshots/c/cf443d08_sabr.jpg",
    id: "pacior001tho",
    link: "/players/p/pacioto01.shtml",
    name: "Tom Paciorek",
    name_first: "Tom",
    name_last: "Paciorek",
    search_name: ["Tom Paciorek"],
    teams: [
      { id: "TEX" },
      { id: "NYM" },
      { id: "LAD", season_b_avg_300: "unqualified" },
      {
        id: "SEA",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
      },
      { id: "ATL", season_b_avg_300: "unqualified" },
      { id: "CHW", season_b_avg_300: "unqualified" },
    ],
    years: "1970-1987",
  },
  {
    headshot_url: "202307191/images/headshots/a/a1b6b56e_sabr.jpg",
    id: "parker003dav",
    link: "/players/p/parkeda01.shtml",
    name: "Dave Parker",
    name_first: "Dave",
    name_last: "Parker",
    search_name: ["Dave Parker"],
    teams: [
      { id: "ANA" },
      {
        id: "CIN",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "PIT",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_mvp: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
      { id: "TOR", season_b_avg_300: "unqualified" },
      {
        id: "MIL",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
      },
      { id: "OAK", ws_champ: "qualified" },
    ],
    years: "1973-1991",
  },
  {
    headshot_url: "202307191/images/headshots/7/7ee5893a_sabr.jpg",
    id: "parret001jef",
    link: "/players/p/parreje01.shtml",
    name: "Jeff Parrett",
    name_first: "Jeff",
    name_last: "Parrett",
    search_name: ["Jeff Parrett"],
    teams: [
      { id: "COL" },
      { id: "WSN", season_b_avg_300: "unqualified" },
      { id: "ATL", season_b_avg_300: "unqualified" },
      { id: "PHI" },
      { id: "STL", season_b_avg_300: "unqualified" },
      { id: "OAK" },
    ],
    years: "1986-1996",
  },
  {
    headshot_url: "202307191/images/headshots/d/dba61d68_sabr.jpg",
    id: "parris001lan",
    link: "/players/p/parrila02.shtml",
    name: "Lance Parrish",
    name_first: "Lance",
    name_last: "Parrish",
    search_name: ["Lance Parrish"],
    teams: [
      { id: "CLE" },
      {
        id: "ANA",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
      },
      { id: "PIT" },
      { id: "SEA" },
      { id: "PHI", season_allstar: "qualified" },
      {
        id: "DET",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
      { id: "TOR" },
    ],
    years: "1977-1995",
  },
  {
    headshot_url: "202307191/images/headshots/e/ebd45fd8_br.jpg",
    id: "peavy-001jac",
    link: "/players/p/peavyja01.shtml",
    name: "Jake Peavy",
    name_first: "Jake",
    name_last: "Peavy",
    search_name: ["Jake Peavy"],
    teams: [
      {
        id: "SDP",
        season_allstar: "qualified",
        season_award_cy_young: "qualified",
        season_p_so_200: "qualified",
      },
      { id: "SFG", ws_champ: "qualified" },
      { id: "BOS", ws_champ: "qualified" },
      {
        id: "CHW",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
      },
    ],
    years: "2002-2016",
  },
  {
    headshot_url: "202307191/images/headshots/6/64a40670_br_hou.jpg",
    id: "pence-001hun",
    link: "/players/p/pencehu01.shtml",
    name: "Hunter Pence",
    name_first: "Hunter",
    name_last: "Pence",
    search_name: ["Hunter Pence"],
    teams: [
      { id: "TEX", season_allstar: "qualified" },
      { id: "SFG", season_allstar: "qualified", ws_champ: "qualified" },
      { id: "PHI", season_b_avg_300: "unqualified" },
      { id: "HOU", season_allstar: "qualified", season_b_avg_300: "qualified" },
    ],
    years: "2007-2020",
  },
  {
    career_award_hof: "qualified",
    headshot_url: "202307191/images/headshots/1/1c4baf33_sabr.jpg",
    id: "perez-001ata",
    link: "/players/p/perezto01.shtml",
    name: "Tony P\u00e9rez",
    name_first: "Tony",
    name_last: "P\u00e9rez",
    search_name: ["Tony Perez"],
    teams: [
      { id: "WSN" },
      {
        id: "CIN",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
      { id: "PHI" },
      { id: "BOS", season_b_rbi_100: "qualified" },
    ],
    years: "1964-1986",
  },
  {
    career_award_hof: "qualified",
    career_p_so_3000: "qualified",
    career_p_w_300: "qualified",
    headshot_url: "202307191/images/headshots/f/f7cb0d3e_sabr.jpg",
    id: "perry-001gay",
    link: "/players/p/perryga01.shtml",
    name: "Gaylord Perry",
    name_first: "Gaylord",
    name_last: "Perry",
    search_name: ["Gaylord Perry"],
    teams: [
      { id: "KCR" },
      {
        id: "SFG",
        season_allstar: "qualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
      },
      { id: "ATL" },
      { id: "TEX" },
      {
        id: "SDP",
        season_allstar: "qualified",
        season_award_cy_young: "qualified",
        season_p_w_20: "qualified",
      },
      {
        id: "CLE",
        season_allstar: "qualified",
        season_award_cy_young: "qualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
      },
      { id: "NYY" },
      { id: "SEA" },
    ],
    years: "1962-1983",
  },
  {
    headshot_url: "202307191/images/headshots/8/85d040c6_sabr.jpg",
    id: "phelps001jos",
    link: "/players/p/phelpjo01.shtml",
    name: "Josh Phelps",
    name_first: "Josh",
    name_last: "Phelps",
    search_name: ["Josh Phelps"],
    teams: [
      { id: "TBD" },
      { id: "CLE", season_b_avg_300: "unqualified" },
      { id: "PIT", season_b_avg_300: "unqualified" },
      { id: "NYY" },
      { id: "STL" },
      { id: "TOR", season_b_avg_300: "unqualified" },
    ],
    years: "2000-2008",
  },
  {
    career_b_avg_300: "unqualified",
    headshot_url: "202307191/images/headshots/8/832d87fc_sabr.jpg",
    id: "phelps001ern",
    link: "/players/p/phelpba01.shtml",
    name: "Babe Phelps",
    name_first: "Babe",
    name_last: "Phelps",
    search_name: ["Babe Phelps"],
    teams: [
      { id: "MIN", season_b_avg_300: "unqualified" },
      {
        id: "LAD",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
      },
      { id: "CHC" },
      { id: "PIT" },
    ],
    years: "1931-1942",
  },
  {
    headshot_url: "202307191/images/headshots/6/67f155b6_sabr.jpg",
    id: "phille001dav",
    link: "/players/p/phillda01.shtml",
    name: "Dave Philley",
    name_first: "Dave",
    name_last: "Philley",
    search_name: ["Dave Philley"],
    teams: [
      { id: "SFG" },
      { id: "PHI", season_b_avg_300: "unqualified" },
      { id: "BOS" },
      { id: "OAK", season_b_avg_300: "qualified" },
      { id: "CLE" },
      { id: "BAL" },
      { id: "DET" },
      { id: "CHW", season_b_avg_300: "qualified" },
    ],
    years: "1941-1962",
  },
  {
    headshot_url: "202307191/images/headshots/2/27e6e67e_sabr.jpg",
    id: "philli001kei",
    link: "/players/p/phillto02.shtml",
    name: "Tony Phillips",
    name_first: "Tony",
    name_last: "Phillips",
    search_name: ["Tony Phillips"],
    teams: [
      { id: "NYM" },
      { id: "ANA" },
      { id: "DET", season_b_avg_300: "qualified" },
      { id: "TOR", season_b_avg_300: "unqualified" },
      { id: "CHW", season_b_avg_300: "qualified" },
      { id: "OAK", ws_champ: "qualified" },
    ],
    years: "1982-1999",
  },
  {
    career_award_hof: "qualified",
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/c/c035234d_br.jpg",
    id: "piazza001mic",
    link: "/players/p/piazzmi01.shtml",
    name: "Mike Piazza",
    name_first: "Mike",
    name_last: "Piazza",
    search_name: ["Mike Piazza"],
    teams: [
      {
        id: "NYM",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "LAD",
        season_allstar: "qualified",
        season_award_roty: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "SDP" },
      { id: "FLA", season_award_silver_slugger: "qualified" },
      { id: "OAK" },
    ],
    years: "1992-2007",
  },
  {
    headshot_url: "202307191/images/headshots/3/34675e2b_davis.jpg",
    id: "pierre001jua",
    link: "/players/p/pierrju01.shtml",
    name: "Juan Pierre",
    name_first: "Juan",
    name_last: "Pierre",
    search_name: ["Juan Pierre"],
    teams: [
      { id: "LAD", season_b_avg_300: "qualified", season_b_sb_30: "qualified" },
      { id: "CHC", season_b_h_200: "qualified", season_b_sb_30: "qualified" },
      {
        id: "COL",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_sb_30: "qualified",
      },
      {
        id: "PHI",
        season_b_avg_300: "unqualified",
        season_b_sb_30: "qualified",
      },
      {
        id: "FLA",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_sb_30: "qualified",
        ws_champ: "qualified",
      },
      { id: "CHW", season_b_sb_30: "qualified" },
    ],
    years: "2000-2013",
  },
  {
    headshot_url: "202307191/images/headshots/2/28b48f5c_br.jpg",
    id: "pierzy001ant",
    link: "/players/p/pierza.01.shtml",
    name: "A.J. Pierzynski",
    name_first: "A.J.",
    name_last: "Pierzynski",
    search_name: ["A.J. Pierzynski", "AJ Pierzynski"],
    teams: [
      { id: "MIN", season_allstar: "qualified", season_b_avg_300: "qualified" },
      { id: "TEX" },
      { id: "SFG" },
      { id: "ATL", season_b_avg_300: "unqualified" },
      { id: "STL" },
      { id: "BOS" },
      {
        id: "CHW",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "1998-2016",
  },
  {
    headshot_url: "202307191/images/headshots/b/bb767482_davis.jpg",
    id: "pizarr001jua",
    link: "/players/p/pizarju01.shtml",
    name: "Juan Pizarro",
    name_first: "Juan",
    name_last: "Pizarro",
    search_name: ["Juan Pizarro"],
    teams: [
      { id: "PIT", season_b_avg_300: "unqualified" },
      { id: "ATL", ws_champ: "qualified" },
      { id: "OAK", season_b_avg_300: "unqualified" },
      { id: "BOS", season_b_avg_300: "unqualified" },
      { id: "CLE" },
      { id: "CHC" },
      { id: "HOU" },
      { id: "CHW", season_allstar: "qualified" },
    ],
    years: "1957-1974",
  },
  {
    headshot_url: "202307191/images/headshots/c/c9cedd32_sabr.jpg",
    id: "podsed001sco",
    link: "/players/p/podsesc01.shtml",
    name: "Scott Podsednik",
    name_first: "Scott",
    name_last: "Podsednik",
    search_name: ["Scott Podsednik"],
    teams: [
      {
        id: "KCR",
        season_b_avg_300: "unqualified",
        season_b_sb_30: "qualified",
      },
      { id: "LAD" },
      { id: "COL" },
      { id: "SEA" },
      { id: "BOS", season_b_avg_300: "unqualified" },
      {
        id: "CHW",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_sb_30: "qualified",
        ws_champ: "qualified",
      },
      { id: "MIL", season_b_avg_300: "qualified", season_b_sb_30: "qualified" },
    ],
    years: "2001-2012",
  },
  {
    headshot_url: "202307191/images/headshots/7/70e64db5_sabr.jpg",
    id: "poloni001lui",
    link: "/players/p/polonlu01.shtml",
    name: "Luis Polonia",
    name_first: "Luis",
    name_last: "Polonia",
    search_name: ["Luis Polonia"],
    teams: [
      { id: "ANA", season_b_avg_300: "qualified", season_b_sb_30: "qualified" },
      { id: "ATL", season_b_avg_300: "unqualified", ws_champ: "qualified" },
      { id: "NYY", season_b_avg_300: "qualified", ws_champ: "qualified" },
      { id: "DET", season_b_avg_300: "unqualified" },
      { id: "BAL" },
      { id: "OAK" },
    ],
    years: "1987-2000",
  },
  {
    headshot_url: "202307191/images/headshots/f/fc3d3b7b_sabr.jpg",
    id: "power-001vic",
    link: "/players/p/powervi01.shtml",
    name: "Vic Power",
    name_first: "Vic",
    name_last: "Power",
    search_name: ["Vic Power"],
    teams: [
      { id: "MIN", season_award_gold_glove: "qualified" },
      { id: "ANA", season_award_gold_glove: "qualified" },
      {
        id: "CLE",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_b_avg_300: "qualified",
      },
      { id: "PHI", season_award_gold_glove: "qualified" },
      {
        id: "OAK",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_b_avg_300: "qualified",
      },
    ],
    years: "1954-1965",
  },
  {
    headshot_url: "202307191/images/headshots/3/32b3be5d_sabr.jpg",
    id: "pratt-001der",
    link: "/players/p/prattde01.shtml",
    name: "Del Pratt",
    name_first: "Del",
    name_last: "Pratt",
    search_name: ["Del Pratt"],
    teams: [
      {
        id: "NYY",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "DET", season_b_avg_300: "qualified" },
      {
        id: "BAL",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
        season_b_sb_30: "qualified",
      },
      {
        id: "BOS",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
    ],
    years: "1912-1924",
  },
  {
    headshot_url: "202307191/images/headshots/8/857a6f30_sabr.jpg",
    id: "pride-001cur",
    link: "/players/p/pridecu01.shtml",
    name: "Curtis Pride",
    name_first: "Curtis",
    name_last: "Pride",
    search_name: ["Curtis Pride"],
    teams: [
      { id: "ANA" },
      { id: "WSN", season_b_avg_300: "unqualified" },
      { id: "NYY" },
      { id: "ATL" },
      { id: "DET", season_b_avg_300: "unqualified" },
      { id: "BOS", season_b_avg_300: "unqualified" },
    ],
    years: "1993-2006",
  },
  {
    headshot_url: "202307191/images/headshots/7/78eba534_sabr.jpg",
    id: "ragan-001don",
    link: "/players/r/raganpa01.shtml",
    name: "Pat Ragan",
    name_first: "Pat",
    name_last: "Ragan",
    search_name: ["Pat Ragan"],
    teams: [
      { id: "LAD" },
      { id: "CIN", season_b_avg_300: "unqualified" },
      { id: "CHC" },
      { id: "SFG", season_b_avg_300: "unqualified" },
      { id: "PHI", season_b_avg_300: "unqualified" },
      { id: "ATL" },
      { id: "CHW" },
    ],
    years: "1909-1923",
  },
  {
    career_award_hof: "qualified",
    headshot_url: "202307191/images/headshots/6/6fb1015c_sabr.jpg",
    id: "raines001tim",
    link: "/players/r/raineti01.shtml",
    name: "Tim Raines",
    name_first: "Tim",
    name_last: "Raines",
    search_name: ["Tim Raines"],
    teams: [
      {
        id: "WSN",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "NYY", season_b_avg_300: "unqualified", ws_champ: "qualified" },
      { id: "FLA" },
      { id: "BAL" },
      { id: "OAK" },
      { id: "CHW", season_b_avg_300: "qualified", season_b_sb_30: "qualified" },
    ],
    years: "1979-2002",
  },
  {
    career_b_avg_300: "qualified",
    career_b_hr_500: "qualified",
    headshot_url: "202307191/images/headshots/8/8d70b524_sabr.jpg",
    id: "ramire002man",
    link: "/players/r/ramirma02.shtml",
    name: "Manny Ram\u00edrez",
    name_first: "Manny",
    name_last: "Ram\u00edrez",
    search_name: ["Manny Ramirez"],
    teams: [
      { id: "TBD" },
      { id: "LAD", season_b_avg_300: "unqualified" },
      {
        id: "CLE",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "CHW" },
      {
        id: "BOS",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "1993-2011",
  },
  {
    career_p_sv_300: "qualified",
    headshot_url: "202307191/images/headshots/4/4c1e2208_sabr.jpg",
    id: "reardo001jef",
    link: "/players/r/reardje01.shtml",
    name: "Jeff Reardon",
    name_first: "Jeff",
    name_last: "Reardon",
    search_name: ["Jeff Reardon"],
    teams: [
      {
        id: "MIN",
        season_allstar: "qualified",
        season_p_sv_40: "qualified",
        ws_champ: "qualified",
      },
      { id: "NYM" },
      { id: "CIN" },
      { id: "WSN", season_allstar: "qualified", season_p_sv_40: "qualified" },
      { id: "NYY" },
      { id: "ATL" },
      { id: "BOS", season_allstar: "qualified", season_p_sv_40: "qualified" },
    ],
    years: "1979-1994",
  },
  {
    headshot_url: "202307191/images/headshots/6/63af7c64_sabr.jpg",
    id: "renter001edg",
    link: "/players/r/renteed01.shtml",
    name: "Edgar Renter\u00eda",
    name_first: "Edgar",
    name_last: "Renter\u00eda",
    search_name: ["Edgar Renteria"],
    teams: [
      { id: "CIN" },
      { id: "SFG", ws_champ: "qualified" },
      { id: "ATL", season_allstar: "qualified", season_b_avg_300: "qualified" },
      { id: "DET" },
      {
        id: "STL",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
        season_b_sb_30: "qualified",
      },
      {
        id: "FLA",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_sb_30: "qualified",
        ws_champ: "qualified",
      },
      { id: "BOS" },
    ],
    years: "1996-2011",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/b/b93b4f32_sabr.jpg",
    id: "reynol002car",
    link: "/players/r/reynoca01.shtml",
    name: "Carl Reynolds",
    name_first: "Carl",
    name_last: "Reynolds",
    search_name: ["Carl Reynolds"],
    teams: [
      { id: "MIN", season_b_avg_300: "unqualified" },
      { id: "CHC", season_b_avg_300: "qualified" },
      { id: "BAL" },
      { id: "BOS", season_b_avg_300: "unqualified" },
      {
        id: "CHW",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
      },
    ],
    years: "1927-1939",
  },
  {
    headshot_url: "202307191/images/headshots/a/a8898e71_sabr.jpg",
    id: "robert001leo",
    link: "/players/r/roberbi01.shtml",
    name: "Bip Roberts",
    name_first: "Bip",
    name_last: "Roberts",
    search_name: ["Bip Roberts"],
    teams: [
      { id: "KCR", season_b_avg_300: "unqualified" },
      { id: "SDP", season_b_avg_300: "qualified", season_b_sb_30: "qualified" },
      { id: "CLE" },
      {
        id: "CIN",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "DET" },
      { id: "OAK" },
    ],
    years: "1986-1998",
  },
  {
    career_award_hof: "qualified",
    career_b_hr_500: "qualified",
    headshot_url: "202307191/images/headshots/c/c3ac5482_sabr.jpg",
    id: "robins001fra",
    link: "/players/r/robinfr02.shtml",
    name: "Frank Robinson",
    name_first: "Frank",
    name_last: "Robinson",
    search_name: ["Frank Robinson"],
    teams: [
      { id: "LAD" },
      { id: "CLE" },
      { id: "ANA", season_allstar: "qualified" },
      {
        id: "CIN",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_mvp: "qualified",
        season_award_roty: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "BAL",
        season_allstar: "qualified",
        season_award_mvp: "qualified",
        season_b_avg_300: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "1956-1976",
  },
  {
    headshot_url: "202307191/images/headshots/3/3030255d_sabr.jpg",
    id: "robins005wil",
    link: "/players/r/robined01.shtml",
    name: "Eddie Robinson",
    name_first: "Eddie",
    name_last: "Robinson",
    search_name: ["Eddie Robinson"],
    teams: [
      { id: "MIN", season_allstar: "qualified" },
      { id: "CLE", season_b_avg_300: "qualified", ws_champ: "qualified" },
      { id: "NYY" },
      { id: "DET" },
      { id: "BAL" },
      { id: "OAK", season_allstar: "qualified", season_b_rbi_100: "qualified" },
      {
        id: "CHW",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
    ],
    years: "1942-1957",
  },
  {
    career_p_sv_300: "qualified",
    headshot_url: "202307191/images/headshots/6/697d2f96_br.jpg",
    id: "rodney001fer",
    link: "/players/r/rodnefe01.shtml",
    name: "Fernando Rodney",
    name_first: "Fernando",
    name_last: "Rodney",
    search_name: ["Fernando Rodney"],
    teams: [
      { id: "MIN" },
      { id: "ARI" },
      { id: "TBD", season_allstar: "qualified", season_p_sv_40: "qualified" },
      { id: "OAK" },
      { id: "SDP" },
      { id: "WSN", ws_champ: "qualified" },
      { id: "ANA" },
      { id: "CHC" },
      { id: "SEA", season_allstar: "qualified", season_p_sv_40: "qualified" },
      { id: "DET" },
      { id: "FLA", season_allstar: "qualified" },
    ],
    years: "2002-2019",
  },
  {
    career_b_avg_300: "unqualified",
    career_p_sv_300: "qualified",
    headshot_url: "202307191/images/headshots/c/ce645b1e_br.jpg",
    id: "rodrig009fra",
    link: "/players/r/rodrifr03.shtml",
    name: "Francisco Rodr\u00edguez",
    name_first: "Francisco",
    name_last: "Rodr\u00edguez",
    search_name: ["Francisco Rodriguez"],
    teams: [
      { id: "NYM", season_allstar: "qualified" },
      {
        id: "ANA",
        season_allstar: "qualified",
        season_p_sv_40: "qualified",
        ws_champ: "qualified",
      },
      { id: "DET", season_p_sv_40: "qualified" },
      { id: "BAL" },
      {
        id: "MIL",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
        season_p_sv_40: "qualified",
      },
    ],
    years: "2002-2017",
  },
  {
    career_award_hof: "qualified",
    headshot_url: "202307191/images/headshots/2/2eafa5bc_sabr.jpg",
    id: "rodrig001iva",
    link: "/players/r/rodriiv01.shtml",
    name: "Iv\u00e1n Rodr\u00edguez",
    name_first: "Iv\u00e1n",
    name_last: "Rodr\u00edguez",
    search_name: ["Ivan Rodriguez"],
    teams: [
      {
        id: "TEX",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_mvp: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "WSN" },
      { id: "NYY" },
      { id: "HOU" },
      {
        id: "DET",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
      },
      { id: "FLA", ws_champ: "qualified" },
    ],
    years: "1991-2011",
  },
  {
    career_award_hof: "qualified",
    headshot_url: "202307191/images/headshots/c/c2d4e458_br.jpg",
    id: "rolen-001sco",
    link: "/players/r/rolensc01.shtml",
    name: "Scott Rolen",
    name_first: "Scott",
    name_last: "Rolen",
    search_name: ["Scott Rolen"],
    teams: [
      {
        id: "CIN",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
      },
      {
        id: "PHI",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_roty: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "STL",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
      { id: "TOR", season_b_avg_300: "unqualified" },
    ],
    years: "1996-2012",
  },
  {
    headshot_url: "202307191/images/headshots/c/cd44a05b_sabr.jpg",
    id: "ruel--001her",
    link: "/players/r/ruelmu01.shtml",
    name: "Muddy Ruel",
    name_first: "Muddy",
    name_last: "Ruel",
    search_name: ["Muddy Ruel"],
    teams: [
      { id: "MIN", season_b_avg_300: "qualified", ws_champ: "qualified" },
      { id: "NYY", season_b_avg_300: "unqualified" },
      { id: "DET" },
      { id: "BAL" },
      { id: "CHW" },
      { id: "BOS", season_b_avg_300: "unqualified" },
    ],
    years: "1915-1934",
  },
  {
    headshot_url: "202307191/images/headshots/b/bdbe89ae_sabr.jpg",
    id: "ruethe001wal",
    link: "/players/r/ruethdu01.shtml",
    name: "Dutch Ruether",
    name_first: "Dutch",
    name_last: "Ruether",
    search_name: ["Dutch Ruether"],
    teams: [
      { id: "MIN", season_b_avg_300: "unqualified" },
      {
        id: "LAD",
        season_b_avg_300: "unqualified",
        season_p_w_20: "qualified",
      },
      { id: "CIN", ws_champ: "qualified" },
      { id: "CHC" },
      { id: "NYY", ws_champ: "qualified" },
    ],
    years: "1917-1927",
  },
  {
    headshot_url: "202307191/images/headshots/1/143569f6_sabr.jpg",
    id: "samuel001jua",
    link: "/players/s/samueju01.shtml",
    name: "Juan Samuel",
    name_first: "Juan",
    name_last: "Samuel",
    search_name: ["Juan Samuel"],
    teams: [
      { id: "KCR" },
      { id: "LAD", season_allstar: "qualified", season_b_sb_30: "qualified" },
      { id: "NYM", season_b_sb_30: "qualified" },
      { id: "CIN" },
      {
        id: "PHI",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_rbi_100: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "DET", season_b_avg_300: "unqualified" },
      { id: "TOR" },
    ],
    years: "1983-1998",
  },
  {
    career_b_avg_300: "unqualified",
    headshot_url: "202307191/images/headshots/d/dbd8c059_br.jpg",
    id: "sauveu001ric",
    link: "/players/s/sauveri01.shtml",
    name: "Rich Sauveur",
    name_first: "Rich",
    name_last: "Sauveur",
    search_name: ["Rich Sauveur"],
    teams: [
      { id: "KCR" },
      { id: "NYM" },
      { id: "WSN" },
      { id: "PIT", season_b_avg_300: "unqualified" },
      { id: "OAK" },
      { id: "CHW" },
    ],
    years: "1986-2000",
  },
  {
    headshot_url: "202307191/images/headshots/6/629ca705_sabr.jpg",
    id: "schang001wal",
    link: "/players/s/schanwa01.shtml",
    name: "Wally Schang",
    name_first: "Wally",
    name_last: "Schang",
    search_name: ["Wally Schang"],
    teams: [
      { id: "NYY", season_b_avg_300: "qualified", ws_champ: "qualified" },
      { id: "DET" },
      { id: "BAL", season_b_avg_300: "unqualified" },
      { id: "BOS", season_b_avg_300: "unqualified", ws_champ: "qualified" },
      { id: "OAK", ws_champ: "qualified" },
    ],
    years: "1913-1931",
  },
  {
    headshot_url: "202307191/images/headshots/a/afe6d117_sabr.jpg",
    id: "schein001ric",
    link: "/players/s/scheiri01.shtml",
    name: "Richie Scheinblum",
    name_first: "Richie",
    name_last: "Scheinblum",
    search_name: ["Richie Scheinblum"],
    teams: [
      { id: "KCR", season_allstar: "qualified", season_b_avg_300: "qualified" },
      { id: "TEX" },
      { id: "CIN" },
      { id: "ANA", season_b_avg_300: "unqualified" },
      { id: "CLE", season_b_avg_300: "unqualified" },
      { id: "STL", season_b_avg_300: "unqualified" },
    ],
    years: "1965-1974",
  },
  {
    career_p_so_3000: "qualified",
    headshot_url: "202307191/images/headshots/6/65381047_mlbam.jpg",
    id: "scherz001max",
    link: "/players/s/scherma01.shtml",
    name: "Max Scherzer",
    name_first: "Max",
    name_last: "Scherzer",
    search_name: ["Max Scherzer"],
    teams: [
      { id: "ARI" },
      { id: "NYM" },
      { id: "LAD" },
      {
        id: "WSN",
        season_allstar: "qualified",
        season_award_cy_young: "qualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
        ws_champ: "qualified",
      },
      {
        id: "DET",
        season_allstar: "qualified",
        season_award_cy_young: "qualified",
        season_b_avg_300: "unqualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
      },
    ],
    years: "2008-2023",
  },
  {
    career_p_so_3000: "qualified",
    headshot_url: "202307191/images/headshots/4/44885ff3_sabr.jpg",
    id: "schill001cur",
    link: "/players/s/schilcu01.shtml",
    name: "Curt Schilling",
    name_first: "Curt",
    name_last: "Schilling",
    search_name: ["Curt Schilling"],
    teams: [
      {
        id: "ARI",
        season_allstar: "qualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
        ws_champ: "qualified",
      },
      { id: "PHI", season_allstar: "qualified", season_p_so_200: "qualified" },
      { id: "HOU", season_b_avg_300: "unqualified" },
      { id: "BAL" },
      {
        id: "BOS",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "1988-2007",
  },
  {
    headshot_url: "202307191/images/headshots/8/8415a638_sabr.jpg",
    id: "schriv001wil",
    link: "/players/s/schripo01.shtml",
    name: "Pop Schriver",
    name_first: "Pop",
    name_last: "Schriver",
    search_name: ["Pop Schriver"],
    teams: [
      { id: "LAD" },
      { id: "CIN", season_b_avg_300: "unqualified" },
      { id: "CHC", season_b_avg_300: "unqualified" },
      { id: "PIT" },
      { id: "SFG", season_b_avg_300: "unqualified" },
      { id: "PHI" },
      { id: "STL" },
    ],
    years: "1886-1901",
  },
  {
    headshot_url: "202307191/images/headshots/2/24635f24_sabr.jpg",
    id: "schult003jos",
    link: "/players/s/schuljo03.shtml",
    name: "Joe Schultz",
    name_first: "Joe",
    name_last: "Schultz",
    search_name: ["Joe Schultz"],
    teams: [
      { id: "LAD" },
      { id: "CIN", season_b_avg_300: "unqualified" },
      { id: "CHC" },
      { id: "PIT" },
      { id: "PHI", season_b_avg_300: "unqualified" },
      { id: "ATL" },
      { id: "STL", season_b_avg_300: "unqualified" },
    ],
    years: "1912-1925",
  },
  {
    career_award_hof: "qualified",
    career_p_so_3000: "qualified",
    career_p_w_300: "qualified",
    headshot_url: "202307191/images/headshots/4/486af3ad_sabr.jpg",
    id: "seaver002geo",
    link: "/players/s/seaveto01.shtml",
    name: "Tom Seaver",
    name_first: "Tom",
    name_last: "Seaver",
    search_name: ["Tom Seaver"],
    teams: [
      {
        id: "NYM",
        season_allstar: "qualified",
        season_award_cy_young: "qualified",
        season_award_roty: "qualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
        ws_champ: "qualified",
      },
      { id: "CIN", season_allstar: "qualified", season_p_so_200: "qualified" },
      { id: "BOS" },
      { id: "CHW" },
    ],
    years: "1967-1986",
  },
  {
    headshot_url: "202307191/images/headshots/e/e8eb320d_sabr.jpg",
    id: "segui-001dav",
    link: "/players/s/seguida01.shtml",
    name: "David Segui",
    name_first: "David",
    name_last: "Segui",
    search_name: ["David Segui"],
    teams: [
      { id: "TEX", season_b_avg_300: "unqualified" },
      { id: "NYM", season_b_avg_300: "qualified" },
      { id: "CLE", season_b_avg_300: "unqualified" },
      { id: "WSN", season_b_avg_300: "qualified" },
      { id: "SEA", season_b_avg_300: "qualified" },
      { id: "BAL", season_b_avg_300: "qualified" },
      { id: "TOR", season_b_avg_300: "unqualified" },
    ],
    years: "1990-2004",
  },
  {
    headshot_url: "202307191/images/headshots/2/22649411_sabr.jpg",
    id: "shantz001rob",
    link: "/players/s/shantbo01.shtml",
    name: "Bobby Shantz",
    name_first: "Bobby",
    name_last: "Shantz",
    search_name: ["Bobby Shantz"],
    teams: [
      { id: "CHC", season_award_gold_glove: "qualified" },
      {
        id: "PIT",
        season_award_gold_glove: "qualified",
        season_b_avg_300: "unqualified",
      },
      { id: "PHI", season_award_gold_glove: "qualified" },
      {
        id: "NYY",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        ws_champ: "qualified",
      },
      { id: "STL", season_award_gold_glove: "qualified" },
      { id: "HOU", season_award_gold_glove: "qualified" },
      {
        id: "OAK",
        season_allstar: "qualified",
        season_award_mvp: "qualified",
        season_b_avg_300: "unqualified",
        season_p_w_20: "qualified",
      },
    ],
    years: "1949-1964",
  },
  {
    headshot_url: "202307191/images/headshots/8/8afd4dae_sabr.jpg",
    id: "dunsto001sha",
    link: "/players/d/dunstsh01.shtml",
    name: "Shawon Dunston",
    name_first: "Shawon",
    name_last: "Dunston",
    search_name: ["Shawon Dunston"],
    teams: [
      { id: "NYM", season_b_avg_300: "unqualified" },
      { id: "CLE" },
      {
        id: "CHC",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "PIT", season_b_avg_300: "unqualified" },
      { id: "SFG", season_b_avg_300: "unqualified" },
      { id: "STL", season_b_avg_300: "unqualified" },
    ],
    years: "1985-2002",
  },
  {
    career_b_hr_500: "qualified",
    headshot_url: "202307191/images/headshots/4/493e1da7_br.jpg",
    id: "sheffi001gar",
    link: "/players/s/sheffga01.shtml",
    name: "Gary Sheffield",
    name_first: "Gary",
    name_last: "Sheffield",
    search_name: ["Gary Sheffield"],
    teams: [
      { id: "NYM" },
      {
        id: "ATL",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "LAD",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "SDP",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "NYY",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "FLA",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
      { id: "DET" },
      { id: "MIL" },
    ],
    years: "1988-2009",
  },
  {
    career_award_hof: "qualified",
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/c/cd6ca572_sabr.jpg",
    id: "simmon001alo",
    link: "/players/s/simmoal01.shtml",
    name: "Al Simmons",
    name_first: "Al",
    name_last: "Simmons",
    search_name: ["Al Simmons"],
    teams: [
      { id: "MIN", season_b_avg_300: "qualified" },
      { id: "CIN" },
      { id: "ATL" },
      {
        id: "DET",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "BOS" },
      {
        id: "CHW",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "OAK",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "1924-1944",
  },
  {
    headshot_url: "202307191/images/headshots/b/bc4fd057_sabr.jpg",
    id: "slaugh001don",
    link: "/players/s/slaugdo01.shtml",
    name: "Don Slaught",
    name_first: "Don",
    name_last: "Slaught",
    search_name: ["Don Slaught"],
    teams: [
      { id: "KCR", season_b_avg_300: "unqualified" },
      { id: "TEX" },
      { id: "SDP" },
      { id: "ANA", season_b_avg_300: "unqualified" },
      { id: "PIT", season_b_avg_300: "unqualified" },
      { id: "NYY" },
      { id: "CHW" },
    ],
    years: "1982-1997",
  },
  {
    career_award_hof: "qualified",
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/f/fd6550d9_sabr.jpg",
    id: "slaugh001eno",
    link: "/players/s/slaugen01.shtml",
    name: "Enos Slaughter",
    name_first: "Enos",
    name_last: "Slaughter",
    search_name: ["Enos Slaughter"],
    teams: [
      { id: "ATL" },
      { id: "NYY", season_b_avg_300: "unqualified", ws_champ: "qualified" },
      {
        id: "STL",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
      { id: "OAK", season_b_avg_300: "unqualified" },
    ],
    years: "1938-1959",
  },
  {
    headshot_url: "202307191/images/headshots/1/13db7231_sabr.jpg",
    id: "smith-001lon",
    link: "/players/s/smithlo01.shtml",
    name: "Lonnie Smith",
    name_first: "Lonnie",
    name_last: "Smith",
    search_name: ["Lonnie Smith"],
    teams: [
      { id: "KCR", season_b_sb_30: "qualified", ws_champ: "qualified" },
      { id: "PIT" },
      { id: "ATL", season_b_avg_300: "qualified" },
      {
        id: "PHI",
        season_b_avg_300: "unqualified",
        season_b_sb_30: "qualified",
        ws_champ: "qualified",
      },
      { id: "BAL" },
      {
        id: "STL",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_sb_30: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "1978-1994",
  },
  {
    career_b_avg_300: "unqualified",
    headshot_url: "202307191/images/headshots/3/32c88355_mlbam.jpg",
    id: "smith-058wil",
    link: "/players/s/smithwi04.shtml",
    name: "Will Smith",
    name_first: "Will",
    name_last: "Smith",
    search_name: ["Will Smith"],
    teams: [
      { id: "KCR" },
      { id: "TEX" },
      {
        id: "SFG",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
      },
      { id: "ATL", ws_champ: "qualified" },
      { id: "HOU", ws_champ: "qualified" },
      { id: "MIL" },
    ],
    years: "2012-2023",
  },
  {
    career_b_avg_300: "unqualified",
    headshot_url: "202307191/images/headshots/b/b50e1307_sabr.jpg",
    id: "smith-004ear",
    link: "/players/s/smithea02.shtml",
    name: "Earl Smith",
    name_first: "Earl",
    name_last: "Smith",
    search_name: ["Earl Smith"],
    teams: [
      { id: "PIT", season_b_avg_300: "unqualified", ws_champ: "qualified" },
      { id: "SFG", season_b_avg_300: "unqualified", ws_champ: "qualified" },
      { id: "ATL" },
      { id: "STL", season_b_avg_300: "unqualified" },
    ],
    years: "1919-1930",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/1/1887a4fb_sabr.jpg",
    id: "smith-004elm",
    link: "/players/s/smithel01.shtml",
    name: "Elmer Smith",
    name_first: "Elmer",
    name_last: "Smith",
    search_name: ["Elmer Smith"],
    teams: [
      { id: "CIN", season_b_avg_300: "qualified", season_p_w_20: "qualified" },
      { id: "SFG" },
      {
        id: "PIT",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "ATL" },
    ],
    years: "1886-1901",
  },
  {
    career_b_avg_300: "unqualified",
    headshot_url: "202307191/images/headshots/1/135a771b_sabr.jpg",
    id: "sodows001cli",
    link: "/players/s/sodowcl01.shtml",
    name: "Clint Sodowsky",
    name_first: "Clint",
    name_last: "Sodowsky",
    search_name: ["Clint Sodowsky"],
    teams: [
      { id: "ARI", season_b_avg_300: "unqualified" },
      { id: "PIT", season_b_avg_300: "unqualified" },
      { id: "STL" },
      { id: "DET" },
    ],
    years: "1995-1999",
  },
  {
    headshot_url: "202307191/images/headshots/b/bf7a8cdf_sabr.jpg",
    id: "sorian001alf",
    link: "/players/s/soriaal01.shtml",
    name: "Alfonso Soriano",
    name_first: "Alfonso",
    name_last: "Soriano",
    search_name: ["Alfonso Soriano"],
    teams: [
      {
        id: "TEX",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_hr_30_sb_30: "qualified",
        season_b_rbi_100: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "CHC", season_allstar: "qualified", season_b_rbi_100: "qualified" },
      {
        id: "WSN",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_hr_30_sb_30: "qualified",
        season_b_hr_40: "qualified",
        season_b_sb_30: "qualified",
      },
      {
        id: "NYY",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_hr_30_sb_30: "qualified",
        season_b_rbi_100: "qualified",
        season_b_sb_30: "qualified",
      },
    ],
    years: "1999-2014",
  },
  {
    headshot_url: "202307191/images/headshots/1/17850fe1_br.jpg",
    id: "sorian001raf",
    link: "/players/s/soriara01.shtml",
    name: "Rafael Soriano",
    name_first: "Rafael",
    name_last: "Soriano",
    search_name: ["Rafael Soriano"],
    teams: [
      { id: "TBD", season_allstar: "qualified", season_p_sv_40: "qualified" },
      { id: "CHC" },
      { id: "WSN", season_p_sv_40: "qualified" },
      { id: "NYY", season_p_sv_40: "qualified" },
      { id: "ATL" },
      { id: "SEA" },
    ],
    years: "2002-2015",
  },
  {
    career_b_hr_500: "qualified",
    headshot_url: "202307191/images/headshots/7/74258cea_sabr.jpg",
    id: "sosa--001sam",
    link: "/players/s/sosasa01.shtml",
    name: "Sammy Sosa",
    name_first: "Sammy",
    name_last: "Sosa",
    search_name: ["Sammy Sosa"],
    teams: [
      { id: "TEX" },
      {
        id: "CHC",
        season_allstar: "qualified",
        season_award_mvp: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_hr_30_sb_30: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "BAL" },
      { id: "CHW", season_b_sb_30: "qualified" },
    ],
    years: "1989-2007",
  },
  {
    career_award_hof: "qualified",
    headshot_url: "202307191/images/headshots/b/b8be8c57_davis.jpg",
    id: "southw003wil",
    link: "/players/s/southbi01.shtml",
    name: "Billy Southworth",
    name_first: "Billy",
    name_last: "Southworth",
    search_name: ["Billy Southworth"],
    teams: [
      { id: "CLE" },
      { id: "SFG", season_b_avg_300: "qualified" },
      { id: "PIT", season_b_avg_300: "qualified" },
      { id: "ATL", season_b_avg_300: "qualified" },
      { id: "STL", season_b_avg_300: "unqualified", ws_champ: "qualified" },
    ],
    years: "1913-1929",
  },
  {
    headshot_url: "202307191/images/headshots/d/da629708_sabr.jpg",
    id: "speier001jus",
    link: "/players/s/speieju01.shtml",
    name: "Justin Speier",
    name_first: "Justin",
    name_last: "Speier",
    search_name: ["Justin Speier"],
    teams: [
      { id: "ANA" },
      { id: "COL", season_b_avg_300: "unqualified" },
      { id: "CLE", season_b_avg_300: "unqualified" },
      { id: "CHC" },
      { id: "ATL", season_b_avg_300: "unqualified" },
      { id: "FLA" },
      { id: "TOR" },
    ],
    years: "1998-2009",
  },
  {
    career_b_avg_300: "unqualified",
    headshot_url: "202307191/images/headshots/1/17797360_sabr.jpg",
    id: "stanto003wil",
    link: "/players/s/stantmi02.shtml",
    name: "Mike Stanton",
    name_first: "Mike",
    name_last: "Stanton",
    search_name: ["Mike Stanton"],
    teams: [
      { id: "NYM", season_b_avg_300: "unqualified" },
      { id: "SFG" },
      { id: "ATL", season_b_avg_300: "unqualified" },
      { id: "BOS" },
      { id: "TEX" },
      { id: "WSN" },
      { id: "CIN" },
      {
        id: "NYY",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
        ws_champ: "qualified",
      },
    ],
    years: "1989-2007",
  },
  {
    headshot_url: "202307191/images/headshots/f/fe3589cd_sabr.jpg",
    id: "staub-001dan",
    link: "/players/s/staubru01.shtml",
    name: "Rusty Staub",
    name_first: "Rusty",
    name_last: "Staub",
    search_name: ["Rusty Staub"],
    teams: [
      { id: "TEX", season_b_avg_300: "unqualified" },
      {
        id: "NYM",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "WSN", season_allstar: "qualified", season_b_avg_300: "qualified" },
      { id: "DET", season_allstar: "qualified", season_b_rbi_100: "qualified" },
      { id: "HOU", season_allstar: "qualified", season_b_avg_300: "qualified" },
    ],
    years: "1963-1985",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/2/267e4766_sabr.jpg",
    id: "stenze001jak",
    link: "/players/s/stenzja01.shtml",
    name: "Jake Stenzel",
    name_first: "Jake",
    name_last: "Stenzel",
    search_name: ["Jake Stenzel"],
    teams: [
      { id: "CIN", season_b_avg_300: "unqualified" },
      { id: "CHC" },
      {
        id: "PIT",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "STL" },
    ],
    years: "1890-1899",
  },
  {
    headshot_url: "202307191/images/headshots/0/09c30bed_sabr.jpg",
    id: "strang001sam",
    link: "/players/s/stransa01.shtml",
    name: "Sammy Strang",
    name_first: "Sammy",
    name_last: "Strang",
    search_name: ["Sammy Strang"],
    teams: [
      { id: "LAD", season_b_sb_30: "qualified" },
      { id: "CHC", season_b_avg_300: "unqualified" },
      {
        id: "SFG",
        season_b_avg_300: "qualified",
        season_b_sb_30: "qualified",
        ws_champ: "qualified",
      },
      { id: "CHW", season_b_sb_30: "qualified" },
    ],
    years: "1896-1908",
  },
  {
    headshot_url: "202307191/images/headshots/7/709a21a2_mlbam.jpg",
    id: "strang001dev",
    link: "/players/g/gordode01.shtml",
    name: "Dee Strange-Gordon",
    name_first: "Dee",
    name_last: "Strange-Gordon",
    search_name: ["Dee Strange-Gordon", "Dee StrangeGordon"],
    teams: [
      {
        id: "LAD",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "WSN", season_b_avg_300: "unqualified" },
      { id: "SEA", season_b_sb_30: "qualified" },
      {
        id: "FLA",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_sb_30: "qualified",
      },
    ],
    years: "2011-2022",
  },
  {
    headshot_url: "202307191/images/headshots/f/f1b29111_davis.jpg",
    id: "sulliv005wil",
    link: "/players/s/sullibi04.shtml",
    name: "Billy Sullivan",
    name_first: "Billy",
    name_last: "Sullivan",
    search_name: ["Billy Sullivan"],
    teams: [
      { id: "LAD" },
      { id: "CLE", season_b_avg_300: "unqualified" },
      { id: "CIN" },
      { id: "PIT" },
      { id: "DET", season_b_avg_300: "unqualified" },
      { id: "BAL" },
      { id: "CHW", season_b_avg_300: "unqualified" },
    ],
    years: "1931-1947",
  },
  {
    career_award_hof: "qualified",
    career_p_so_3000: "qualified",
    career_p_w_300: "qualified",
    headshot_url: "202307191/images/headshots/9/99de681e_sabr.jpg",
    id: "sutton002don",
    link: "/players/s/suttodo01.shtml",
    name: "Don Sutton",
    name_first: "Don",
    name_last: "Sutton",
    search_name: ["Don Sutton"],
    teams: [
      {
        id: "LAD",
        season_allstar: "qualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
      },
      { id: "ANA" },
      { id: "HOU" },
      { id: "OAK" },
      { id: "MIL" },
    ],
    years: "1966-1988",
  },
  {
    headshot_url: "202307191/images/headshots/5/5ee36b75_sabr.jpg",
    id: "tartab001dan",
    link: "/players/t/tartada01.shtml",
    name: "Danny Tartabull",
    name_first: "Danny",
    name_last: "Tartabull",
    search_name: ["Danny Tartabull"],
    teams: [
      {
        id: "KCR",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "PHI" },
      { id: "NYY", season_b_rbi_100: "qualified" },
      { id: "SEA", season_b_avg_300: "qualified" },
      { id: "CHW", season_b_rbi_100: "qualified" },
      { id: "OAK" },
    ],
    years: "1984-1997",
  },
  {
    headshot_url: "202307191/images/headshots/d/d4ba27ca_sabr_tex.jpg",
    id: "teixei001mar",
    link: "/players/t/teixema01.shtml",
    name: "Mark Teixeira",
    name_first: "Mark",
    name_last: "Teixeira",
    search_name: ["Mark Teixeira"],
    teams: [
      {
        id: "TEX",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "ANA", season_b_avg_300: "unqualified" },
      {
        id: "NYY",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
      { id: "ATL", season_b_avg_300: "unqualified" },
    ],
    years: "2003-2016",
  },
  {
    headshot_url: "202307191/images/headshots/e/e57cc94c_sabr.jpg",
    id: "tejada001mig",
    link: "/players/t/tejadmi01.shtml",
    name: "Miguel Tejada",
    name_first: "Miguel",
    name_last: "Tejada",
    search_name: ["Miguel Tejada"],
    teams: [
      { id: "KCR" },
      { id: "SDP" },
      { id: "SFG" },
      { id: "HOU", season_allstar: "qualified", season_b_avg_300: "qualified" },
      {
        id: "BAL",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "OAK",
        season_allstar: "qualified",
        season_award_mvp: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
      },
    ],
    years: "1997-2013",
  },
  {
    career_award_hof: "qualified",
    career_b_hr_500: "qualified",
    headshot_url: "202307191/images/headshots/a/a2bb6366_sabr.jpg",
    id: "thome-001jam",
    link: "/players/t/thomeji01.shtml",
    name: "Jim Thome",
    name_first: "Jim",
    name_last: "Thome",
    search_name: ["Jim Thome"],
    teams: [
      { id: "MIN" },
      { id: "LAD" },
      {
        id: "CLE",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "PHI",
        season_allstar: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "BAL" },
      {
        id: "CHW",
        season_allstar: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
      },
    ],
    years: "1991-2012",
  },
  {
    headshot_url: "202307191/images/headshots/e/ea122092_sabr.jpg",
    id: "thomps002mil",
    link: "/players/t/thompmi02.shtml",
    name: "Milt Thompson",
    name_first: "Milt",
    name_last: "Thompson",
    search_name: ["Milt Thompson"],
    teams: [
      { id: "LAD" },
      { id: "COL" },
      { id: "PHI", season_b_avg_300: "qualified", season_b_sb_30: "qualified" },
      { id: "ATL", season_b_avg_300: "unqualified" },
      { id: "HOU" },
      { id: "STL", season_b_avg_300: "qualified" },
    ],
    years: "1984-1996",
  },
  {
    headshot_url: "202307191/images/headshots/c/c78d7380_sabr.jpg",
    id: "trillo001jes",
    link: "/players/t/trillma01.shtml",
    name: "Manny Trillo",
    name_first: "Manny",
    name_last: "Trillo",
    search_name: ["Manny Trillo"],
    teams: [
      { id: "CIN" },
      { id: "WSN" },
      { id: "CLE", season_allstar: "qualified" },
      { id: "CHC", season_allstar: "qualified" },
      { id: "SFG" },
      {
        id: "PHI",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_silver_slugger: "qualified",
        ws_champ: "qualified",
      },
      { id: "OAK", ws_champ: "qualified" },
    ],
    years: "1973-1989",
  },
  {
    headshot_url: "202307191/images/headshots/6/60e7c35e_sabr.jpg",
    id: "ward--001tur",
    link: "/players/w/wardtu01.shtml",
    name: "Turner Ward",
    name_first: "Turner",
    name_last: "Ward",
    search_name: ["Turner Ward"],
    teams: [
      { id: "ARI", season_b_avg_300: "unqualified" },
      { id: "CLE", season_b_avg_300: "unqualified" },
      { id: "PIT", season_b_avg_300: "unqualified" },
      { id: "PHI" },
      { id: "MIL" },
      { id: "TOR", season_b_avg_300: "unqualified" },
    ],
    years: "1990-2001",
  },
  {
    headshot_url: "202307191/images/headshots/0/0f06cd2a_sabr_ari.jpg",
    id: "upton-001jus",
    link: "/players/u/uptonju01.shtml",
    name: "Justin Upton",
    name_first: "Justin",
    name_last: "Upton",
    search_name: ["Justin Upton"],
    teams: [
      {
        id: "ARI",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
      },
      { id: "SDP", season_allstar: "qualified" },
      { id: "ANA", season_award_silver_slugger: "qualified" },
      { id: "SEA" },
      {
        id: "ATL",
        season_award_silver_slugger: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "DET",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
      },
    ],
    years: "2007-2022",
  },
  {
    headshot_url: "202307191/images/headshots/f/fd1b7e5d_br.jpg",
    id: "uribe-002jua",
    link: "/players/u/uribeju01.shtml",
    name: "Juan Uribe",
    name_first: "Juan",
    name_last: "Uribe",
    search_name: ["Juan Uribe"],
    teams: [
      { id: "NYM" },
      { id: "LAD", season_b_avg_300: "unqualified" },
      { id: "CLE" },
      { id: "COL", season_b_avg_300: "qualified" },
      { id: "SFG", ws_champ: "qualified" },
      { id: "ATL" },
      { id: "CHW", ws_champ: "qualified" },
    ],
    years: "2001-2016",
  },
  {
    career_b_avg_300: "unqualified",
    headshot_url: "202307191/images/headshots/c/c4f93094_sabr.jpg",
    id: "valdes001rau",
    link: "/players/v/valdera02.shtml",
    name: "Ra\u00fal Vald\u00e9s",
    name_first: "Ra\u00fal",
    name_last: "Vald\u00e9s",
    search_name: ["Raul Valdes"],
    teams: [
      { id: "NYM", season_b_avg_300: "unqualified" },
      { id: "PHI", season_b_avg_300: "unqualified" },
      { id: "NYY" },
      { id: "HOU" },
      { id: "STL" },
    ],
    years: "2010-2014",
  },
  {
    career_b_avg_300: "unqualified",
    headshot_url: "202307191/images/headshots/8/8846c5ec_br.jpg",
    id: "valver001jos",
    link: "/players/v/valvejo01.shtml",
    name: "Jos\u00e9 Valverde",
    name_first: "Jos\u00e9",
    name_last: "Valverde",
    search_name: ["Jose Valverde"],
    teams: [
      {
        id: "ARI",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
        season_p_sv_40: "qualified",
      },
      { id: "NYM" },
      { id: "DET", season_allstar: "qualified", season_p_sv_40: "qualified" },
      { id: "HOU", season_p_sv_40: "qualified" },
    ],
    years: "2003-2014",
  },
  {
    headshot_url: "202307191/images/headshots/f/f7d0ef06_sabr.jpg",
    id: "vaughn001gre",
    link: "/players/v/vaughgr01.shtml",
    name: "Greg Vaughn",
    name_first: "Greg",
    name_last: "Vaughn",
    search_name: ["Greg Vaughn"],
    teams: [
      { id: "TBD", season_allstar: "qualified" },
      {
        id: "SDP",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "COL" },
      { id: "CIN", season_b_hr_40: "qualified", season_b_rbi_100: "qualified" },
      { id: "MIL", season_allstar: "qualified" },
    ],
    years: "1989-2003",
  },
  {
    headshot_url: "202307191/images/headshots/e/e5f63ffa_sabr.jpg",
    id: "vazque001jav",
    link: "/players/v/vazquja01.shtml",
    name: "Javier V\u00e1zquez",
    name_first: "Javier",
    name_last: "V\u00e1zquez",
    search_name: ["Javier Vazquez"],
    teams: [
      { id: "ARI" },
      { id: "WSN", season_p_so_200: "qualified" },
      { id: "ATL", season_p_so_200: "qualified" },
      { id: "NYY", season_allstar: "qualified" },
      { id: "FLA" },
      {
        id: "CHW",
        season_b_avg_300: "unqualified",
        season_p_so_200: "qualified",
      },
    ],
    years: "1998-2011",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/7/7a57b94d_davis.jpg",
    id: "veach-002rob",
    link: "/players/v/veachbo01.shtml",
    name: "Bobby Veach",
    name_first: "Bobby",
    name_last: "Veach",
    search_name: ["Bobby Veach"],
    teams: [
      { id: "MIN" },
      { id: "NYY", season_b_avg_300: "unqualified" },
      {
        id: "DET",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "BOS" },
    ],
    years: "1912-1925",
  },
  {
    headshot_url: "202307191/images/headshots/2/2b256d0b_sabr.jpg",
    id: "vizcai001jos",
    link: "/players/v/vizcajo01.shtml",
    name: "Jos\u00e9 Vizca\u00edno",
    name_first: "Jos\u00e9",
    name_last: "Vizca\u00edno",
    search_name: ["Jose Vizcaino"],
    teams: [
      { id: "NYM", season_b_avg_300: "qualified" },
      { id: "SFG" },
      { id: "STL", season_b_avg_300: "unqualified" },
      { id: "LAD" },
      { id: "CHC" },
      { id: "CLE" },
      { id: "NYY", ws_champ: "qualified" },
      { id: "HOU", season_b_avg_300: "unqualified" },
    ],
    years: "1989-2006",
  },
  {
    headshot_url: "202307191/images/headshots/e/e218d2ce_sabr.jpg",
    id: "vizque001oma",
    link: "/players/v/vizquom01.shtml",
    name: "Omar Vizquel",
    name_first: "Omar",
    name_last: "Vizquel",
    search_name: ["Omar Vizquel"],
    teams: [
      { id: "TEX" },
      {
        id: "CLE",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_b_avg_300: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "SFG", season_award_gold_glove: "qualified" },
      { id: "SEA", season_award_gold_glove: "qualified" },
      { id: "TOR" },
      { id: "CHW" },
    ],
    years: "1989-2012",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/1/1d8560f0_sabr.jpg",
    id: "vosmik001jos",
    link: "/players/v/vosmijo01.shtml",
    name: "Joe Vosmik",
    name_first: "Joe",
    name_last: "Vosmik",
    search_name: ["Joe Vosmik"],
    teams: [
      { id: "MIN" },
      { id: "LAD" },
      {
        id: "CLE",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "BAL", season_b_avg_300: "qualified" },
      { id: "BOS", season_b_avg_300: "qualified", season_b_h_200: "qualified" },
    ],
    years: "1930-1944",
  },
  {
    career_award_hof: "qualified",
    headshot_url: "202307191/images/headshots/a/a5b2c2b4_sabr.jpg",
    id: "waddel001geo",
    link: "/players/w/wadderu01.shtml",
    name: "Rube Waddell",
    name_first: "Rube",
    name_last: "Waddell",
    search_name: ["Rube Waddell"],
    teams: [
      { id: "CHC" },
      { id: "PIT" },
      { id: "BAL", season_p_so_200: "qualified" },
      { id: "OAK", season_p_so_200: "qualified", season_p_w_20: "qualified" },
    ],
    years: "1897-1910",
  },
  {
    career_p_sv_300: "qualified",
    headshot_url: "202307191/images/headshots/8/87aa8388_sabr.jpg",
    id: "wagner002wil",
    link: "/players/w/wagnebi02.shtml",
    name: "Billy Wagner",
    name_first: "Billy",
    name_last: "Wagner",
    search_name: ["Billy Wagner"],
    teams: [
      { id: "NYM", season_allstar: "qualified", season_p_sv_40: "qualified" },
      { id: "ATL" },
      {
        id: "PHI",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
      },
      {
        id: "HOU",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
        season_p_sv_40: "qualified",
      },
      { id: "BOS" },
    ],
    years: "1995-2010",
  },
  {
    headshot_url: "202307191/images/headshots/9/91c4e981_sabr.jpg",
    id: "walker001tod",
    link: "/players/w/walketo04.shtml",
    name: "Todd Walker",
    name_first: "Todd",
    name_last: "Walker",
    search_name: ["Todd Walker"],
    teams: [
      { id: "MIN", season_b_avg_300: "qualified" },
      { id: "SDP" },
      { id: "CHC", season_b_avg_300: "unqualified" },
      { id: "CIN" },
      { id: "COL", season_b_avg_300: "unqualified" },
      { id: "OAK" },
      { id: "BOS" },
    ],
    years: "1996-2007",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/7/74909ba3_sabr.jpg",
    id: "walker001fre",
    link: "/players/w/walkedi02.shtml",
    name: "Dixie Walker",
    name_first: "Dixie",
    name_last: "Walker",
    search_name: ["Dixie Walker"],
    teams: [
      {
        id: "LAD",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "PIT", season_b_avg_300: "unqualified" },
      { id: "NYY", season_b_avg_300: "unqualified" },
      { id: "DET", season_b_avg_300: "qualified" },
      { id: "CHW", season_b_avg_300: "qualified" },
    ],
    years: "1931-1949",
  },
  {
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/3/39a6e69e_sabr.jpg",
    id: "walker004wil",
    link: "/players/w/walkecu01.shtml",
    name: "Curt Walker",
    name_first: "Curt",
    name_last: "Walker",
    search_name: ["Curt Walker"],
    teams: [
      { id: "CIN", season_b_avg_300: "qualified" },
      { id: "SFG" },
      { id: "PHI", season_b_avg_300: "qualified" },
      { id: "NYY" },
    ],
    years: "1919-1930",
  },
  {
    headshot_url: "202307191/images/headshots/f/f69f2a41_sabr.jpg",
    id: "walton001jer",
    link: "/players/w/waltoje01.shtml",
    name: "Jerome Walton",
    name_first: "Jerome",
    name_last: "Walton",
    search_name: ["Jerome Walton"],
    teams: [
      { id: "TBD", season_b_avg_300: "unqualified" },
      { id: "CIN", season_b_avg_300: "unqualified" },
      { id: "ANA" },
      { id: "CHC", season_award_roty: "qualified" },
      { id: "ATL", season_b_avg_300: "unqualified" },
      { id: "BAL" },
    ],
    years: "1989-1998",
  },
  {
    career_award_hof: "qualified",
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/c/ca302f54_sabr.jpg",
    id: "waner-001llo",
    link: "/players/w/wanerll01.shtml",
    name: "Lloyd Waner",
    name_first: "Lloyd",
    name_last: "Waner",
    search_name: ["Lloyd Waner"],
    teams: [
      { id: "LAD" },
      { id: "CIN" },
      {
        id: "PIT",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
      },
      { id: "PHI" },
      { id: "ATL", season_b_avg_300: "unqualified" },
    ],
    years: "1927-1945",
  },
  {
    career_award_hof: "qualified",
    career_b_avg_300: "qualified",
    career_b_h_3000: "qualified",
    headshot_url: "202307191/images/headshots/9/9d598ab8_sabr.jpg",
    id: "waner-001pau",
    link: "/players/w/wanerpa01.shtml",
    name: "Paul Waner",
    name_first: "Paul",
    name_last: "Waner",
    search_name: ["Paul Waner"],
    teams: [
      { id: "LAD", season_b_avg_300: "unqualified" },
      {
        id: "PIT",
        season_allstar: "qualified",
        season_award_mvp: "qualified",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "NYY" },
      { id: "ATL" },
    ],
    years: "1926-1945",
  },
  {
    headshot_url: "202307191/images/headshots/3/3a6cff60_sabr.jpg",
    id: "ward--001dar",
    link: "/players/w/wardda01.shtml",
    name: "Daryle Ward",
    name_first: "Daryle",
    name_last: "Ward",
    search_name: ["Daryle Ward"],
    teams: [
      { id: "LAD" },
      { id: "CHC", season_b_avg_300: "unqualified" },
      { id: "WSN", season_b_avg_300: "unqualified" },
      { id: "PIT" },
      { id: "ATL", season_b_avg_300: "unqualified" },
      { id: "HOU", season_b_avg_300: "unqualified" },
    ],
    years: "1998-2008",
  },
  {
    career_b_avg_300: "unqualified",
    headshot_url: "202307191/images/headshots/7/79961264_sabr.jpg",
    id: "webb--001wil",
    link: "/players/w/webbea01.shtml",
    name: "Earl Webb",
    name_first: "Earl",
    name_last: "Webb",
    search_name: ["Earl Webb"],
    teams: [
      { id: "CHC", season_b_avg_300: "unqualified" },
      { id: "SFG" },
      { id: "DET" },
      { id: "CHW" },
      {
        id: "BOS",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
    ],
    years: "1925-1933",
  },
  {
    headshot_url: "202307191/images/headshots/9/9230b963_sabr.jpg",
    id: "wells-002dav",
    link: "/players/w/wellsda01.shtml",
    name: "David Wells",
    name_first: "David",
    name_last: "Wells",
    search_name: ["David Wells"],
    teams: [
      { id: "BOS" },
      { id: "LAD" },
      { id: "SDP" },
      { id: "CIN" },
      { id: "NYY", season_allstar: "qualified", ws_champ: "qualified" },
      { id: "DET", season_allstar: "qualified" },
      { id: "BAL" },
      {
        id: "TOR",
        season_allstar: "qualified",
        season_p_w_20: "qualified",
        ws_champ: "qualified",
      },
      { id: "CHW" },
    ],
    years: "1987-2007",
  },
  {
    headshot_url: "202307191/images/headshots/9/9d542cc4_sabr.jpg",
    id: "wertz-001vic",
    link: "/players/w/wertzvi01.shtml",
    name: "Vic Wertz",
    name_first: "Vic",
    name_last: "Wertz",
    search_name: ["Vic Wertz"],
    teams: [
      { id: "MIN" },
      { id: "CLE", season_allstar: "qualified", season_b_rbi_100: "qualified" },
      { id: "BAL", season_b_avg_300: "qualified" },
      {
        id: "DET",
        season_allstar: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "BOS", season_b_rbi_100: "qualified" },
    ],
    years: "1947-1963",
  },
  {
    career_p_sv_300: "qualified",
    headshot_url: "202307191/images/headshots/5/56f0b8c4_sabr.jpg",
    id: "wettel001joh",
    link: "/players/w/wettejo01.shtml",
    name: "John Wetteland",
    name_first: "John",
    name_last: "Wetteland",
    search_name: ["John Wetteland"],
    teams: [
      {
        id: "TEX",
        season_allstar: "qualified",
        season_b_avg_300: "unqualified",
        season_p_sv_40: "qualified",
      },
      { id: "LAD" },
      { id: "WSN", season_p_sv_40: "qualified" },
      {
        id: "NYY",
        season_allstar: "qualified",
        season_p_sv_40: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "1989-2000",
  },
  {
    headshot_url: "202307191/images/headshots/f/f60d7078_sabr.jpg",
    id: "white-001dev",
    link: "/players/w/whitede03.shtml",
    name: "Devon White",
    name_first: "Devon",
    name_last: "White",
    search_name: ["Devon White"],
    teams: [
      { id: "ARI", season_allstar: "qualified" },
      { id: "LAD" },
      {
        id: "ANA",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "FLA", ws_champ: "qualified" },
      { id: "MIL" },
      {
        id: "TOR",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_b_sb_30: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "1985-2001",
  },
  {
    headshot_url: "202307191/images/headshots/0/0ca0941b_sabr.jpg",
    id: "white-003ron",
    link: "/players/w/whitero02.shtml",
    name: "Rondell White",
    name_first: "Rondell",
    name_last: "White",
    search_name: ["Rondell White"],
    teams: [
      { id: "MIN" },
      { id: "KCR", season_b_avg_300: "unqualified" },
      { id: "SDP", season_allstar: "qualified" },
      { id: "CHC", season_b_avg_300: "unqualified" },
      { id: "WSN", season_b_avg_300: "qualified" },
      { id: "NYY" },
      { id: "DET", season_b_avg_300: "unqualified" },
    ],
    years: "1993-2007",
  },
  {
    career_award_hof: "qualified",
    headshot_url: "202307191/images/headshots/6/635428bb_sabr.jpg",
    id: "wilhel002jam",
    link: "/players/w/wilheho01.shtml",
    name: "Hoyt Wilhelm",
    name_first: "Hoyt",
    name_last: "Wilhelm",
    search_name: ["Hoyt Wilhelm"],
    teams: [
      { id: "SFG", season_allstar: "qualified", ws_champ: "qualified" },
      { id: "ATL", season_allstar: "qualified" },
      { id: "STL" },
      { id: "LAD" },
      { id: "CLE" },
      { id: "ANA" },
      { id: "CHC" },
      { id: "BAL", season_allstar: "qualified" },
      { id: "CHW" },
    ],
    years: "1952-1972",
  },
  {
    career_award_hof: "qualified",
    career_b_avg_300: "qualified",
    headshot_url: "202307191/images/headshots/e/e2c5ebeb_sabr.jpg",
    id: "wilson001lew",
    link: "/players/w/wilsoha01.shtml",
    name: "Hack Wilson",
    name_first: "Hack",
    name_last: "Wilson",
    search_name: ["Hack Wilson"],
    teams: [
      { id: "LAD", season_b_rbi_100: "qualified" },
      {
        id: "CHC",
        season_b_avg_300: "qualified",
        season_b_h_200: "qualified",
        season_b_hr_40: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "SFG" },
      { id: "PHI" },
    ],
    years: "1923-1934",
  },
  {
    career_award_hof: "qualified",
    career_b_h_3000: "qualified",
    headshot_url: "202307191/images/headshots/9/98b82e8f_sabr.jpg",
    id: "winfie001dav",
    link: "/players/w/winfida01.shtml",
    name: "Dave Winfield",
    name_first: "Dave",
    name_last: "Winfield",
    search_name: ["Dave Winfield"],
    teams: [
      { id: "MIN" },
      {
        id: "SDP",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "CLE" },
      { id: "ANA" },
      {
        id: "NYY",
        season_allstar: "qualified",
        season_award_gold_glove: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      {
        id: "TOR",
        season_award_silver_slugger: "qualified",
        season_b_rbi_100: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "1973-1995",
  },
  {
    headshot_url: "202307191/images/headshots/5/5c632957_sabr.jpg",
    id: "woodli001eug",
    link: "/players/w/woodlge01.shtml",
    name: "Gene Woodling",
    name_first: "Gene",
    name_last: "Woodling",
    search_name: ["Gene Woodling"],
    teams: [
      { id: "NYM" },
      { id: "TEX", season_b_avg_300: "unqualified" },
      { id: "CLE", season_b_avg_300: "qualified" },
      { id: "PIT" },
      { id: "NYY", season_b_avg_300: "qualified", ws_champ: "qualified" },
      { id: "BAL", season_allstar: "qualified", season_b_avg_300: "qualified" },
    ],
    years: "1943-1962",
  },
  {
    career_b_avg_300: "unqualified",
    headshot_url: "202307191/images/headshots/8/8ebb8def_sabr.jpg",
    id: "yan---001est",
    link: "/players/y/yanes01.shtml",
    name: "Esteban Yan",
    name_first: "Esteban",
    name_last: "Yan",
    search_name: ["Esteban Yan"],
    teams: [
      { id: "TEX" },
      { id: "TBD", season_b_avg_300: "unqualified" },
      { id: "CIN" },
      { id: "ANA" },
      { id: "DET" },
      { id: "STL", season_b_avg_300: "unqualified" },
      { id: "BAL" },
    ],
    years: "1996-2006",
  },
  {
    career_award_hof: "qualified",
    career_p_w_300: "qualified",
    headshot_url: "202307191/images/headshots/d/dae2fb8a_sabr.jpg",
    id: "young-001den",
    link: "/players/y/youngcy01.shtml",
    name: "Cy Young",
    name_first: "Cy",
    name_last: "Young",
    search_name: ["Cy Young"],
    teams: [
      { id: "CLE" },
      { id: "ATL" },
      { id: "STL", season_p_w_20: "qualified" },
      {
        id: "BOS",
        season_b_avg_300: "unqualified",
        season_p_so_200: "qualified",
        season_p_w_20: "qualified",
        ws_champ: "qualified",
      },
    ],
    years: "1890-1911",
  },
  {
    headshot_url: "202307191/images/headshots/b/b54b45d2_sabr.jpg",
    id: "young-002eri",
    link: "/players/y/younger01.shtml",
    name: "Eric Young Sr.",
    name_first: "Eric",
    name_last: "Young",
    search_name: ["Eric Young Sr.", "Eric Young Sr"],
    teams: [
      { id: "TEX" },
      { id: "LAD", season_b_sb_30: "qualified" },
      { id: "SDP" },
      { id: "CHC", season_b_sb_30: "qualified" },
      {
        id: "COL",
        season_allstar: "qualified",
        season_award_silver_slugger: "qualified",
        season_b_avg_300: "qualified",
        season_b_sb_30: "qualified",
      },
      { id: "SFG" },
      { id: "MIL", season_b_sb_30: "qualified" },
    ],
    years: "1992-2006",
  },
  {
    headshot_url: "202307191/images/headshots/b/b15fdeca_sabr.jpg",
    id: "zachar001jon",
    link: "/players/z/zachato01.shtml",
    name: "Tom Zachary",
    name_first: "Tom",
    name_last: "Zachary",
    search_name: ["Tom Zachary"],
    teams: [
      { id: "MIN", season_b_avg_300: "unqualified", ws_champ: "qualified" },
      { id: "LAD" },
      { id: "PHI", season_b_avg_300: "unqualified" },
      { id: "ATL" },
      { id: "NYY", ws_champ: "qualified" },
      { id: "BAL" },
      { id: "OAK", season_b_avg_300: "unqualified" },
    ],
    years: "1918-1936",
  },
  {
    headshot_url: "202307191/images/headshots/0/0e2f6fc2_sabr.jpg",
    id: "zisk--001ric",
    link: "/players/z/ziskri01.shtml",
    name: "Richie Zisk",
    name_first: "Richie",
    name_last: "Zisk",
    search_name: ["Richie Zisk"],
    teams: [
      { id: "TEX", season_allstar: "qualified" },
      {
        id: "PIT",
        season_b_avg_300: "qualified",
        season_b_rbi_100: "qualified",
      },
      { id: "SEA", season_b_avg_300: "qualified" },
      { id: "CHW", season_allstar: "qualified", season_b_rbi_100: "qualified" },
    ],
    years: "1971-1983",
  },
];

console.log(DATA);
